import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { connect } from "react-redux";
import {
  createGurudwara,
  showUnshowDialogMessage,
  updateGurudwara,
} from "../../redux/gurudwara/action";
import useMediaQuery from "@mui/material/useMediaQuery";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FileUploadOutlined } from "@mui/icons-material";

const FieldName = [
  { key: "diocese", keyName: "Diocese" },
  { key: "name", keyName: "Churches" },
  { key: "pinCode", keyName: "Pin Code" },
  { key: "latitude", keyName: "Latitude" },
  { key: "longitude", keyName: "Longitude" },
  { key: "address", keyName: "Address" },
];

const AddChurch = (props) => {
  const [userData, setUserData] = useState({
    diocese: "",
    name: "",
    pinCode: "",
    address: "",
    latitude: "",
    longitude: "",
  });

  const matches = useMediaQuery("(max-width:1440px)");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const editRecords = Object.fromEntries(query.entries());
  const navigate = useNavigate();
  const { action } = useParams();
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (action === "edit") {
      const records = editRecords;

      setUserData({
        ...records,
        landmark: "",
      });
    }
  }, [props.modalViewtype]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    FieldName.forEach((field) => {
      if (!userData[field.key]) {
        errors[field.key] = `${field.keyName} is required`;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (action === "edit") {
        props.updateGurudwara({ ...userData, locationName: userData.name });
        props.trackButtonClick("Update Church Button", 1);
      } else {
        props.createGurudwara(userData);
        props.trackButtonClick("Create Church Button", 1);
      }
    }
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();

    navigate(`/church/view`, { replace: true });
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setUserData({
        name: "",
        latitude: "",
        longitude: "",
        diocese: "",
        pinCode: "",
        address: "",
      });
    }
  };

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            props.modalViewtype === "Edit"
              ? "Gurudwara updated Successfully"
              : "New Gurudwara Added Successfully!"
          }
          content=" "
        />
      )}

      <Grid
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <KeyboardBackspaceIcon
          style={{
            fontSize: "30px",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={() => {
            navigate(`/church/view`, { replace: true });
          }}
        />
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
          }}
        >
          {action === "edit" ? " Edit Church" : "Add Church"}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        sx={{
          backgroundColor: "var(--primary-color)",
          borderRadius: 2,
          width: "auto",
        }}
      >
        <Box component="form" onSubmit={handleFormSubmit}>
          <Grid
            container
            spacing={matches ? 4 : 8}
            sx={{ padding: matches ? "20px" : "100px", marginTop: "0px" }}
          >
            {FieldName.map((item) => (
              <Grid
                item
                xs={12}
                sm={item.key === "latitude" || item.key === "longitude" ? 3 : 6}
                key={item.key}
              >
                <TextField
                  fullWidth
                  id={item.key}
                  name={item.key}
                  label={`${item.keyName}*`}
                  placeholder={`Enter ${item.keyName}`}
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  sx={{ backgroundColor: "white", borderRadius: "10px" }}
                  value={userData[item.key]}
                  onChange={handleChange}
                  rows={item.keyName === "Address" ? 4 : undefined}
                  multiline={item.keyName === "Address"}
                  error={!!formErrors[item.key]}
                  helperText={formErrors[item.key]}
                />
                {item.key === "longitude" ? (
                  <Box className="coords">
                    <img src="/orange-location.svg" className="coords-img" />
                    Get Coordinates
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="end"
              gap={"20px"}
              sx={{ marginTop: { lg: 0, xl: "100px" } }}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={<FileUploadOutlined />}
                sx={{
                  width: 120,
                  textTransform: "none",
                }}
                size={matches ? "small" : "large"}
              >
                {action === "Add" ? "Create" : "Update"}
              </Button>
              <Button
                variant="outlined"
                size={matches ? "small" : "large"}
                color="error"
                sx={{
                  width: 120,
                  textTransform: "none",
                  "&.MuiButtonBase-root:hover": {
                    color: "#c62828",
                  },
                }}
                onClick={() => {
                  navigate(`/church/view`, { replace: true });
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    dialogMessage,
    createGurudwara,
    createResponse,
    updateGurudwara,
    updateResponse,
    error,
  } = state.gurudwaraReducer;

  return {
    data,
    createGurudwara,
    createResponse,
    updateGurudwara,
    updateResponse,
    error,
    successResponse: dialogMessage,
  };
};

export default connect(mapStateToProps, {
  createGurudwara,
  updateGurudwara,
  showUnshowDialogMessage,
})(withMixpanelTracking(AddChurch, " Add Church"));

import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_PROFILE,
  profileSuccess,
  profileFailure,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  UPDATE_USER_PROFILE,
} from "./action";
import { showSnackbar } from "../snackbar/action";
import { postRequest } from "../../apiServices/ApiActions";

import { addProfileUrl } from "../../apiServices/ApiUrl";
import { updateUserProfileFromApi } from "../../apiServices/app-management";

const addProfile = async (payload) => {
  return await postRequest(addProfileUrl, payload);
};

const updateProfileApi = async (payload) => {
  return await updateUserProfileFromApi(payload);
};

function* createProfile(action) {
  try {
    const result = yield call(addProfile, action.payload);
    if (result.status === 200) {
      yield put(profileSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(profileFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(profileFailure(error));
  }
}

function* updateUserProfileResponse(action) {
  try {
    const result = yield call(updateProfileApi, action.payload);
    if (result.status === 200) {
      yield put(updateUserProfileSuccess(result));
      yield put(
        showSnackbar({
          message: "Profile details updated successfully!",
          severity: "success",
        }),
      );
    } else {
      let res = result?.response?.data;
      yield put(updateUserProfileFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(updateUserProfileFailure(error));
  }
}

function* profileSaga() {
  yield takeEvery(CREATE_PROFILE, createProfile);
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfileResponse);
}

export default profileSaga;

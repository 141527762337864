import { call, put, takeEvery } from "redux-saga/effects";
import { createGurudwara, deleteGurudwara, getAllGurudwara, updateGurudwara, updateGurudwaraStatus } from "../../apiServices/gurudwara";
import {
    CREATE_GURUDWARA,
    createGurudwaraFaliure,
    createGURUDWARASuccess,
    DELETE_GURUDWARA,
    deleteGurudwaraFailure,
    deleteGURUDWARASuccess,
    GET_GURUDWARA,
    getAllGURUDWARAFailure,
    getAllGURUDWARASuccess,
    UPDATE_GURUDWARA,
    UPDATE_GURUDWARA_FAILURE,
    UPDATE_GURUDWARA_STATUS,
    updateGurudwaraFailure,
    updateGURUDWARAStatusSuccess,
    updateGURUDWARASuccess,
} from "./action";

const createCall = async (payload) => {
  return createGurudwara(payload);
};
const getCall = async () => {
  return getAllGurudwara();
};
const updateCall = async (payload) => {
  return updateGurudwara(payload);
};
const updateStatusCall = async (id, status) => {
  return updateGurudwaraStatus(id, status);
};
const deleteCall = async (payload) => {
  return deleteGurudwara(payload);
};

function* createGURUDWARA(action) {
  try {
    const result = yield call(createCall, action.payload);
    if (result.status === 200) {
      yield put(createGURUDWARASuccess(result));
    } else {
      yield put(createGurudwaraFaliure(result));
    }
  } catch (error) {
    alert(error.response.data.message)
    yield put(createGurudwaraFaliure(error));
  }
}
function* getGURUDWARA(action) {
  try {
    const result = yield call(getCall);
    if (result.status === 200) {
      yield put(getAllGURUDWARASuccess(result));
    } else {
      yield put(getAllGURUDWARAFailure(result));
    }
  } catch (error) {
    yield put(getAllGURUDWARAFailure(error));
  }
}
function* updateGURUDWARAStatus(action) {
  try {
    const result = yield call(updateStatusCall, action.id, action.status);
    if (result.status === 200) {
      yield put(updateGURUDWARAStatusSuccess(result));
    } else {
      yield put(UPDATE_GURUDWARA_FAILURE(result));
    }
  } catch (error) {
    // alert(error.response.data.message)
    yield put(UPDATE_GURUDWARA_FAILURE(error));
  }
}
function* updateGURUDWARA(action) {
  try {
    const result = yield call(updateCall, action.payload, action.id);
    if (result.status === 200) {
      yield put(updateGURUDWARASuccess(result));
    } else {
      yield put(updateGurudwaraFailure(result));
    }
  } catch (error) {
    yield put(updateGurudwaraFailure(error));
  }
}
function* deleteGURUDWARA(action) {
  try {
    const result = yield call(deleteCall, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteGURUDWARASuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteGurudwaraFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(deleteGurudwaraFailure(error));
  }
}

function* gurudwaraSaga() {
  yield takeEvery(GET_GURUDWARA, getGURUDWARA);
  yield takeEvery(UPDATE_GURUDWARA, updateGURUDWARA);
  yield takeEvery(CREATE_GURUDWARA, createGURUDWARA);
  yield takeEvery(DELETE_GURUDWARA, deleteGURUDWARA);
  yield takeEvery(UPDATE_GURUDWARA_STATUS, updateGURUDWARAStatus);
}
export default gurudwaraSaga;

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { connect } from "react-redux";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import {
  deleteGurudwara,
  deleteToggleModal,
  getAllGURUDWARA,
  updateGurudwaraStatus,
} from "../../redux/gurudwara/action";
import "../../styles/church.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import AntSwitch from "./AntSwitch";
import UpdateStatusDialog from "../../commonLayout/UpdateStatusDialog";

const Overview = (props) => {
  const [searchKey, setSearchKey] = useState("");

  const [deleteToggle, setDeleteToggle] = useState(false);
  const [gurudwaraCount, setGurudwaraCount] = useState(0);
  const [activeGurudwaras, setActiveGurudwaras] = useState(0);
  const [, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const size = 5;

  const [state, setState] = useState(null);
  const [statusState, setStatusState] = useState(false);
  const [currStatus, setCurrStatus] = useState(null);
  const [id, setId] = useState(null);
  const [updateStatusToggle, setUpdateStatusToggle] = useState(false);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);

  const handleStatus = (row) => {
    setUpdateStatusToggle(true);
    setId(row.id);
    if (!row.active) {
      setState("Activate");
      setCurrStatus(true);
    } else {
      setState("Deactivate");
      setCurrStatus(false);
    }
  };

  useEffect(() => {
    if (id) {
      props.updateGurudwaraStatus(id, currStatus);
      props.trackButtonClick("Update Status Church Button", 1);
    }
    setStatusState(false);
    setId(null);
  }, [statusState]);

  const handleEditRowData = (Value) => {
    props.handleEdit(Value);
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteGurudwara(value.id);
    props.trackButtonClick("Delete Church Button", 1);
    if (props.error) {
      alert("Error");
    }
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSuccessDelete(false);
  };

  useEffect(() => {
    props.getAllGURUDWARA();

    if(props.deleteResponse) {
      setOpenSuccessDelete(true);
    }
  }, [props.deleteResponse, props.successResponse]);

  useEffect(() => {
    let activeGurudwaras = 0;
    props?.data?.data?.forEach((row) => {
      if (row.active) {
        activeGurudwaras++;
      }
    });

    setActiveGurudwaras(activeGurudwaras);
    setGurudwaraCount(props?.data?.data?.length);
  }, [props.data]);

  useEffect(() => {
    if (props.data?.data) {
      setPaginatedData([
        ...props.data?.data?.slice((page - 1) * size, page * size),
      ]);
      setCount(Math.ceil(props.data?.data?.length / size));
    }
  }, [page, props.data]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (props.data?.data) {
      // Filter data based on searchKey
      const filtered = props.data.data.filter((row) =>
        Object.values(row).some((value) => {
          if (value) {
            return value
              .toString()
              .toLowerCase()
              .includes(searchKey.toLowerCase());
          }
        }),
      );

      setFilteredData(filtered);
      setPaginatedData(filtered.slice((page - 1) * size, page * size));
      setCount(Math.ceil(filtered.length / size));
    }
  }, [searchKey, page, props.data]);

  const matches = useMediaQuery("(max-width:1440px)");

  return (
    <>
      {deleteToggle && (
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          recordType="Church"
          setDeleteToggle={setDeleteToggle}
          recordRowData={rowData}
          deleteRowData={deleteRowData}
          openSuccessDelete={openSuccessDelete}
          closeSuccessDeleteModal={closeSuccessDeleteModal}
        />
      )}
      {updateStatusToggle && (
        <UpdateStatusDialog
          state={state}
          recordType="User"
          deleteToggle={updateStatusToggle}
          setDeleteToggle={setUpdateStatusToggle}
          setStatusState={setStatusState}
        />
      )}
      <Box sx={{ mx: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 5, sm: 2, md: 1 }}
            justifyContent="flex-start"
          >
            <Grid
              xs={4}
              sm={6}
              md={2.5}
              className={"church_users_section"}
              sx={{ p: 2}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    className="church_user_count_icon"
                    src="/totalGurudwara.svg"
                    alt="total-Gurudwara-icon"
                  />
                </Box>

                <Box className={"church_user_count_title"}>
                  Total Diocese
                </Box>
              </Box>

              <div>
                <span className={"church_user_count_text"}>
                  {gurudwaraCount}
                </span>
              </div>
            </Grid>

            <Grid
              xs={4}
              sm={6}
              md={2.5}
              className={"church_users_section"}
              sx={{ p: 2}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    className="church_user_count_icon"
                    src="/totalGurudwara.svg"
                    alt="total-Gurudwara-icon"
                  />
                </Box>

                <Box className={"church_user_count_title"}>
                  Total Churches
                </Box>
              </Box>

              <div>
                <span className={"church_user_count_text"}>
                  {gurudwaraCount}
                </span>
              </div>
            </Grid>

            <Grid
              xs={5}
              sm={6}
              md={3.25}
              className={"church_users_section"}
              sx={{  p: 2, ml: "1%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    className="church_user_count_icon "
                    src="/activeGurudwara.svg"
                    alt="active-Gurudwara-icon"
                  />
                </Box>
                <Box className={"church_user_count_title"}>
                  Active Churches On App
                </Box>
              </Box>
              <div>
                <span className={"church_user_count_text"}>
                  {activeGurudwaras}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Paper className="church-search-bar">
              <IconButton
                type="button"
                sx={{ width: "20px", height: "20px", padding: "20px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search "
                value={searchKey}
                onChange={handleSearch}
              />
            </Paper>
          </Grid>
          <Grid container xs={12} flexWrap="wrap" mt="2%">
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{ maxHeight: matches ? 250 : 480, overflowY: "auto", scrollbarWidth:'thin' }}
            >
              <Table aria-label="simple table" stickyHeader>
                <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                  <TableRow className="chruch_table_row">
                    <TableCell className="gurudwara_user_cell">
                      Church Name
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Address
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Pin Code
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Created By
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Created On
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Action
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ maxWidth: "200px" }}
                        className="gurudwara_user_cell"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        sx={{ maxWidth: "200px" }}
                        className="gurudwara_user_cell"
                      >
                        {row.address}
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        {row.pinCode}
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        {row.createdBy}
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        {row.createdOn}
                      </TableCell>
                      <TableCell sx={{ p: 0 }} className="gurudwara_user_cell">
                        <img
                          src="/edit-icon.svg"
                          alt="edit"
                          className="church_edit_icon"
                          onClick={() => handleEditRowData(row)}
                        />
                        <img
                          src="/delete-icon.svg"
                          alt="delete"
                          className="church_delete_icon"
                          onClick={() => {
                            handleDeleteData(row);
                          }}
                        />
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <AntSwitch
                            defaultChecked
                            inputProps={{ "aria-label": "ant design" }}
                            onChange={() => handleStatus(row)}
                            checked={row?.active}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {count ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size="large"
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    getAllGURUDWARA,
    deleteGurudwara,
    deleteResponse,
    updateGurudwaraStatus,
    dialogMessage,
  } = state.gurudwaraReducer;

  return {
    data,
    getAllGURUDWARA,
    deleteGurudwara,
    deleteResponse,
    updateGurudwaraStatus,
    successResponse: dialogMessage,
  };
};

export default connect(mapStateToProps, {
  getAllGURUDWARA,
  deleteGurudwara,
  updateGurudwaraStatus,
  deleteToggleModal
})(withMixpanelTracking(Overview, " Church"));

import {
  GET_HELP_CENTER_STATUS,
  GET_HELP_CENTER_STATUS_SUCCESS,
  GET_HELP_CENTER_STATUS_FAILURE,
  GET_DELETE_REQUEST_STATUS,
  GET_DELETE_REQUEST_STATUS_SUCCESS,
  GET_DELETE_REQUEST_STATUS_FAILURE,
  GET_VOLUNTEER_STATUS,
  GET_VOLUNTEER_STATUS_SUCCESS,
  GET_VOLUNTEER_STATUS_FAILURE,
} from "./action";
const initialState = {
  error: "",
  volunteerData: [],
  helpCenterData: [],
  deleteRequestData: [],
  helpcenterSuccess: false,
  voluntterSuccess: false,
  deleteRequestSuccess: false,
};

export const paneReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HELP_CENTER_STATUS:
      return {
        ...state,
        helpCenterData: [],
        error: "",
        helpcenterSuccess: false,
      };
    case GET_HELP_CENTER_STATUS_SUCCESS:
      return {
        ...state,
        helpCenterData: action.payload,
        error: "",
        helpcenterSuccess: false,
      };
    case GET_HELP_CENTER_STATUS_FAILURE:
      return {
        ...state,
        helpCenterData: [],
        error: action.payload,
        helpcenterSuccess: false,
      };
    case GET_DELETE_REQUEST_STATUS:
      return {
        ...state,
        deleteRequestData: [],
        error: "",
        deleteRequestSuccess: false,
      };
    case GET_DELETE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        deleteRequestData: action.payload,
        error: "",
        deleteRequestSuccess: false,
      };
    case GET_DELETE_REQUEST_STATUS_FAILURE:
      return {
        ...state,
        deleteRequestData: [],
        error: action.payload,
        deleteRequestSuccess: false,
      };

    case GET_VOLUNTEER_STATUS:
      return {
        ...state,
        volunteerData: [],
        error: "",
        voluntterSuccess: false,
      };
    case GET_VOLUNTEER_STATUS_SUCCESS:
      return {
        ...state,
        volunteerData: action.payload,
        error: "",
        voluntterSuccess: false,
      };
    case GET_VOLUNTEER_STATUS_FAILURE:
      return {
        ...state,
        volunteerData: [],
        error: action.payload,
        voluntterSuccess: false,
      };

    default:
      return state;
  }
};

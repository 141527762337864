import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_REQUEST,
  UPDATE_REQUEST_BY_STATUS,
  GET_REQUEST_BY_STATUS,
  getRequestdetailsSuccess,
  getRequestdetailsFail,
  getRequestByStatusSuccess,
  getRequestByStatusFail,
  updateRequestByStatusSuccess,
  updateRequestByStatusFail,
} from "./action";

import {
  getRequest,
  putRequest,
  putDeleteRquest,
} from "../../apiServices/ApiActions";
import {
  getDeleteRequestUrls,
  getDeleteRequestByStatusUrls,
  updateDeleteRequestUrls,
} from "../../apiServices/ApiUrl";

const getDeleteReqDetails = async () => {
  return getRequest(getDeleteRequestUrls);
};

const getDeleteRStatus = async (payload) => {
  return getRequest(getDeleteRequestByStatusUrls + payload);
};
const updateStatus = async (payload) => {
  return putDeleteRquest(updateDeleteRequestUrls, payload);
};

function* getDeleteRequest(action) {
  try {
    const result = yield call(getDeleteReqDetails);

    if (result.status === 200) {
      let response = result.data.content;
      yield put(getRequestdetailsSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(getRequestdetailsFail(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(getRequestdetailsFail(error));
  }
}

function* getStatusListById(action) {
  try {
    const result = yield call(getDeleteRStatus, action.payload);
    if (result.status === 200) {
      let response = result.data.userList;
      yield put(getRequestByStatusSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(getRequestByStatusFail(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(getRequestByStatusFail(error));
  }
}
function* updateStatusById(action) {
  try {
    const result = yield call(updateStatus, action.payload);

    if (result.status === 200) {
      let response = result.data.content;
      yield put(updateRequestByStatusSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(updateRequestByStatusFail(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(updateRequestByStatusFail(error));
  }
}
function* deleteRequestSaga() {
  yield takeEvery(GET_REQUEST, getDeleteRequest);

  yield takeEvery(GET_REQUEST_BY_STATUS, getStatusListById);
  yield takeEvery(UPDATE_REQUEST_BY_STATUS, updateStatusById);
}

export default deleteRequestSaga;

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: "1.75rem", // 28px / 16px
  height: "1rem", // 16px / 16px
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: "0.9375rem", // 15px / 16px
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(0.5625rem)", // 9px / 16px
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: "0.125rem", // 2px / 16px
    "&.Mui-checked": {
      transform: "translateX(0.75rem)", // 12px / 16px
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "var(--secondary-color)" : "var(--secondary-color)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 0.125rem 0.25rem rgba(0, 35, 11, 0.2)", // Adjusted shadow
    width: "0.75rem", // 12px / 16px
    height: "0.75rem", // 12px / 16px
    borderRadius: "0.375rem", // 6px / 16px
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: "0.75rem", // 16px / 2 / 16px
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,0.35)" : "#4F566C",
    boxSizing: "border-box",
  },
  // Media Queries for responsive design
  "@media (max-width: 600px)": {
    width: "1.5rem", // Adjusted size for smaller screens
    height: "0.875rem", // Adjusted size for smaller screens
    "& .MuiSwitch-thumb": {
      width: "0.6875rem", // Adjusted size for smaller screens
      height: "0.6875rem", // Adjusted size for smaller screens
      borderRadius: "0.3125rem", // Adjusted size for smaller screens
    },
    "& .MuiSwitch-switchBase": {
      padding: "0.0625rem", // Adjusted padding for smaller screens
      "&.Mui-checked": {
        transform: "translateX(0.5625rem)", // Adjusted transform for smaller screens
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: "0.375rem", // Adjusted size for smaller screens
    },
  },
  "@media (max-width: 400px)": {
    width: "1.25rem", // Further adjustment for very small screens
    height: "0.75rem", // Further adjustment for very small screens
    "& .MuiSwitch-thumb": {
      width: "0.5625rem", // Further adjustment for very small screens
      height: "0.5625rem", // Further adjustment for very small screens
      borderRadius: "0.25rem", // Further adjustment for very small screens
    },
    "& .MuiSwitch-switchBase": {
      padding: "0.03125rem", // Further adjustment for very small screens
      "&.Mui-checked": {
        transform: "translateX(0.4375rem)", // Further adjustment for very small screens
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: "0.25rem", // Further adjustment for very small screens
    },
  },
}));

export default AntSwitch;

export const CREATE_EVENTS = "CREATE_EVENTS";
export const CREATE_EVENTS_SUCCESS = "CREATE_EVENTS_SUCCESS";
export const CREATE_EVENTS_FAILURE = "CREATE_EVENTS_FAILURE";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

export const DELETE_EVENTS = "DELETE_EVENTS";
export const DELETE_EVENTS_SUCCESS = "DELETE_EVENTS_SUCCESS";
export const DELETE_EVENTS_FAILURE = "DELETE_EVENTS_FAILURE";

export const TOGGLE_MODAL_STATUS = "TOGGLE_MODAL_STATUS";

export const toggleModal = () => {
  return {
    type: TOGGLE_MODAL_STATUS,
  };
};

export const deleteEventsByID = (id) => {
  return {
    type: DELETE_EVENTS,
    payload: id,
  };
};
export const deleteEventsByIDSuccess = () => {
  return {
    type: DELETE_EVENTS_SUCCESS,
  };
};
export const deleteEventsByIDFail = (err) => {
  return {
    type: DELETE_EVENTS_FAILURE,
    payload: err,
  };
};

export const createEvents = (data) => {
  return {
    type: CREATE_EVENTS,
    payload: data,
  };
};
export const createEventsPostSuccess = (data) => {
  return {
    type: CREATE_EVENTS_SUCCESS,
    payload: data,
  };
};
export const createEventsPostFailure = (error) => {
  return {
    type: CREATE_EVENTS_FAILURE,
    payload: error,
  };
};

export const getEvents = (urlParameters) => {
  return {
    type: GET_EVENTS,
    params: urlParameters,
  };
};
export const getEventsSuccess = (data) => {
  return {
    type: GET_EVENTS_SUCCESS,
    payload: data,
  };
};
export const getEventsFailure = (err) => {
  return {
    type: GET_EVENTS_FAILURE,
    payload: err,
  };
};

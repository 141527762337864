export const CREATE_COMMUNITY = "CREATE_COMMUNITY";
export const CREATE_COMMUNITY_SUCCESS = "CREATE_COMMUNITY_SUCCESS";
export const CREATE_COMMUNITY_FAILURE = "CREATE_COMMUNITY_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const GET_COMMUNITY = "GET_COMMUNITY";
export const GET_COMMUNITY_SUCCESS = "GET_COMMUNITY_SUCCESS";
export const GET_COMMUNITY_FAILURE = "GET_COMMUNITY_FAILURE";

export const UPDATE_COMMUNITY = "UPDATE_COMMUNITY";
export const UPDATE_COMMUNITY_SUCCESS = "UPDATE_COMMUNITY_SUCCESS";
export const UPDATE_COMMUNITY_FAILURE = "UPDATE_COMMUNITY_FAILURE";

export const DELETE_COMMUNITY = "DELETE_COMMUNITY";
export const DELETE_COMMUNITY_SUCCESS = "DELETE_COMMUNITY_SUCCESS";
export const DELETE_COMMUNITY_FAILURE = "DELETE_COMMUNITY_FAILURE";

export const createCommPost = (data) => {
  return {
    type: CREATE_COMMUNITY,
    payload: data,
  };
};
export const createCommunityPostSuccess = (data) => {
  return {
    type: CREATE_COMMUNITY_SUCCESS,
    payload: data,
  };
};
export const createCommunityPostFailure = (error) => {
  return {
    type: CREATE_COMMUNITY_FAILURE,
    payload: error,
  };
};

export const getCommPost = (urlParameters) => {
  return {
    type: GET_COMMUNITY,
    params: urlParameters
  };
};
export const getCommPostSuccess = (data) => {
  return {
    type: GET_COMMUNITY_SUCCESS,
    payload: data,
  };
};
export const getCommPostFailure = (error) => {
  return {
    type: GET_COMMUNITY_FAILURE,
    payload: error,
  };
};

export const updateCommunity = (data, id) => {
  return {
    type: UPDATE_COMMUNITY,
    payload: data,
    id: id,
  };
};

export const updateCommunitySuccess = (data) => {
  return {
    type: UPDATE_COMMUNITY_SUCCESS,
    payload: data,
  };
};

export const updateCommunityFailure = (error) => {
  return {
    type: UPDATE_COMMUNITY_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const deleteCommunity = (id) => {
  return {
    type: DELETE_COMMUNITY,
    payload: id,
  };
};

export const deleteCommunitySuccess = () => {
  return {
    type: DELETE_COMMUNITY_SUCCESS,
   
  };
};

export const deleteCommunityFails = (error) => {
  return {
    type: DELETE_COMMUNITY_FAILURE,
    payload: error,
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

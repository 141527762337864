import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDateSeperatedWithHyphen } from "../../helpers/dateHelper";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { connect } from "react-redux";
import { getVolunteerdetails } from "../../redux/volunteer/action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Overview = (props) => {
  const [tableList, setTableList] = useState([]);
  const navigate = useNavigate();
  const handleViewData = (val) => {
    navigate(`/volunteer/formsView?id=${val?.id}`);
    props.trackButtonClick("View Volunteer Button", 1);
  };

  useEffect(() => {
    props.getVolunteerdetails();
  }, [props.getVolunteerdetails]);

  useEffect(() => {
    setTableList(props.searchedList);
  }, [props.searchedList]);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [size] = useState(5);

  useEffect(() => {
    if (props.getVolunteerSuccess && props.volunteerData.length > 0) {
      if (props.volunteerData) {
        setTableList([
          ...props.volunteerData?.slice((page - 1) * size, page * size),
        ]);
        setCount(Math.ceil(props.volunteerData?.length / size));
        props.searchList(props.volunteerData);
      }
    } else {
      props.searchList([]);
      setTableList([]);
    }
  }, [props.getVolunteerSuccess, page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Grid container xs={12} flexWrap="wrap">
        <TableContainer component={Paper} elevation={0}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="view_Volunteer_table"
          >
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow className="view_volunteer_table_row">
                <TableCell className="view_volunteer_cell">
                  Event Name
                </TableCell>
                <TableCell className="view_volunteer_cell">Date</TableCell>
                <TableCell className="view_volunteer_cell">Location</TableCell>

                <TableCell align="right" className="view_volunteer_cell">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableList &&
                tableList.map((row) => (
                  <TableRow
                    key={row.title}
                    className="view_volunteer_table_row"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="view_volunteer_cell">
                      {row.name}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {formatDateSeperatedWithHyphen(row.date)}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.locationName}
                    </TableCell>

                    <TableCell align="right">
                      <button className='action-Button'
                        style={{
                          border: "1px solid #4169E1",                         
                          color: "#4169E1",                        
                        }}
                        onClick={() => handleViewData(row)}
                      >
                        View Data
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {tableList.length == 0 && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
        {count && tableList.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size="large"
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { volunteerData, getVolunteerSuccess, getVolunteerDownload } =
    state.volunteerReducer;
  return {
    volunteerData,
    getVolunteerSuccess,
    getVolunteerDownload,
  };
};

export default connect(mapStateToProps, { getVolunteerdetails })(
  withMixpanelTracking(Overview, "Volunteer"),
);

import { useEffect, useState } from "react";

import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  Pagination,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import {
  getVolunteerList,
  getVolunteerByStatus,
  updateVolunteerByStatus,
} from "../../redux/volunteer/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useLocation } from "react-router-dom";
const AppliedVol = (props) => {
  const [tableList, setTableList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState("");
  const [volId, setVolId] = useState("");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [size] = useState(5);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("id");
  const decodedData = decodeURIComponent(encodedData);

  const EventsId = JSON.parse(decodedData);

  const handleChange = (_, value) => {
    setPage(value);
  };

  const HandleModal = (val, status) => {
    setOpenModal(!openModal);
    setStatus(status);
    setVolId(val);
  };

  const handleStatus = () => {
    props.updateVolunteerByStatus(volId, status);
    props.trackButtonClick("Update Volunteer Status Button", 1);
    setOpenModal(false);
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", 0);
    formData.append("size", 50);
    formData.append("status", "APPLIED");
    const queryParams = new URLSearchParams(formData).toString();

    props.getVolunteerList(`${EventsId}?${queryParams}`);
  }, [props.getVolunteerList, props.updateByStatusSuccess]);

  useEffect(() => {
    setTableList(props.searchedList);
  }, [props.searchedList]);

  useEffect(() => {
    if (
      (props.getListSuccess || props.updateByStatusSuccess) &&
      props.volunteerList.length > 0
    ) {
      if (props.volunteerList) {
        setTableList([
          ...props.volunteerList?.slice((page - 1) * size, page * size),
        ]);
        setCount(Math.ceil(props.volunteerList?.length / size));
        props.searchList(props.volunteerList);
      }
    } else {
      props.searchList([]);
      setTableList([]);
    }
  }, [props.getListSuccess, props.updateByStatusSuccess, page]);

  return (
    <>
      {openModal && (
        <Dialog open={openModal} onClose={HandleModal} maxWidth="sm">
          <DialogTitle>
            <IconButton
              onClick={HandleModal}
              sx={{ position: "absolute", right: "2%", top: "4%" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box
              justifyContent={"center"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minWidth: { xs: "200px", sm: "400px" },
                p: 4,
                borderRadius: 4,
                textAlign: "center",
              }}
            >
              <Typography variant="h5" mb={2}>
                <p>Approve Volunteer</p>
              </Typography>

              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleStatus}
                    sx={{
                      backgroundColor: "var(--tertiary-color)",
                      color: "white",
                      borderRadius: 4,
                    }}
                  >
                    Yes
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={HandleModal}
                    sx={{
                      backgroundColor: "var(--tertiary-color)",
                      color: "white",
                      borderRadius: 4,
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <Grid container xs={12} flexWrap="wrap">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ maxHeight: 620, overflowY: "auto", scrollbarWidth:'thin' }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="view_Volunteer_table"
          >
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow className="view_volunteer_table_row">
                <TableCell className="view_volunteer_cell">SL No.</TableCell>
                <TableCell className="view_volunteer_cell">Name</TableCell>
                <TableCell className="view_volunteer_cell">Email Id</TableCell>
                <TableCell className="view_volunteer_cell">
                  Phone Number
                </TableCell>
                <TableCell className="view_volunteer_cell">Sewa Type</TableCell>
                <TableCell align="right" className="view_volunteer_cell">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableList?.length > 0 &&
                tableList.map((row, index) => (
                  <TableRow
                    key={row.title}
                    className="view_volunteer_table_row"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="view_volunteer_cell"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.name}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.email}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.phone}
                    </TableCell>
                    <TableCell>{row.sevaResponse}</TableCell>
                    <TableCell align="right" className="view_volunteer_cell">
                      <button
                        className="action-Button"
                        style={{
                          border: "1px solid #4169E1",
                          color: "#4169E1",
                          marginRight: "10px",
                        }}
                        onClick={() => HandleModal(row.id, "APPROVED")}
                      >
                        Accept
                      </button>

                      <button
                        className="action-Button"
                        style={{
                          border: "1px solid #C7332A",
                          color: "#C7332A",
                        }}
                        onClick={() => HandleModal(row.id, "REJECTED")}
                      >
                        Reject
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {tableList?.length === 0 && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
        {count && tableList?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size="large"
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    volunteerList,

    updateByStatusSuccess,
    getListSuccess,
  } = state.volunteerReducer;
  return {
    volunteerList,

    updateByStatusSuccess,
    getListSuccess,
  };
};

export default connect(mapStateToProps, {
  getVolunteerList,
  getVolunteerByStatus,
  updateVolunteerByStatus,
})(withMixpanelTracking(AppliedVol, "Applied Volunteer"));

import {
    Button,
    FormControl,
    TextField,
    Grid,
    IconButton,
    useTheme
} from '@mui/material';

import { Link } from 'react-router-dom';

import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import useCustomStyles from '../../customHooks/useCustomStyles';

const styles  = (theme) => ({
    container: {
        marginTop: '45px',
        backgroundColor: 'var(--primary-color)',
        borderRadius: '8px',
        padding: '0 10px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    componentTitle: {
        fontSize: '24px',
        paddingLeft: '15px',
        fontWeight: '400',
        color: '#333333'
    },

    helper_text: {
        marginLeft: '18px',
        color: '#333333',
        fontSize: '20px',
        textDecoration: 'underline'
    },

    input_box: {
        backgroundColor: '#ffffff',
        color: '#A0A09E',
        borderRadius: '8px'
    }
});

const DailyVerseBulkUpload = () => {
    const theme = useTheme();
    const classes = useCustomStyles(styles, theme);

    const handleBulkUploadHukumnama = (event) => {
        event.preventDefault();
    }

    return (
        <Grid 
            xs={12} 
            className={ classes?.container } 
            component="form" 
            onSubmit={ handleBulkUploadHukumnama }
        >
                <Grid container xs={12}>
                    <p className={ classes?.componentTitle }>Hukumnama(Bulk Upload)</p>
                </Grid>

                <Grid xs={12} md={6}>
                    <FormControl variant="outlined" sx={{ width: { xs: 200, md: 250, lg: 480 }, mb: 2 }}>
                        <TextField 
                            className={ classes?.input_box }
                            id="outlined-basic" 
                            label="*Upload CSV File" 
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <>
                                        <IconButton
                                          aria-label="upload"
                                          component="label"
                                        >
                                          <CreateNewFolderOutlinedIcon sx={{ color: '#000000' }} />
                                          <input
                                            hidden
                                            type="file"
                                            // onChange={(e) => scriptUpload(e)}
                                          />
                                        </IconButton>
                                    </>
                                )
                            }}
                        />
                    </FormControl>

                    <div>
                        <Link className={ classes?.helper_text } to="#">
                            Download Sample File
                        </Link>
                    </div>
                </Grid>

                <Grid xs={12} sx={{ mt: 5, marginLeft: '23%' }}>
                    <Button 
                        variant="contained"
                        type='submit'
                        startIcon={ <FileUploadOutlined /> }
                        sx={{
                            backgroundColor: 'var(--secondary-color)', 
                            textTransform: 'none',
                            fontSize: '20px',
                            fontWeight: '500'
                        }}
                    >Bulk Upload</Button>
                </Grid>
            </Grid>
    )
}

export default DailyVerseBulkUpload;

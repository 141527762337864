import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createGranth,
  showUnshowDialogMessage,
  updateGranth,
} from "../../redux/granth/action";

import {
  Grid,
  Stack,
  FormControl,
  TextField,
  Button,
  useTheme,
  Select,
  InputLabel,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

const styles = (theme) => ({
  container: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px",
      marginTop: "20px",
      height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditBible = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);

  const [granthObject, setGranthObject] = useState({
    name: "",
    description: "",
    files: "",
    languageCode: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState(false);

  // Opens the file upload dialog for the specified type
  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  // Closes the progress dialog, stops the upload progress, and resets progress
  const handleProgressDialogClose = () => {
    setGranthObject({ ...granthObject, files: "" });
    setFileName("");
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval); // Stop the upload interval
    setProgress(0); // Reset the progress
  };

  // Handles file input change, sets the file name, and starts the upload process
  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && file.type === "application/epub+zip") {
      setUploadFileName(file.name);
      setGranthObject({ ...granthObject, files: file });
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
      setFileError(false);
    } else {
      alert("Please upload a valid epub file.");
    }
  };

  // Opens the progress dialog and sets upload status
  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  // Simulates file upload progress
  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval); // Save the interval ID to stop it later
    };
    UploadProgress();
  };

  // Handles form submission
  const handleGranthUpload = async (event) => {
    event.preventDefault();

    if (fileName === "") {
      setFileError(true);
      return;
    }

    const formData = new FormData();

    formData.append("name", granthObject?.name);
    formData.append("description", granthObject?.description);
    formData.append("languageCode", granthObject?.languageCode);
    formData.append("files", granthObject.files);
    if (action === "edit" && !granthObject.files) {
      let Records = editRecords;

      let data = {
        lastModified: Records.createdAt,
        lastModifiedDate: Records.updatedAt,
        name: Records.fileName,
        size: Records.fileSize,
        type: Records.fileType,
        webkitRelativePath: Records.filePath,
      };
      formData.append("files", data);
    } else {
      formData.append("files", granthObject.files);
    }
    if (action === "edit") {
      props.updateGranth(formData, editRecords.id);
      props.trackButtonClick("Update Bible Button", 1);
    } else {
      props.createGranth(formData);
      props.trackButtonClick("Create Bible Button", 1);
    }
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setGranthObject({
        ...granthObject,
        name: "",
        description: "",
      });
      setFileName("");
    }
  };

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const handleChangeLanguage = (val) => {
    setGranthObject({
      ...granthObject,
      languageCode: val.target.value,
    });
  };

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setGranthObject({
        ...granthObject,
        name: records.name,
        description: records.description,
        languageCode: records.languageCode,
      });
      setFileName(records.fileName);
    }
  }, []);

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header="Uploaded Successfully!"
          content="You Have Uploaded Your File Successfully"
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadFileName={uploadFileName}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleGranthUpload}
      >
        <div>
          <Stack
            spacing={2}
            direction="row"
            gap={3}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <Stack spacing={2} direction="column" gap={3}>
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  required
                  className={classes?.input_box}
                  id="outlined-basic"
                  placeholder="Enter Title"
                  label="Title"
                  size={ matches?'small':'' }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={granthObject?.name}
                  onChange={(event) =>
                    setGranthObject({
                      ...granthObject,
                      name: event.target.value,
                    })
                  }
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mb: 2 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-basic"
                  placeholder="EPUB File"
                  label="File"
                  variant="outlined"
                  size={ matches?'small':'' }
                  value={fileName}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    required: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <>
                        <Button
                          aria-label="upload"
                          component="label"
                          className="addFileButton"
                          onClick={() => {
                            handleOpen("EPUB", ["EPUB"], [".epub"]);
                          }}
                        >
                          Add File
                        </Button>
                      </>
                    ),
                  }}
                />
                {fileError && <div style={{ color: "red" }}>File required</div>}
              </FormControl>

              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={ matches?'small':'' }
              >
                <InputLabel id="demo-simple-select-label" sx={{ mt: 1 }}>
                  Choose Language
                </InputLabel>
                <Select
                  required
                  className={classes?.input_box}
                  sx={{ mt: 1 }}
                  InputLabelProps={{ shrink: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={granthObject?.languageCode}
                  label="Select Language"
                  placeholder="Choose Language"
                  onChange={(e) => handleChangeLanguage(e)}
                >
                  <MenuItem value="en_US">English</MenuItem>
                  <MenuItem value="hi_IN">Hindi</MenuItem>
                  <MenuItem value="pa_IN">Punjabi</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
            >
              <TextField
                required
                className={classes?.input_box}
                id="outlined-multiline-static"
                size={ matches?'small':'' }
                placeholder="Enter Description"
                label="Description"
                InputLabelProps={{ shrink: true }}
                multiline
                rows={6}
                value={granthObject?.description}
                onChange={(event) =>
                  setGranthObject({
                    ...granthObject,
                    description: event.target.value,
                  })
                }
              />
            </FormControl>
          </Stack>

          <Grid xs={12} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              type="submit"
              startIcon={<FileUploadOutlined />}
              size="small"
              sx={{
                backgroundColor: "var(--secondary-color)",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "500",
                marginLeft: { sm: 11, md: 52, lg: 87, xl: 112 },
              }}
            >
              {action === "edit" ? "Update" : "Upload"}
            </Button>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage } = state.granthReducer;

  return {
    successResponse: dialogMessage,
  };
};

export default connect(mapStateToProps, {
  createGranth,
  showUnshowDialogMessage,
  updateGranth,
})(withMixpanelTracking(AddEditBible, "Add Bible"));

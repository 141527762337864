import { useState, useEffect, React } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import UploadKirtan from "./UploadGospel";
import { connect } from "react-redux";
import "../../styles/kirtan.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  IconButton,
  Paper,
  useTheme,
  TableSortLabel,
  Box,
} from "@mui/material";
import useCustomStyles from "../../customHooks/useCustomStyles";

import {
  getKirtanWithSinger,
  getKirtanWithAlbum,
  deleteSingerSong,
  deleteToggleModal,
} from "../../redux/kirtan/action";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = (theme) => ({
  caption: {
    ".MuiTablePagination-displayedRows": {
      fontSize: "1.15rem",
      fontWeight: 600,
      textAlign: "right",
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[200],
    fontWeight: "bold",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  expandedRow: {
    backgroundColor: theme.palette.grey[100],
  },
  collapseContent: {
    padding: theme.spacing(2),
  },
  songImage: {
    width: "100px",
    borderRadius: "4px",
  },
  albumThumbnail: {
    width: "150px",
    borderRadius: "4px",
  },
  table: {
    overflowY: "auto",
    scrollbarWidth:'thin',
    [theme.breakpoints.down(1535)]: {
      maxHeight: 225,
    },
    [theme.breakpoints.between(1536, 1919)]: {
      maxHeight: 320,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 510,
    },
  },
});

const ViewGospel = ({
  openModal,
  setOpenModal,
  kirtanList,
  setRecordsList,
  setKirtanList,
  getAllKirtans,
  kirtandata,
  setContentDataToBeUpdated,
  tabType,
  subTabType,
  ...props
}) => {
  const [rowData, setRowData] = useState([]);
  const theme = useTheme();

  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [redordsData, setRecors] = useState([]);
  const [viewType, setViewtype] = useState();
  const [opeeditModal, setopenEditModal] = useState(false);
  const matches = useMediaQuery("(max-width:1440px)");
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("songName");
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  let sortedSingers = [];

  if (kirtanList && kirtanList?.length > 0) {
    sortedSingers = [...kirtanList].sort((a, b) => {
      if (order) {
        return (
          (a.songName.toUpperCase() < b.songName.toUpperCase() ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      }
      return 0;
    });
  }

  const deleteRowData = (value) => {
    props.deleteSingerSong(value.songId);
  };

  const handleEditRow = (row) => {
    setRecors(row);
    setViewtype("Edit");
    setopenEditModal(true);
  };

  const handleCloseEditBox = () => {
    setViewtype("");
    setopenEditModal(false);
  };

  const setData = () => {
    let NewTableData = [];

    if (props.kirtaWithSingerData && props.kirtaWithAlbumData) {
      let mergedData = props.kirtaWithSingerData.concat(
        props.kirtaWithAlbumData,
      );

      mergedData.map((item) => {
        let newObj = {};

        if (item?.songs?.length > 0) {
          if (item.singerId) {
            newObj["singerName"] = item.singerName;
            newObj["singerId"] = item.singerId;
            newObj["languageCode"] = item.languageCode;
            item.songs.map((itm) => {
              newObj = { ...newObj, ...itm };
              NewTableData.push(newObj);
            });
          } else {
            newObj["albumName"] = item.albumName;
            newObj["albumId"] = item.albumId;
            newObj["languageCode"] = item.languageCode;
            item.songs.map((itm) => {
              newObj = { ...newObj, ...itm };
            });
            NewTableData.push(newObj);
          }
        }
      });

      if (NewTableData?.length > 0) {
        typeof setRecordsList === "function" && setRecordsList(NewTableData);
        typeof setKirtanList === "function" && setKirtanList(NewTableData);
      }
    }
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/gospel/edit?data=${encodedData}`);
  };

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSuccessDelete(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  useEffect(() => {
    setData();
  }, [props.albumSuccessResponse, props.singerSuccessResponse]);

  useEffect(() => {
    setRowData([]);

    if(props.deleteSuccess) {
      setOpenSuccessDelete(true);
    }
  }, [props.deleteSuccess]);

  useEffect(() => {
    props.getKirtanWithSinger();
    props.getKirtanWithAlbum();
  }, [props.deleteSuccess, opeeditModal]);

  return (
    <>
      {deleteToggle && (
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          recordType="Kirtan"
          setDeleteToggle={setDeleteToggle}
          recordRowData={rowData}
          deleteRowData={deleteRowData}
          openSuccessDelete={openSuccessDelete}
          closeSuccessDeleteModal={closeSuccessDeleteModal}
        />
      )}

      {opeeditModal || openModal ? (
        <UploadKirtan
          closeEditModal={openModal ? setOpenModal : handleCloseEditBox}
          recordsData={redordsData}
          viewType={viewType ? "Edit" : "Add"}
        />
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            className={classes?.table}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "songName"}
                      direction={order}
                      onClick={() => handleRequestSort("songName")}
                    >
                      Song Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Singer Name</TableCell>
                  <TableCell>Album Name</TableCell>
                  <TableCell>Song Duration</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedSingers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((singer) => (
                    <>
                      <TableRow>
                        <TableCell>{singer?.songName}</TableCell>
                        <TableCell>
                          {singer.singerName ? singer.singerName : "-"}
                        </TableCell>
                        <TableCell>
                          {singer.albumName ? singer.albumName : "-"}
                        </TableCell>
                        <TableCell>{singer?.songDuration}</TableCell>
                        <TableCell>English</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell align="right" sx={{ p: 0 }}>
                          <IconButton
                            aria-label="edit"
                            sx={{
                              width: {
                                lg: "40px",
                                xl: "60px",
                              },
                              height: {
                                lg: "40px",
                                xl: "60px",
                              },
                            }}
                            onClick={() => {
                              subTabType("Gospel");
                              handleRowEdit(singer);
                              setContentDataToBeUpdated(singer);
                              props.trackButtonClick("Edit Gospel Button", 1);
                            }}
                          >
                            <img src="/edit-icon.svg" alt="delete-icon" />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteData(singer)}
                            sx={{
                              width: {
                                lg: "40px",
                                xl: "60px",
                              },
                              height: {
                                lg: "40px",
                                xl: "60px",
                              },
                            }}
                          >
                            <img src="/delete-icon.svg" alt="delete-icon" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {sortedSingers.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto",
                marginTop: "30px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          )}

          {sortedSingers.length > 0 ? (
            <Grid container sx={{ marginTop: "45px" }}>
              <Grid xs={5}></Grid>
              <Grid xs={4}>
                <Pagination
                  className={classes?.caption}
                  rowsPerPageOptions={[10, 20, 35]}
                  component="div"
                  count={Math.ceil(kirtanList?.length / 10)}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  size={matches ? "size" : "large"}
                  onChange={handleChangePage}
                />
              </Grid>
              <Grid xs={3}></Grid>
            </Grid>
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    kirtaWithSingerData,
    kirtaWithAlbumData,
    deleteSuccess,
    createRespone,
    albumSuccessResponse,
    singerSuccessResponse,
  } = state.kirtanReducer;

  return {
    kirtaWithSingerData,
    kirtaWithAlbumData,
    deleteSuccess,
    createRespone,
    albumSuccessResponse,
    singerSuccessResponse,
  };
};

export default connect(mapStateToProps, {
  getKirtanWithSinger,
  getKirtanWithAlbum,
  deleteSingerSong,
  deleteToggleModal
})(withMixpanelTracking(ViewGospel, "Gospel"));

import {
  CREATE_SINGER,
  CREATE_SINGER_FAILURE,
  CREATE_SINGER_SUCCESS,
  DELETE_SINGER,
  DELETE_SINGER_FAILURE,
  DELETE_SINGER_SUCCESS,
  GET_SINGER,
  GET_SINGER_FAILURE,
  GET_SINGER_SUCCESS,
  UPDATE_SINGER,
  UPDATE_SINGER_FAILURE,
  UPDATE_SINGER_SUCCESS,
  DIALOG_MESSAGE,
  DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
  loading: false,
  error: "",
  SingerData: [],
  createRespone: false,
  updateResponse: false,
  deleteResponse: false,
  dialogMessage: false,
};

export const singerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SINGER:
      return {
        ...state,
        loading: true,
        createRespone: false,
        dialogMessage: false,
      };
    case CREATE_SINGER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        createRespone: true,
        dialogMessage: true,
      };
    case CREATE_SINGER_FAILURE:
      return {
        ...state,
        loading: false,
        createRespone: false,
        error: action.payload,
        dialogMessage: false,
      };
    case GET_SINGER:
      return {
        ...state,
        SingerData: [],
        loading: true,
      };
    case GET_SINGER_SUCCESS:
      return {
        ...state,
        SingerData: action.payload,
        error: "",
        loading: false,
      };
    case GET_SINGER_FAILURE:
      return {
        ...state,
        error: action.payload,
        SingerData: [],
        loading: false,
      };
    case UPDATE_SINGER:
      return {
        ...state,
        loading: true,
        updateResponse: false,
        dialogMessage: false,
      };
    case UPDATE_SINGER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        updateResponse: true,
        dialogMessage: true,
      };
    case UPDATE_SINGER_FAILURE:
      return {
        ...state,
        error: action.payload,
        updateResponse: false,
        dialogMessage: false,
        loading: false,
      };
    case DELETE_SINGER:
      return {
        ...state,
        loading: true,
        deleteResponse: false
      };
    case DELETE_SINGER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        deleteResponse: true,
      };
    case DELETE_SINGER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        deleteResponse: false
      };

    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };

    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,
        deleteResponse: false,
      };

    default:
      return state;
  }
};

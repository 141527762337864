import {
  Box,
  Grid,
  Pagination,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import UpdateStatusDialog from "../../commonLayout/UpdateStatusDialog";
import {
  deleteUser,
  toggleModal,
  getSubAdminUsers,
  updateUserStatus,
} from "../../redux/usermanagement/action";
import "../../styles/church.css";
import "../../styles/user-management.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import DeleteDialogBoxLayout from "../../commonLayout/DeleteDialogBoxLayout";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "var(--tertiary-color)" : "var(--tertiary-color)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#4F566C",
    boxSizing: "border-box",
  },
}));

const ViewUsers = (props) => {
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [updateStatusToggle, setUpdateStatusToggle] = useState(false);
  const matches = useMediaQuery("(max-width:1440px)");
  const [data, setData] = useState([]);

  const [sortDirection, setSortDirection] = useState("asc");
  const [rowData, setRowData] = useState([]);

  const handleEditRowData = (Value) => {
    props.handleEdit(Value);
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteUser(value.id);
    props.trackButtonClick("Delete User Button", 1);
    if (props.error) {
      alert("Error");
    }
  };

  const handleSort = () => {
    const sortedData = [...data].sort((a, b) => {
      if (sortDirection === "asc") {
        return a.fullName.localeCompare(b.fullName);
      } else {
        return b.fullName.localeCompare(a.fullName);
      }
    });
    setData(sortedData);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    setData(props.data);
    setPage(1);
    setCount(Math.ceil(data?.length / size));
  }, [ props.data, props.successResponse, props.deleteUserSuccess, props.statusSuccess ]);

  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const size = 5;

  useEffect(() => {
    setPaginatedData([...data.slice((page - 1) * size, page * size)]);
    setCount(Math.ceil(data?.length / size));
  }, [
    page,
    data,
    props.value,
    props.data,
    props.successResponse,
    props.deleteUserSuccess,
    props.statusSuccess,
  ]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const [state, setState] = useState(null);
  const [statusState, setStatusState] = useState(false);
  const [currStatus, setCurrStatus] = useState(null);
  const [id, setId] = useState(null);

  const handleUpdateStatus = (id, accountEnabled) => {
    setUpdateStatusToggle(true);
    setId(id);
    if (accountEnabled) {
      setState("Activate");
      setCurrStatus(true);
    } else {
      setState("Deactivate");
      setCurrStatus(false);
    }
  };

  useEffect(() => {
    if (id) {
      props.updateUserStatus(id, currStatus);
      props.getSubAdminUsers();
      props.trackButtonClick("Edit Users Button", 1);
      setStatusState(false);
      setId(null);
    }
  }, [statusState]);

  const closeSuccessDeleteModal = () => {
    props.toggleModal();
  };

  return (
    <>
      {deleteToggle && (
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          setDeleteToggle={setDeleteToggle}
          recordType="User"
          recordRowData={rowData}
          deleteRowData={deleteRowData}
        />
      )}
      {updateStatusToggle && (
        <UpdateStatusDialog
          state={state}
          recordType="User"
          deleteToggle={updateStatusToggle}
          setDeleteToggle={setUpdateStatusToggle}
          setStatusState={setStatusState}
        />
      )}
      {props.deleteUserSuccess && (
        <DeleteDialogBoxLayout
          deletedialogBoxToggle={props.deleteUserSuccess}
          closeDialogBox={closeSuccessDeleteModal}
          header={`User Details`}
          content="Deleted Successfully"
        />
      )}
      <Grid container xs={12} flexWrap="wrap" mt="1%">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ maxHeight: matches ? 240 : 500, overflowY: "auto", scrollbarWidth:'thin' }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow className="view_user_table_row">
                <TableCell
                  sortDirection={sortDirection}
                  className="view_user_cell"
                >
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={handleSort}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell className="view_user_cell">Phone Number</TableCell>
                <TableCell className="view_user_cell">Email ID</TableCell>
                <TableCell className="view_user_cell">Gurudwara</TableCell>
                <TableCell className="view_user_cell">Action</TableCell>
                <TableCell sx={{ p: 0 }} className="view_user_cell">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className="view_user_cell"
                  >
                    {row.fullName}
                  </TableCell>
                  <TableCell className="view_user_cell">
                    {row.phoneNumber}
                  </TableCell>
                  <TableCell className="view_user_cell">{row.email}</TableCell>
                  <TableCell className="view_user_cell">
                    {row.gurudwara}
                  </TableCell>
                  <TableCell sx={{ p: 0 }} className="view_user_cell">
                    <img
                      src="/edit-icon.svg"
                      alt="edit-icon"
                      onClick={() => handleEditRowData(row)}
                      className="church_edit_icon"
                    />
                    <img
                      src="/delete-icon.svg"
                      alt="delete-icon"
                      className="church_delete_icon"
                      onClick={() => handleDeleteData(row)}
                    />
                  </TableCell>

                  <TableCell sx={{ p: 0 }} className="view_user_cell">
                    <Box display="center" alignItems="center">
                      <AntSwitch
                        defaultChecked
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={() =>
                          handleUpdateStatus(row.id, !row.accountEnabled)
                        }
                        sx={{ marginRight: "10px" }}
                        checked={row.accountEnabled}
                      />
                      {row.accountEnabled ? (
                        <p
                          style={{
                            padding: 0,
                            margin: 4,
                            color: "var(--tertiary-color)",
                          }}
                        >
                          Active
                        </p>
                      ) : (
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            color: "#4F566C",
                          }}
                        >
                          Inactive
                        </p>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {paginatedData.length === 0 && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
        {count ? (
          <Box
            sx={{
              display: "flex",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size="large"
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage, subAdminUsers, updateUserStatus, deleteUserSuccess } =
    state.userReducer;

  return {
    successResponse: dialogMessage,
    subAdminUsers,
    updateUserStatus,
    deleteUserSuccess,
  };
};

export default connect(mapStateToProps, {
  updateUserStatus,
  getSubAdminUsers,
  deleteUser,
  toggleModal,
})(withMixpanelTracking(ViewUsers, "User"));

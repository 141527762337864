export const CREATE_NITNEM = "CREATE_NITNEM";
export const NITNEM_SUCCESS = "NITNEM_SUCCESS";
export const NITNEM_FAILURE = "NITNEM_FAILURE";

export const FETCH_ALL_NITNEM = "FETCH_ALL_NITNEM";
export const FETCH_ALL_NITNEM_SUCCESS = "FETCH_ALL_NITNEM_SUCCESS";
export const FETCH_ALL_NITNEM_FAILURE = "FETCH_ALL_NITNEM_FAILIURE";

export const UPDATE_NITNEM = "UPDATE_NITNEM";
export const UPDATE_NITNEM_SUCCESS = "UPDATE_NITNEM_SUCCESS";
export const UPDATE_NITNEM_FAILURE = "UPDATE_NITNEM_FAILURE";

export const DELETE_NITNEM = "DELETE_NITNEM";
export const DELETE_NITNEM_SUCCESS = "DELETE_NITNEM_SUCCESS";
export const DELETE_NITNEM_FAILURE = "DELETE_NITNEM_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

// defining actions for create nitnem
export const createNitnem = (data) => {
  return {
    type: CREATE_NITNEM,
    payload: data,
  };
};

export const nitnemSuccess = (data) => {
  return {
    type: NITNEM_SUCCESS,
    payload: data,
  };
};

export const nitnemFailure = (error) => {
  return {
    type: NITNEM_FAILURE,
    payload: error,
  };
};

// defining actions for get all nitnem
export const fetchAllNitnemWithPagination = (urlParameters) => {
  return {
    type: FETCH_ALL_NITNEM,
    params: urlParameters
  }
}

export const fetchAllNitnemSuccess = (data) => {
  return {
    type: FETCH_ALL_NITNEM_SUCCESS,
    payload: data,
  };
};

export const fetchAllNitnemFailure = (error) => {
  return {
    type: FETCH_ALL_NITNEM_FAILURE,
    payload: error,
  };
};

// defining actions for update nitnem
export const updateNitnem = (data, urlParameters) => {
  return {
    type: UPDATE_NITNEM,
    payload: data,
    params: urlParameters
  };
};

export const updateNitnemSuccess = (data) => {
  return {
    type: UPDATE_NITNEM_SUCCESS,
    payload: data,
  };
};

export const updateNitnemFailure = (error) => {
  return {
    type: UPDATE_NITNEM_FAILURE,
    payload: error,
  };
};

// defining actions for delete nitnem
export const deleteNitnem = (id) => {
  return {
    type: DELETE_NITNEM,
    params: id
  };
};

export const deleteNitnemSuccess = (data) => {
  return {
    type: DELETE_NITNEM_SUCCESS,
    payload: data,
  };
};

export const deleteNitnemFailure = (error) => {
  return {
    type: DELETE_NITNEM_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

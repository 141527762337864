import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminLogin } from "../redux/login/action";

import withMixpanelTracking from "./commonComponent/withMixPanelTracking"; // Import the Mixpanel wrapper

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ForgotPassword from "./ForgotPassword";
import "../styles/login.css";
import useCustomStyles from "../customHooks/useCustomStyles";
import { apiClient } from "../apiServices/AxiosClient";
import login from "../assets/images/login_image.svg";
import CreatePassword from "./CreatePassword";

const styles = (theme) => ({
  primary_text_color: { color: "#7F7F7F" },
  secondary_text_color: { color: "#C7332A" },
  tertiary_text_color: { color: "#4169E1" },
  main_container: { height: "100vh", overflow: "hidden" },
  login_title_text: {
    textAlign: "left",
    color: "#000000",
    fontSize: "36px",
    marginBottom: "55px",
  },
  login_image_container: {
    maxHeight: "100vh",
    display: { xs: "none", md: "block" },
  },
  login_fields_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100vh",
  },
  header: { padding: "5px", width: "500px" },
  helper_text: {
    marginBottom: "25px",
    marginLeft: "12px",
    textDecoration: "none",
    textTransform: "none",
    fontSize: "17px",
  },
  message_container: { marginTop: "10px" },
  message_text: { fontSize: "12px" },
  otp_header: { padding: "5px", width: "500px" },
  otp_sub_header: { fontSize: "17px", fontStyle: "#000000", margin: "2px 0" },
  otp_helper_text: { fontSize: "16px" },
  edit_button: {
    color: "#C7332A",
    marginLeft: "25px",
    textDecoration: "none",
    textTransform: "none",
  },
  otp_input_fields: {
    display: "flex",
    flexDirection: "row",
    my: 5,
    width: { xs: 300, md: 400, lg: 480 },
  },
  otp_error_message: { marginTop: "10px", fontSize: "12px" },
  otp_resend_text: { fontSize: "12px" },
  otp_resend_button: {
    color: "#4169E1",
    textDecoration: "none",
    textTransform: "none",
  },
  otp_verify_button: { marginTop: "20px" },
});

const Login = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [generateOTPToggle, setGenerateOTPToggle] = useState(false);
  const [OTPValidityToggle, setOTPValidityToggle] = useState(false);
  const [createPasswordToggle, setCreatePasswordToggle] = useState(false);
  const [otpArray, setOtpArray] = useState(new Array(4).fill(""));
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [profileData, setProfileData] = useState({
    fullName: props.userProfile?.fullName,
    email: props.userProfile?.email,
    phoneNumber: props.userProfile?.phoneNumber,
    role: "Admin",
  });

  const handleCreateNewPassword = (event) => {
    event.preventDefault();
    const joinedOTP = otpArray.join("");

    if (joinedOTP === "0000" || joinedOTP.length < 4) {
      setOTPValidityToggle(!OTPValidityToggle);
    } else {
      setCreatePasswordToggle(!createPasswordToggle);
    }
  };

  const handleForgetPasswordButtonClick = (event) => {
    event.preventDefault();
    setToggle(!toggle);
    props.trackButtonClick("Forget Password Button", 1);
  };

  const handleOtpInputChange = (event, index) => {
    if (isNaN(event.target.value)) {
      return false;
    }
    setOtpArray([
      ...otpArray.map((value, i) => (i === index ? event.target.value : value)),
    ]);
  };

  const sendOTP = () => {
    setMinutes(0);
    setSeconds(30);
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();

    const userObj = {
      userName: adminEmail,
      password: adminPassword,
    };

    props?.adminLogin(userObj);
  };

  const setLoggedInUserData = (successValue) => {
    if (successValue) {
      const loggedInUserData = props?.loggedInUserData?.data;

      // Save token and role to local storage
      localStorage.setItem("token", loggedInUserData?.accessToken);
      localStorage.setItem("role", loggedInUserData?.roles);

      // Set up the Axios interceptor
      apiClient.interceptors.request.use((config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      });

      navigate("/dashboard");

      // Track the button click event
      props.trackButtonClick("Login Button", 1);
    }
  };

  useEffect(() => {
    setLoggedInUserData(props?.successResponse);
  }, [props?.successResponse]);

  useEffect(() => {
    if (props.successResponse) {
      setProfileData({
        fullName: props.userProfile?.fullName,
        email: props.userProfile?.email,
        phoneNumber: props.userProfile?.phoneNumber,
        role: "Admin",
      });
      localStorage.setItem("currUser", JSON.stringify(profileData));
    }
  }, [props.userProfile, props.successResponse]);

  useEffect(() => {
    let timeInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timeInterval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  });

  return (
    <>
      <Grid container component="main" className={classes?.main_container}>
        <Grid className={classes?.login_image_container} item md={5}>
          <img src={login} alt="login-page-image" />
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          component={Paper}
          square
          className={classes?.login_fields_container}
        >
          {toggle === false ? (
            <Grid item xs={9} className={classes?.header}>
              <p className={classes?.login_title_text}>
                Sign-in to your account
              </p>

              <Box component="form" noValidate onSubmit={handleButtonClick}>
                <div>
                  <div>
                    <FormControl
                      variant="outlined"
                      sx={{
                        width: { xs: 200, md: 400, lg: 480 },
                        borderRadius: "12px",
                      }}
                    >
                      <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        inputProps={{
                          style: {
                            boxSizing: "content-box",
                            height: "27px",
                            fontSize: "18px",
                            color: "#7F7F7F",
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Username"
                        placeholder="xyz@example.com"
                        value={adminEmail}
                        onChange={(event) => {
                          setAdminEmail(event.target.value);
                        }}
                      />
                    </FormControl>
                    <br />
                    <FormControl
                      sx={{ mt: 3, width: { xs: 200, md: 400, lg: 480 } }}
                      variant="outlined"
                    >
                      <FormControl variant="outlined" required>
                        <InputLabel
                          htmlFor="outlined-adornment-password"
                          shrink
                        >
                          Enter Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          notched
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "#444444" }}
                                  />
                                ) : (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "#444444" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          autoComplete="off" // Disable browser autocomplete
                          value={adminPassword}
                          sx={{ borderRadius: "12px" }}
                          onChange={(event) =>
                            setAdminPassword(event.target.value)
                          }
                          label="Enter Password"
                        />
                      </FormControl>
                    </FormControl>
                  </div>

                  <div>
                    <br />
                    <Link
                      component={Button}
                      href="#"
                      onClick={handleForgetPasswordButtonClick}
                      variant="body2"
                      className={`${classes?.helper_text} ${classes?.tertiary_text_color}`}
                    >
                      Forgot password ?
                    </Link>
                  </div>

                  <div>
                    <Button
                      sx={{
                        py: 1.5,
                        width: { xs: 200, md: 400, lg: 480 },
                        backgroundColor: "#9E46E3",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#9E46E3",
                        },
                      }}
                      variant="contained"
                      type="submit"
                    >
                      <Typography fontSize="19px">Login</Typography>
                    </Button>
                  </div>
                </div>
              </Box>
            </Grid>
          ) : toggle === true && generateOTPToggle === false ? (
            <ForgotPassword
              forgotPasswordToggle={toggle}
              setForgotPasswordToggle={setToggle}
              generateOTPToggle={generateOTPToggle}
              setGenerateOTPToggle={setGenerateOTPToggle}
              sendOTP={sendOTP}
              minutes={minutes}
              seconds={seconds}
              setMinutes={setMinutes}
              setSeconds={setSeconds}
            />
          ) : createPasswordToggle === true ? (
            <CreatePassword
              createPasswordToggle={createPasswordToggle}
              setCreatePasswordToggle={setCreatePasswordToggle}
              otpArray={otpArray}
              handleOtpInputChange={handleOtpInputChange}
              OTPValidityToggle={OTPValidityToggle}
              setOTPValidityToggle={setOTPValidityToggle}
              handleCreateNewPassword={handleCreateNewPassword}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { success, data } = state.loginReducer;
  const { userProfile } = state.userReducer;

  return {
    userProfile,
    successResponse: success,
    loggedInUserData: data,
  };
};

// Wrap the Login component with Mixpanel tracking
export default connect(mapStateToProps, {
  adminLogin,
})(withMixpanelTracking(Login, "Login Screen"));

import { useState, useEffect } from "react";
import { css } from "@emotion/css";

// creating a custom hook as a replacement of makeStyles hook
const useCustomStyles = (styles, theme) => {
    const [classes, setClasses] = useState(null)

    useEffect(() => {
        if(!classes) {
            const resultantStyles = typeof styles === 'function' ? styles(theme) : styles;
            const generatedStyleClass = {};

            for (const key in resultantStyles) {
                generatedStyleClass[key] = css(resultantStyles[key]);
            }

            setClasses(generatedStyleClass);
        }
    }, [styles, theme, classes]);

    return classes;
};

export default useCustomStyles;

import { formatDateSeperatedWithHyphen } from "../helpers/dateHelper";
import { apiClient } from "./AxiosClient";

export const getUploadStatusFromApi = async () => {
  const res = await apiClient.get(
    `/thought/upload-status?date=${formatDateSeperatedWithHyphen()}`,
  );
  return res;
};

export const getTodayTaskFromApi = async () => {
  const res = await apiClient.get(`/task/complete/reports`);
  return res;
};

export const getLeaderBoardsFromApi = async () => {
  return await apiClient.get(`/game/coins/leaderBoard?size=100`);
};

export const getUserStatsFromApi = async () => {
  return await apiClient.get(`/admin/users-statistics`);
};

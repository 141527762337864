import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_HELPS,
  getHelpsFailure,
  getHelpsSuccess,
  UPDATE_HELPS,
  updateHelpsPutFailure,
  updateHelpsPutSuccess,
} from "./action";

import { getRequest, putRequest } from "../../apiServices/ApiActions";
import { getHelpsUrl, updateHelpsUrl } from "../../apiServices/ApiUrl";

// const createPost = async (payload) => {
//   return postRequest(createHelpsUrl, payload);
// };
const getPost = async () => {
  return getRequest(getHelpsUrl);
};
const updatePost = async (payload, id) => {
  return putRequest(updateHelpsUrl + `${id}`, payload);
};

// function* createHELPS(action) {
//   try {
//     const Result = yield call(createPost, action.payload);

//     if (Result.status === 200) {
//       yield put(createHelpsPostSuccess(Result));
//     } else {
//       yield put(createHelpsPostFailure(Result));
//     }
//   } catch (error) {
//     yield put(createHelpsPostFailure(error));
//   }
// }

function* updateHELPS(action) {
  try {
    const Result = yield call(updatePost, action.payload, action.id);
    if (Result.status === 200) {
      yield put(updateHelpsPutSuccess(Result));
    } else {
      yield put(updateHelpsPutFailure(Result));
    }
  } catch (error) {
    yield put(updateHelpsPutFailure(error));
  }
}

function* getHELPS() {
  try {
    const Result = yield call(getPost);

    if (Result.status === 200) {
      let response = Result.data;
      yield put(getHelpsSuccess(response.content));
    } else {
      yield put(getHelpsFailure(Result));
    }
  } catch (error) {
    yield put(getHelpsFailure(error));
  }
}

function* helpsSaga() {
  // yield takeEvery(CREATE_HELPS, createHELPS);
  yield takeEvery(GET_HELPS, getHELPS);
  yield takeEvery(UPDATE_HELPS, updateHELPS);
}
export default helpsSaga;

import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    LinearProgress,
    Avatar
} from "@mui/material";

const ProgressDialogLayout = ({ progressDialogOpen, handleProgressDialogClose, uploadFileName, toBeUploadedFileFormat,  uploadInProgress, progress, imageFileType}) => {
    return (
        <>
            <Dialog open={progressDialogOpen} onClose={handleProgressDialogClose}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleProgressDialogClose}
                        sx={{ position: 'absolute', right: "2%", top: "4%"}}
                    >
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Typography variant="h5" mt={4} ml={4}>
                        Uploading
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            minWidth: { xs: '200px', sm: '400px' },
                            p: 4,
                            borderRadius: 2
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            <Avatar sx={{ bgcolor: "#DBDBDB", mr: 2, width: "60px", height: "60px", color: 'black'}}>{`${ toBeUploadedFileFormat?.fileType }`}</Avatar>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="body1">
                                    {uploadFileName}
                                </Typography>

                                <LinearProgress variant="determinate" value={progress} color="primary" sx={{ width: '100%', mt: 2 }} />
                                <Typography variant="body2" color="textSecondary" align="start" sx={{ mt: 1 }} >{`${Math.round(progress)}% done`}</Typography>

                            </Box>
                        </Box>
                        
                        {uploadInProgress && (
                            <Button
                                type="submit" 
                                variant="contained" 
                                sx={{ 
                                    mt: "10%", backgroundColor: "var(--secondary-color)", ml: "80%"
                                }}
                                onClick={() => {
                                    handleProgressDialogClose();
                                }}
                            >
                                Cancel
                        </Button>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ProgressDialogLayout;

import { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  useTheme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import { deleteGranth, deleteToggleModal, getGranthdetails } from "../../redux/granth/action";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import { connect } from "react-redux";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";

const LanguageList = [
  { langName: "HINDI", LangCode: "hi_IN" },
  { langName: "ENGLISH", LangCode: "en_US" },
  { langName: "PUNJABI", LangCode: "pa_IN" },
];

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth:'thin',
    [theme.breakpoints.down(1535)]: {
      maxHeight: 245,
    },
    [theme.breakpoints.between(1536, 1919)]: {
      maxHeight: 320,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 510,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1535)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1536, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewBible = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [sortedData, setSortedData] = useState([]);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getGranthdetails(newPage);
  };

  const setGranthDataWithPagination = (loading) => {
    if (!loading && props?.granthData) {
      const { content, page } = props?.granthData;

      props.setGranthList(content);
      props.setRecordsList(content);
      setSortedData(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  const handleSortRequest = () => {
    if (sortedData?.length > 0) {
      const isAsc = orderDirection === "asc";
      const sorted = [...sortedData].sort((a, b) =>
        isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      );
      setOrderDirection(isAsc ? "desc" : "asc");
      setSortedData(sorted);
    }
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteGranth(value.id);
    props.trackButtonClick("Delete Bible Button", 1, value?.id);
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/bible/edit?data=${encodedData}`);
  };

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSuccessDelete(false);
  };

  useEffect(() => {
    setSortedData(props.granthList);

    if (props?.granthList?.length !== props?.recordsList?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setGranthDataWithPagination(false);
    }
  }, [props?.granthList]);

  useEffect(() => {
    props.getGranthdetails(paginationObject?.page);
  }, [props.getGranthdetails]);

  useEffect(() => {
    setGranthDataWithPagination(props.loading);
  }, [props.loading]);

  useEffect(() => {
    props.getGranthdetails(paginationObject?.page);
    setRowData([]);

    if(props.deleteGranthsucces) {
      setOpenSuccessDelete(true);
    }
  }, [props.deleteGranthsucces, props.updateGranthSuccess]);

  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        setDeleteToggle={setDeleteToggle}
        recordType="Granth"
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />

      <Grid container xs={12} flexWrap="wrap">
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes?.table}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active
                    direction={orderDirection}
                    onClick={handleSortRequest}
                  >
                    Book Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Uploaded By</TableCell>
                <TableCell>Uploaded on</TableCell>
                <TableCell align="right" sx={{ pr: 2.5 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData?.length > 0 &&
                sortedData.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {
                        LanguageList.filter(
                          (item) => item.LangCode === row?.languageCode,
                        )[0]?.langName
                      }
                    </TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell>
                      {formatDateInAlphanumericFormat(row.createdAt)}
                    </TableCell>
                    <TableCell align="right" sx={{ p: 0 }}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          props.setContentDataToBeUpdated(row);
                          handleRowEdit(row);
                          props.trackButtonClick("Edit Bible Button", 1);
                        }}
                      >
                        <img
                          className={classes?.icon_size}
                          src="/edit-icon.svg"
                          alt="edit"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteData(row)}
                      >
                        <img
                          className={classes?.icon_size}
                          src="/delete-icon.svg"
                          alt="delete-icon"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {sortedData === undefined ||
          (sortedData?.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto",
                marginTop: "30px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
        ))}

        {sortedData?.length > 0 ? (
          <Grid container sx={{ marginTop: "45px" }}>
            <Grid xs={5}></Grid>
            <Grid xs={4}>
              <Paginate
                paginationObject={paginationObject}
                handlePageChange={handlePageChange}
              />
            </Grid>
            <Grid xs={3}></Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    deleteGranthsucces,
    granthDataById,
    granthData,
    loading,
    updateGranthSuccess,
    getGrantSucces,
  } = state.granthReducer;

  return {
    deleteGranthsucces,
    granthDataById,
    granthData,
    loading,
    updateGranthSuccess,
    getGrantSucces,
  };
};

export default connect(mapStateToProps, { deleteGranth, getGranthdetails, deleteToggleModal })(
  withMixpanelTracking(ViewBible, "Bible"),
);

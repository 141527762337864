import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteDialogBoxLayout from "./DeleteDialogBoxLayout";

const UpdateStatusDialog = ({
  deleteToggle,
  setDeleteToggle,
  state,
  setStatusState,
  recordType,
  recordRowData,
  deleteRowData,
  openSuccessDelete,
  closeSuccessDeleteModal,
}) => {
  const [deletedialogBoxToggle, setDeletedialogBoxToggle] = useState(false);

  const closeDialogBox = () => {
    setDeleteToggle(false);
    setDeletedialogBoxToggle(false);
    if (typeof closeSuccessDeleteModal === "function") {
      closeSuccessDeleteModal();
    }
  };

  const HandleDeleteRow = () => {
    if (typeof deleteRowData === "function") {
      deleteRowData(recordRowData);
    }
  };

  const handleYesClick = () => {
    HandleDeleteRow();
    setDeleteToggle(false);
    if (typeof closeSuccessDeleteModal !== "function") {
      setTimeout(() => {
        setDeletedialogBoxToggle(true);
      }, 100);
    }
  };

  useEffect(() => {
    if (openSuccessDelete) {
      setDeletedialogBoxToggle(true);
    }
  }, [openSuccessDelete]);

  return (
    <>
      {deletedialogBoxToggle && (
        <DeleteDialogBoxLayout
          deletedialogBoxToggle={deletedialogBoxToggle}
          closeDialogBox={closeDialogBox}
          header={`${recordType} Details`}
          content="Deleted Successfully"
        />
      )}

      <Dialog open={deleteToggle} onClose={closeDialogBox} maxWidth="sm">
        <div style={{ backgroundColor: "#F5F5F5" }}>
          <DialogTitle>
            <IconButton
              onClick={closeDialogBox}
              sx={{
                position: "absolute",
                right: "2%",
                top: "4%",
                width: "2.6rem",
                height: "2.6rem",
                ":hover" : {backgroundColor:"#82858c"}
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box
              justifyContent={"center"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "250px", sm: "280px" },
                height: { xs: "120px", sm: "160px" },
                p: 2,
                borderRadius: 12,
                textAlign: "center",
              }}
            >
              <Box
                sx={{ fontSize: "18px", fontWeight: 600 }}
                style={{ marginBottom: "1rem" }}
              >
                <p>Are You Sure You</p>
                <p
                  style={{ marginTop: "-20px" }}
                >{`want to ${state} this ${recordType}?`}</p>
              </Box>

              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={5}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setStatusState(true);
                      closeDialogBox();
                    }}
                    sx={{
                      height: "35px",
                      backgroundColor: "#F5F5F5",
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "inherit",
                        color: "black",
                      },
                      color: "black",
                      borderRadius: 1,
                      fontSize: "16px",
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                  >
                    Yes
                  </Button>
                </Grid>

                <Grid item xs={5}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={closeDialogBox}
                    sx={{
                      height: "35px",
                      borderRadius: 1,
                      fontSize: "16px",
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default UpdateStatusDialog;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import "../../styles/kirtan.css";
import AddAlbum from "./AddAlbum";
import { connect } from "react-redux";
import { getAllAlbum, deleteAlbum, deleteToggleModal } from "../../redux/album/action";
import { formatDateSeperatedWithHyphen } from "../../helpers/dateHelper";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useTheme } from "@emotion/react";
import useCustomStyles from "../../customHooks/useCustomStyles";

const LanguageList = [
  { langName: "HINDI", LangCode: "hi_IN" },
  { langName: "ENGLISH", LangCode: "en_US" },
  { langName: "PUNJABI", LangCode: "pa_IN" },
];

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth:'thin',
    [theme.breakpoints.down(1535)]: {
      maxHeight: 225,
    },
    [theme.breakpoints.between(1536, 1919)]: {
      maxHeight: 320,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 510,
    },
  },
});

const ViewAlbum = ({
  openModal,
  setOpenModal,
  albumList,
  setAlbumList,
  setRecordsList,
  getAllAlbum,
  setContentDataToBeUpdated,
  ...props
}) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);
  const [rowData, setRowData] = useState([]);
  const [redordsData, setRecors] = useState([]);
  const [viewType, setViewtype] = useState();
  const [opeeditModal, setopenEditModal] = useState(false);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    getAllAlbum(newPage);
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const handleCloseEditBox = () => {
    setViewtype("");
    setopenEditModal(false);
  };

  const deleteRowData = (value) => {
    props.deleteAlbum(value.id);
  };

  const handleEditRow = (row) => {
    setRecors(row);
    setViewtype("edit");
    setopenEditModal(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let sortedSingers = [];

  if (albumList && albumList.length > 0) {
    sortedSingers = [...albumList].sort((a, b) => {
      if (order) {
        return (
          (a.albumName.toUpperCase() < b.albumName.toUpperCase() ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      }
      return 0;
    });
  }
  
  const setAlbumDataAndPagination = (loadingValue) => {
    if (!loadingValue && props?.AlbumData) {
      const { content, page } = props?.AlbumData;

      setAlbumList(content);
      setRecordsList(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/gospel/edit?data=${encodedData}`);
  };

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSuccessDelete(false);
  };

  useEffect(() => {
    setAlbumDataAndPagination(props?.loading);
  }, [props.loading]);

  useEffect(() => {
    getAllAlbum(paginationObject?.page);
    setRowData([]);

    if(props.deleteResponse) {
      setOpenSuccessDelete(true);
    }
  }, [props.deleteResponse]);

  return (
    <>
      {deleteToggle && (
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          recordType="Album"
          setDeleteToggle={setDeleteToggle}
          recordRowData={rowData}
          deleteRowData={deleteRowData}
          openSuccessDelete={openSuccessDelete}
          closeSuccessDeleteModal={closeSuccessDeleteModal}
        />
      )}

      {opeeditModal || openModal ? (
        <AddAlbum
          closeEditModal={openModal ? setOpenModal : handleCloseEditBox}
          recordsData={redordsData}
          viewType={viewType ? "Edit" : "Add"}
        />
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            className={classes?.table}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "albumName"}
                      direction={order}
                      onClick={() => handleRequestSort("albumName")}
                    >
                      Album Name
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>Language</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedSingers?.length > 0 &&
                  sortedSingers?.map((row) => (
                    <TableRow
                      key={row.title}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.albumName}
                      </TableCell>
                      <TableCell>
                        {
                          LanguageList.filter(
                            (item) => item.LangCode === row?.languageCode,
                          )[0]?.langName
                        }
                      </TableCell>
                      <TableCell>Admin</TableCell>
                      <TableCell>
                        {formatDateSeperatedWithHyphen(row.updatedAt)}
                      </TableCell>
                      <TableCell align="right" sx={{ p: 0 }}>
                        <IconButton
                          aria-label="edit"
                          sx={{
                            width: {
                              lg: "40px",
                              xl: "60px",
                            },
                            height: {
                              lg: "40px",
                              xl: "60px",
                            },
                          }}
                          onClick={() => {
                            setContentDataToBeUpdated(row);
                            handleRowEdit(row);
                            props.trackButtonClick("Edit Album Button", 1);
                          }}
                        >
                          <img src="/edit-icon.svg" alt="delete-icon" />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteData(row)}
                          sx={{
                            width: {
                              lg: "40px",
                              xl: "60px",
                            },
                            height: {
                              lg: "40px",
                              xl: "60px",
                            },
                          }}
                        >
                          <img src="/delete-icon.svg" alt="delete-icon" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {sortedSingers.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto",
                marginTop: "30px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          )}

          {sortedSingers.length > 0 ? (
            <Grid container sx={{ marginTop: "45px" }}>
              <Grid xs={5}></Grid>
              <Grid xs={4}>
                <Paginate
                  paginationObject={paginationObject}
                  handlePageChange={handlePageChange}
                />
              </Grid>
              <Grid xs={3}></Grid>
            </Grid>
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { AlbumData, createRespone, updateResponse, deleteResponse, loading } =
    state.albumReducer;
  return {
    AlbumData,
    createRespone,
    updateResponse,
    deleteResponse,
    loading,
  };
};

export default connect(mapStateToProps, {
  getAllAlbum,
  deleteAlbum,
  deleteToggleModal
})(withMixpanelTracking(ViewAlbum, "Album"));

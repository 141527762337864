import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
  useTheme,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  formatDateSeperatedWithHyphen,
  anyDate,
  todayDate,
} from "../../helpers/dateHelper";
import useCustomStyles from "../../customHooks/useCustomStyles";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";

import { fetchLanguage } from "../../redux/language/action";
import {
  createHukumnama,
  fetchHukumnama,
  updateHukumnama,
  showUnshowDialogMessage,
} from "../../redux/hukumnama/action";

const styles = (theme) => ({
  container: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px",
      marginTop: "20px",
      height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditDailyVerse = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);

  // defining all the necessary state variables
  const [hukumnamaObect, setHukumnamaObject] = useState({
    languageCode: "en_US",
    dateValue: todayDate(),
    title: "",
    description: "",
  });

  // function to add/update hukumnama
  const handleAddHukumnama = async (event) => {
    event.preventDefault();

    const hyphenFormattedDate = formatDateSeperatedWithHyphen(
      hukumnamaObect?.dateValue,
    );

    const newHukumnamaObject = {
      displaydate: hyphenFormattedDate,
      languageCode: hukumnamaObect?.languageCode,
      description: hukumnamaObect?.description,
      title: hukumnamaObect?.title,
    };

    if (action === "add") {
      // perform add hukumnama
      props.createHukumnama(newHukumnamaObject);
      props.trackButtonClick("Create Daily Verse Button", 1);
    } else if (action === "edit") {
      // perform edit hukumnama
      props.updateHukumnama(newHukumnamaObject, editRecords?.id);
      props.trackButtonClick("Update Daily Verse Button", 1);
    }
  };

  // function to check if there is any hukumnama created in today's date or not
  const getHukumnama = (languageCode) => {
    if (languageCode !== "") {
      props.fetchHukumnama(languageCode);
    }
  };

  // function that will change the behaviour in either add or edit
  const setInputFieldsOnGetHukumnamaData = (successValue) => {
    if (action === "add" && successValue) {
      const hyphenFormattedDate = formatDateSeperatedWithHyphen(todayDate());
      const todayExistingHukumnamaRecord =
        props?.todayHukumnamaData &&
        props?.todayHukumnamaData?.data?.hukumnama[0];

      // if today hukumnama has already been created for each of the languages
      if (
        hyphenFormattedDate === todayExistingHukumnamaRecord?.displaydate &&
        todayExistingHukumnamaRecord?.languageCode ===
          hukumnamaObect?.languageCode
      ) {
        /* populate the fields with fetched hukumnama data and 
                make the add hukumnama button perform update operation */
        setHukumnamaObject({
          ...hukumnamaObect,
          languageCode: todayExistingHukumnamaRecord?.languageCode,
          title: todayExistingHukumnamaRecord?.title,
          description: todayExistingHukumnamaRecord?.description,
        });
      } else {
        // make the add hukumnama button perform add operation
        setHukumnamaObject({
          ...hukumnamaObect,
          title: "",
          description: "",
        });
      }
    } else if (action === "edit") {
      setHukumnamaObject({
        ...hukumnamaObect,
        dateValue: anyDate(editRecords?.displaydate),
        languageCode: editRecords?.languageCode,
        title: editRecords?.title,
        description: editRecords?.description,
      });
    }
  };

  // function that will reset the input fields after succesing add/edit operation
  const resetInputFieldsOnCreateSuccess = (successValue) => {
    if (successValue) {
      setHukumnamaObject({
        ...hukumnamaObect,
        languageCode: "",
        title: "",
        description: "",
      });
    }
  };

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const getAllLanguagesList = () => {
    props.fetchLanguage();
  };

  useEffect(() => {
    if (action === "add") {
      getHukumnama(hukumnamaObect?.languageCode);
    }
  }, [hukumnamaObect?.languageCode]);

  useEffect(() => {
    setInputFieldsOnGetHukumnamaData(props?.getHukumnamaSuccess);
  }, [props?.getHukumnamaSuccess, hukumnamaObect?.languageCode]);

  useEffect(() => {
    getAllLanguagesList();
  }, []);

  useEffect(() => {
    resetInputFieldsOnCreateSuccess(props.successResponse);
  }, [props.successResponse]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header="Uploaded Successfully!"
          content="You Have Uploaded Your File Successfully"
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleAddHukumnama}
      >
        <div>
          <Stack
            spacing={8}
            direction="column"
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <Stack spacing={4} direction="row">
              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={ matches?'small':'' }
              >
                <InputLabel id="demo-simple-select-label" sx={{ mt: 1 }}>
                  Choose Language *
                </InputLabel>
                <Select
                  required
                  className={classes?.input_box}
                  sx={{ mt: 1 }}
                  readOnly={action === "add" ? false : true}
                  InputLabelProps={{ shrink: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={hukumnamaObect?.languageCode}
                  label="Select Language *"
                  placeholder="Choose Language"
                  onChange={(event) => {
                    setHukumnamaObject({
                      ...hukumnamaObect,
                      languageCode: event.target.value,
                    });
                  }}
                >
                  {props?.languageData?.data?.length > 0 &&
                    props?.languageData?.data.map((language) => (
                      <MenuItem value={language?.code}>
                        {language?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    className={classes?.input_box}
                    readOnly
                    sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                    InputLabelProps={{ shrink: true }}
                    label="Date *"
                    format="DD/MM/YYYY"
                    value={hukumnamaObect?.dateValue}
                    onChange={(newValue) =>
                      setHukumnamaObject({
                        ...hukumnamaObect,
                        dateValue: newValue,
                      })
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>

            <Stack spacing={4} direction="row">
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  required
                  className={classes?.input_box}
                  id="outlined-multiline-static"
                  InputLabelProps={{ shrink: true }}
                  label="Content"
                  placeholder="Content"
                  size={ matches?'small':'' }
                  multiline
                  rows={6}
                  value={hukumnamaObect?.description}
                  onChange={(event) =>
                    setHukumnamaObject({
                      ...hukumnamaObect,
                      description: event.target.value,
                    })
                  }
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  required
                  className={classes?.input_box}
                  id="outlined-basic"
                  InputLabelProps={{ shrink: true }}
                  label="Title"
                  placeholder="Title"
                  size={ matches?'small':'' }
                  variant="outlined"
                  value={hukumnamaObect?.title}
                  onChange={(event) =>
                    setHukumnamaObject({
                      ...hukumnamaObect,
                      title: event.target.value,
                    })
                  }
                />
              </FormControl>
            </Stack>
          </Stack>

          <Grid xs={12} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              type="submit"
              startIcon={<FileUploadOutlined />}
              size="small"
              sx={{
                backgroundColor: "var(--secondary-color)",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "500",
                marginLeft: { sm: 11, md: 52, lg: 85, xl: 110 },
              }}
            >
              {action === "edit" ? "Update" : "Upload"}
            </Button>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    dialogMessage,
    getHukumnamaSuccess,
    hukumnamaData,
    todayHukumnamaData,
  } = state.hukumnamaReducer;
  const { success, languageData } = state.languageReducer;

  return {
    successResponse: dialogMessage,
    getHukumnamaSuccess,
    hukumnamaData,
    todayHukumnamaData,
    languageSuccessResponse: success,
    languageData,
    contentDataToBeUpdated: ownProps.contentDataToBeUpdated,
  };
};

export default connect(mapStateToProps, {
  createHukumnama,
  fetchHukumnama,
  updateHukumnama,
  showUnshowDialogMessage,
  fetchLanguage,
})(withMixpanelTracking(AddEditDailyVerse, "Add Daily Verse"));

import {
    CREATE_NITNEM,
    NITNEM_SUCCESS,
    NITNEM_FAILURE,
    FETCH_ALL_NITNEM,
    FETCH_ALL_NITNEM_SUCCESS,
    FETCH_ALL_NITNEM_FAILURE,
    UPDATE_NITNEM,
    UPDATE_NITNEM_SUCCESS,
    UPDATE_NITNEM_FAILURE,
    DELETE_NITNEM,
    DELETE_NITNEM_SUCCESS,
    DELETE_NITNEM_FAILURE,
    DIALOG_MESSAGE,
    DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
    dialogMessage: false,
    deleteSuccess: false,
    loading: false,
    error: "",
    nitnemData: [],
};

export const nitnemReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_NITNEM:
            return {
                ...state,
                error: "",
                dialogMessage: false,
                loading: true,
            };

        case NITNEM_SUCCESS:
            return {
                ...state,
                error: "",
                dialogMessage: true,
                loading: false,
            };

        case NITNEM_FAILURE:
            return {
                ...state,
                error: action.payload,
                dialogMessage: false,
                loading: false,
            };

        case FETCH_ALL_NITNEM:
            return {
                ...state,
                nitnemData: [],
                error: "",
                loading: true
            }

        case FETCH_ALL_NITNEM_SUCCESS:
            return {
                ...state,
                nitnemData: action.payload,
                error: "",
                loading: false
            }

        case FETCH_ALL_NITNEM_FAILURE:
            return {
                ...state,
                nitnemData: [],
                error: action.payload,
                loading: false
            }

        case UPDATE_NITNEM:
            return {
                ...state,
                error: "",
                dialogMessage: false,
                loading: true,
            };

        case UPDATE_NITNEM_SUCCESS:
            return {
                ...state,
                error: "",
                dialogMessage: true,
                loading: false,
            };

        case UPDATE_NITNEM_FAILURE:
            return {
                ...state,
                error: action.payload,
                dialogMessage: false,
                loading: false,
            };

        case DELETE_NITNEM:
            return {
                ...state,
                error: "",
                deleteSuccess: false,
                loading: true
            }

        case DELETE_NITNEM_SUCCESS:
            return {
                ...state,
                error: "",
                deleteSuccess: true,
                loading: false
            }

        case DELETE_NITNEM_FAILURE:
            return {
                ...state,
                error: action.payload,
                deleteSuccess: false,
                loading: false
            }

        case DIALOG_MESSAGE:
            return {
                ...state,
                dialogMessage: false
            }

        case DELETE_TOGGLE_MODAL_STATUS:
            return {
                ...state,
                deleteSuccess: false,
            };

        default:
            return state;
    }
};

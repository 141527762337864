import {
    CircularProgress
} from "@mui/material";


const LoaderLayout = () => {
    return (
        <>
            <span>
                Please wait...
            </span>
            <CircularProgress color="primary" sx={{ marginLeft: { sm: 11, md: 25, lg: 40 }}} />
        </>
    )
}

export default LoaderLayout;

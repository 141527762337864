import {
  GET_VOLUNTEER_DETAILS,
  GET_VOLUNTEER_SUCCESS_DETAILS,
  GET_VOLUNTEER_FAILURE_DETAILS,
  GET_VOLUNTEER_DOWNLOAD,
  GET_VOLUNTEER_DOWNLOAD_SUCCESS,
  GET_VOLUNTEER_DOWNLOAD_FAILURE,
  GET_VOLUNTEER_BY_STATUS,
  GET_VOLUNTEER_BY_STATUS_SUCCESS,
  GET_VOLUNTEER_BY_STATUS_FAILURE,
  UPDATE_VOLUNTEER_BY_STATUS,
  UPDATE_VOLUNTEER_BY_STATUS_SUCCESS,
  UPDATE_VOLUNTEER_BY_STATUS_FAILURE,
  GET_VOLUNTEER_LIST,
  GET_VOLUNTEER_LIST_SUCCESS,
  GET_VOLUNTEER_LIST_FAILURE,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  volunteerData: [],
  downloadData: null,
  volunteerList: [],
  volunteerListByStatus: [],
  getVolunteerSuccess: false,
  getByStatusSuccess: false,
  updateByStatusSuccess: false,
  getListSuccess: false,
};

export const volunteerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VOLUNTEER_DETAILS:
      return {
        ...state,
        volunteerData: [],
        error: "",
        getVolunteerSuccess: false,
        loading: true,
      };
    case GET_VOLUNTEER_SUCCESS_DETAILS:
      return {
        ...state,
        volunteerData: action.payload,
        error: "",
        getVolunteerSuccess: true,
        loading: false,
      };

    case GET_VOLUNTEER_FAILURE_DETAILS:
      return {
        ...state,
        volunteerData: [],
        error: action.payload,
        getVolunteerSuccess: false,
        loading: false,
      };
    case GET_VOLUNTEER_DOWNLOAD:
      return {
        ...state,
        volunteerData: [],
        error: "",
        getVolunteerSuccess: false,
        loading: true,
      };
    case GET_VOLUNTEER_DOWNLOAD_SUCCESS:
      return {
        ...state,
        // volunteerData: action.payload,
        error: "",
        // getVolunteerSuccess: true,
        loading: false,
      };
    case GET_VOLUNTEER_DOWNLOAD_FAILURE:
      return {
        ...state,
        // volunteerData: [],
        error: action.payload,
        // getVolunteerSuccess: false,
        loading: false,
      };
    case GET_VOLUNTEER_LIST:
      return {
        ...state,
        volunteerList: [],
        error: "",
        getListSuccess: false,
      };

    case GET_VOLUNTEER_LIST_SUCCESS:
      return {
        ...state,
        volunteerList: action.payload,
        error: "",
        getListSuccess: true,
      };
    case GET_VOLUNTEER_LIST_FAILURE:
      return {
        ...state,

        error: action.payload,
        getListSuccess: false,
      };
    case GET_VOLUNTEER_BY_STATUS:
      return {
        ...state,
        volunteerListByStatus: [],
        error: "",
        getByStatusSuccess: false,
      };
    case GET_VOLUNTEER_BY_STATUS_SUCCESS:
      return {
        ...state,
        volunteerListByStatus: action.payload,
        error: action.payload,
        getByStatusSuccess: true,
      };
    case GET_VOLUNTEER_BY_STATUS_FAILURE:
      return {
        ...state,
        volunteerListByStatus: [],
        error: action.payload,
        getByStatusSuccess: false,
      };
    case UPDATE_VOLUNTEER_BY_STATUS:
      return {
        ...state,

        error: "",
        updateByStatusSuccess: false,
      };
    case UPDATE_VOLUNTEER_BY_STATUS_SUCCESS:
      return {
        ...state,

        error: "",
        updateByStatusSuccess: true,
      };
    case UPDATE_VOLUNTEER_BY_STATUS_FAILURE:
      return {
        ...state,

        error: action.payload,
        updateByStatusSuccess: false,
      };

    default:
      return state;
  }
};

import MenuLayout from "../commonLayout/MenuLayout";

const CrashAnalytics = () => {
    return (
        <h1>Crash Analytics</h1>
    )
}

const wrapper = () => {
    return (
      <MenuLayout childComponent = { () => { return ( <CrashAnalytics /> ) } }/>
    )
}

export default wrapper;

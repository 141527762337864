import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "./action";

const initialState = {
  open: false,
  message: "",
  severity: "",
};

export const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.message,
        severity: action.severity,
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        open: false,
        message: "",
        severity: "",
      };

    default:
      return state;
  }
};

export const CREATE_HELPS = "CREATE_HELPS";
export const CREATE_HELPS_SUCCESS = "CREATE_HELPS_SUCCESS";
export const CREATE_HELPS_FAILURE = "CREATE_HELPS_FAILURE";

export const GET_HELPS = "GET_HELPS";
export const GET_HELPS_SUCCESS = "GET_HELPS_SUCCESS";
export const GET_HELPS_FAILURE = "GET_HELPS_FAILURE";

export const UPDATE_HELPS = "UPDATE_HELPS";
export const UPDATE_HELPS_SUCCESS = "UPDATE_HELPS_SUCCESS";
export const UPDATE_HELPS_FAILURE = "UPDATE_HELPS_FAILURE";

export const updateHelps = (data,id) => {
  return {
    type: UPDATE_HELPS,
    payload: data,
    id:id
  };
};

export const updateHelpsPutSuccess = (data) => {
  return {
    type: UPDATE_HELPS_SUCCESS,
    payload: data,
  };
};
export const updateHelpsPutFailure = (error) => {
  return {
    type: UPDATE_HELPS_FAILURE,
    payload: error,
  };
};

export const createHelps = (data) => {
  return {
    type: CREATE_HELPS,
    payload: data,
  };
};
export const createHelpsPostSuccess = (data) => {
  return {
    type: CREATE_HELPS_SUCCESS,
    payload: data,
  };
};
export const createHelpsPostFailure = (error) => {
  return {
    type: CREATE_HELPS_FAILURE,
    payload: error,
  };
};

export const getHelps = () => {
  return {
    type: GET_HELPS,
  };
};
export const getHelpsSuccess = (data) => {
  return {
    type: GET_HELPS_SUCCESS,
    payload: data,
  };
};
export const getHelpsFailure = (err) => {
  return {
    type: GET_HELPS_FAILURE,
    payload: err,
  };
};
import React, { useCallback } from "react";

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Grid,
  Stack,
  FormControl,
  TextField,
  Button,
  useTheme,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import useCustomStyles from "../../customHooks/useCustomStyles";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import LoaderLayout from "../../commonLayout/LoaderLayout";
import { fetchLanguage } from "../../redux/language/action";
import {
  createKirtanWithSinger,
  updateKirtanWithSinger,
  createKirtanWithAlbum,
  updateKirtanWithAlbum,
  getKirtanWithSinger,
  getKirtanWithAlbum,
  showUnshowDialogMessage,
} from "../../redux/kirtan/action";

import { getAllSinger } from "../../redux/singer/action";
import { getAllAlbum } from "../../redux/album/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddAlbum from "./AddAlbumNew";
import AddSinger from "./AddSingerNew";
import { truncateAtDot } from "../../helpers/truncateFileName";

const styles = (theme) => ({
  container: {
    marginTop: "45px",
    backgroundColor: "#F9F3FB",
    borderRadius: "8px",
    padding: "0 10px 300px",
    height: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const UploadGospel = ({ closeEditModal, ...props }) => {
  const theme = useTheme();
  const { action } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);

  const editRecords = JSON.parse(decodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);

  const [uploadKirtan, setUploadKirtan] = useState({
    songName: "",
    songImage: "",
    audioFile: "",
    songDuration: "",
    singerId: null,
    albumId: null,
    singerName: "",
    albumName: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [audioFileName, setAudioFileName] = useState("");
  const [disableSinger, setSingerdisabled] = useState(true);
  const [disableAlbum, setAlbumdisabled] = useState(true);
  const [addMore, setAddMore] = useState(false);
  const [addMoreCount, setAddMoreCount] = useState([]);
  const [uploadType, setUploadType] = useState("");

  const resetInputFieldsOnApiSuccess = useCallback(
    (successValue, loadingValue) => {
      if (successValue) {
        setLoading(false);
        setUploadKirtan((prevUploadKirtan) => ({
          ...prevUploadKirtan,
          songName: "",
          songImage: "",
          audioFile: "",
          songDuration: "",
          singerId: null,
          albumId: null,
          singerName: "",
          albumName: "",
        }));
        setFileName("");
        setAudioFileName("");
        setSingerdisabled(true);
        setAlbumdisabled(true);
        props.showUnshowDialogMessage();
        navigate(`/content/gospel/view`);
      } else {
        setLoading(loadingValue);
      }
    },
    [],
  );

  const resetInputFieldForAddMore = useCallback(() => {
    if (addMoreCount.length < 10) {
      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,
        songName: "",
        songImage: "",
        audioFile: "",
        songDuration: "",
      }));
      setFileName("");
      setAudioFileName("");
      setSingerdisabled(true);
      setAlbumdisabled(true);
    } else {
      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,
        songName: "",
        songImage: "",
        audioFile: "",
        songDuration: "",
      }));
      setFileName("");
      setAudioFileName("");
      setSingerdisabled(true);
      setAlbumdisabled(true);
      props.showUnshowDialogMessage();
      navigate(`/content/gospel/view`);
      setAddMore(false);
    }
  }, []);

  useEffect(() => {
    if (props?.dialogMessage) {
      if (addMore) {
        setAddMoreCount([...addMoreCount, "song"]);
        resetInputFieldForAddMore();
      } else {
        resetInputFieldsOnApiSuccess(true, "Created Successfully");
      }
    }
  }, [props.createResponse, props.updateResponse]);

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;
      console.log("thisrecords", editRecords);

      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,

        songName: records.songName,

        songDuration: records.songDuration,
        singerId: records.singerId ? records.singerId : null,
        albumId: records.albumId ? records.albumId : null,
        singerName: records.singerName ? records.singerName : null,
        albumName: records.albumName ? records.albumName : null,
      }));
      setFileName("song image.jpg");
      setAudioFileName("audioSong.mp3");
    }
  }, []);

  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setUploadType(fileType);
    setDialogOpen(true);
  };

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    if (uploadType === "JPEG") {
      setFileName("");
    } else if (uploadType === "MP3") {
      setAudioFileName("");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setUploadFileName(file.name);
      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,
        songImage: file,
      }));
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else if (file && file.type === "audio/mpeg") {
      setUploadFileName(file.name);
      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,
        audioFile: file,
      }));
      setAudioFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else {
      alert("Please upload a valid file.");
    }
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval);
    };
    UploadProgress();
  };

  const handleKirtanUpload = (event) => {
    const formData = new FormData();

    formData.append("songName", uploadKirtan?.songName);
    formData.append("songDuration", uploadKirtan?.songDuration);

    if (action === "edit" && !uploadKirtan.audioFile) {
      formData.append("audioFile", editRecords.songPresignedUrl);
    } else {
      formData.append("audioFile", uploadKirtan?.audioFile);
    }
    if (action === "edit" && !uploadKirtan.songImage) {
      formData.append("songImage", editRecords.thumbnailImageUrl);
    } else {
      formData.append("songImage", uploadKirtan?.songImage);
    }

    if (
      uploadKirtan?.albumId !== null &&
      action !== "edit" &&
      uploadKirtan.albumId
    ) {
      formData.append("albumId", uploadKirtan?.albumId);

      props.createKirtanWithAlbum(formData);
      props.trackButtonClick("Create Gospel Button", 1, editRecords?.songName);
    }
    if (
      uploadKirtan?.singerId !== null &&
      action !== "edit" &&
      uploadKirtan.singerId
    ) {
      formData.append("singerId", uploadKirtan?.singerId);

      props.createKirtanWithSinger(formData);
      props.trackButtonClick("Create Gospel Button", 1, editRecords?.songName);
    }
    if (action === "edit" && uploadKirtan.albumId != null) {
      formData.append("albumId", uploadKirtan?.albumId);

      props.updateKirtanWithAlbum(formData, editRecords.songId);
      props.trackButtonClick("Update Gospel Button", 1, editRecords?.songName);
    }
    if (action === "edit" && uploadKirtan.singerId != null) {
      formData.append("singerId", uploadKirtan?.singerId);

      props.updateKirtanWithSinger(formData, editRecords.songId);
      props.trackButtonClick("Update Gospel Button", 1, editRecords?.songName);
    }
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate("/content/gospel/view");
  };

  useEffect(() => {
    props.getAllAlbum(1);
    props.getAllSinger(1);
  }, []);

  const [openSingerDialoge, setOpenSingerDialoge] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const handleCreateSong = (val) => {
    setOpenSingerDialoge(true);
    setDialogType(val);
  };

  // const handleAddMore = () => {
  //   setAddMore(true);
  //   handleKirtanUpload();
  // };
  const handleUpload = () => {
    setAddMore(false);
    handleKirtanUpload();
  };

  return (
    <>
      <div className="kirtan__upload-container">
        {dialogOpen && (
          <DialogBoxFileUploadLayout
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            uploadInProgress={uploadInProgress}
            progress={progress}
            handleFileChange={handleFileChange}
            toBeUploadedFileFormat={toBeUploadedFileFormat}
          />
        )}

        {progressDialogOpen && (
          <ProgressDialogLayout
            progressDialogOpen={progressDialogOpen}
            handleProgressDialogClose={handleProgressDialogClose}
            uploadFileName={uploadFileName}
            uploadInProgress={uploadInProgress}
            progress={progress}
          />
        )}

        <Dialog
          open={openSingerDialoge}
          onClose={() => setOpenSingerDialoge(false)}
          maxWidth="sm"
        >
          <div style={{ backgroundColor: "#F5F5F5" }}>
            <DialogTitle>{`Add ${dialogType}`}</DialogTitle>
            <IconButton
              onClick={() => setOpenSingerDialoge(false)}
              sx={{
                position: "absolute",
                right: "2%",
                top: "4%",
                width: "3rem",
                height: "3rem",
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent>
              <Box>
                {dialogType === "Album" ? (
                  <AddAlbum HandleCloseAbum={setOpenSingerDialoge}></AddAlbum>
                ) : (
                  <AddSinger HandleCloseAbum={setOpenSingerDialoge} />
                )}
              </Box>
            </DialogContent>
          </div>
        </Dialog>

        <Grid
          xs={12}
          sx={{
            width: {
              xl: "60%",
            },
            margin: {
              xl: "auto",
            },
            marginTop: {
              xl: "80px",
            },
            paddingBottom: {
              xl: "60px",
            },
          }}
          component="form"
          onSubmit={handleKirtanUpload}
        >
          <div>
            <Stack
              spacing={{ lg: 1, xl: 2 }}
              direction="column"
              gap={{ lg: 1, xl: 3 }}
            >
              {action === "Add" ? (
                <Grid
                  container
                  spacing={{ lg: 8, xl: 20 }}
                  sx={{
                    borderBottom: "3px solid white",
                    paddingBottom: "20px",
                  }}
                >
                  <Grid xs={6}>
                    <FormControl
                      sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                      size={matches ? "small" : ""}
                    >
                      <InputLabel id="demo-simple-select-label" sx={{ mt: 1 }}>
                        Album Name
                      </InputLabel>
                      <Select
                        required
                        disabled={uploadKirtan?.singerId ? true : false}
                        className={classes?.input_box}
                        sx={{ mt: 1 }}
                        InputLabelProps={{ shrink: true }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Album Name"
                        placeholder="Enter Album Name"
                        size={matches ? "small" : ""}
                        value={uploadKirtan?.albumId}
                        onChange={(event) =>
                          setUploadKirtan({
                            ...uploadKirtan,
                            albumId: event.target.value,
                            albumName: event.target.value,
                          })
                        }
                      >
                        <MenuItem value="">
                          <em>Select Album</em>
                        </MenuItem>
                        {props?.AlbumData?.length > 0 &&
                          props?.AlbumData?.map((item) => (
                            <MenuItem value={item.id}>
                              {item?.albumName}
                            </MenuItem>
                          ))}
                      </Select>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "var(--secondary-color)",
                          cursor: "pointer",
                          textAlign: "end",
                          margin: "10px 0px 0px 0px",
                        }}
                        onClick={() => handleCreateSong("Album")}
                      >
                        Create album
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    {" "}
                    <FormControl
                      sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                      size={matches ? "small" : ""}
                    >
                      <InputLabel id="demo-simple-select-label" sx={{ mt: 1 }}>
                        Singer Name
                      </InputLabel>
                      <Select
                        disabled={uploadKirtan?.albumId ? true : false}
                        required
                        className={classes?.input_box}
                        sx={{ mt: 1 }}
                        InputLabelProps={{ shrink: true }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Singer Name"
                        placeholder="Enter Singer Name"
                        value={uploadKirtan?.singerId}
                        size={matches ? "small" : ""}
                        onChange={(event) =>
                          setUploadKirtan({
                            ...uploadKirtan,
                            singerId: event.target.value,
                            singerName: event.target.value,
                          })
                        }
                      >
                        <MenuItem value="">
                          <em>Select Singer</em>
                        </MenuItem>
                        {props?.SingerData?.length > 0 &&
                          props?.SingerData?.map((item) => (
                            <MenuItem value={item.id}>
                              {item?.singerName}
                            </MenuItem>
                          ))}
                      </Select>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "var(--secondary-color)",
                          cursor: "pointer",
                          textAlign: "end",
                          margin: "10px 0px 0px 0px",
                        }}
                        onClick={() => handleCreateSong("Singer")}
                      >
                        Create Singer
                      </p>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              {addMore && addMoreCount?.length > 0 ? (
                <div style={{ display: "flex" }}>
                  {addMoreCount?.length > 0 &&
                    addMoreCount.map((item, index) => (
                      <span
                        style={{
                          border: "1px solid #9E46E3",
                          display: "flex",
                          margin: "0px 10px 10px 0px",
                          borderRadius: "10px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            margin: 0,
                            padding: 5,
                          }}
                        >{`song ${index + 1}`}</p>
                        <CloseIcon
                          style={{
                            height: "20px",
                            cursor: "pointer",
                            margin: "10px",
                          }}
                        />
                      </span>
                    ))}
                </div>
              ) : null}
              <Grid container spacing={{ lg: 8, xl: 20 }}>
                <Grid xs={6}>
                  {" "}
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 }, mb: 2 }}
                  >
                    <TextField
                      className={classes?.input_box}
                      id="outlined-basic"
                      placeholder="Add File"
                      label="Song Image"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      size={matches ? "small" : ""}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <>
                            <Button
                              aria-label="upload"
                              component="label"
                              className="addFileButton"
                              size={matches ? "small" : ""}
                              onClick={() => {
                                handleOpen(
                                  "JPEG",
                                  ["JPEG", "PNG"],
                                  [".jpeg", ".png"],
                                );
                              }}
                            >
                              Add File
                            </Button>
                          </>
                        ),
                      }}
                      value={truncateAtDot(fileName)}
                    />
                    <p
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#4c4c4c",

                        textAlign: "end",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      *In JPEG/PNG format Only
                    </p>
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{
                      width: { xs: 200, md: 250, lg: 400 },
                    }}
                  >
                    <TextField
                      className={classes?.input_box}
                      id="outlined-basic"
                      size={matches ? "small" : ""}
                      placeholder="Add File"
                      label="Audio File"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <>
                            <Button
                              aria-label="upload"
                              component="label"
                              className="addFileButton"
                              size={matches ? "small" : ""}
                              onClick={() => {
                                handleOpen("MP3", ["MP3"], [".mp3"]);
                              }}
                            >
                              Add File
                            </Button>
                          </>
                        ),
                      }}
                      value={audioFileName ? truncateAtDot(audioFileName) : ""}
                    />
                    <p
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#4c4c4c",

                        textAlign: "end",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      *In MP3/MP4 format Only
                    </p>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={{ lg: 8, xl: 20 }}>
                <Grid xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                  >
                    <TextField
                      className={classes?.input_box}
                      size={matches ? "small" : ""}
                      id="outlined-basic"
                      placeholder="Enter Song Name"
                      label="Song Name"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={uploadKirtan?.songName}
                      onChange={(event) =>
                        setUploadKirtan({
                          ...uploadKirtan,
                          songName: event.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  {" "}
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                  >
                    <TextField
                      className={classes?.input_box}
                      size={matches ? "small" : ""}
                      id="outlined-basic"
                      placeholder="Enter song duration"
                      label="Song Duration"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={uploadKirtan?.songDuration}
                      onChange={(event) =>
                        setUploadKirtan({
                          ...uploadKirtan,
                          songDuration: event.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>

            {loading ? (
              <LoaderLayout />
            ) : (
              <div sx={{ float: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<FileUploadOutlined />}
                  size="small"
                  sx={{
                    backgroundColor: "var(--secondary-color)",
                    textTransform: "none",
                    fontSize: {
                      lg: "14px",
                      xl: "20px",
                    },
                    fontWeight: "500",
                    marginTop: "30px",
                    textAlign: "end",
                    float: "right",
                    marginLeft: "30px",
                  }}
                  onClick={handleUpload}
                >
                  {action === "edit" ? "Update" : "Upload"}
                </Button>

                {/* {action === "Add" ? (
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      color: "#9E46E3",
                      border: "1px solid #9E46E3",
                      fontSize: {
                        lg: "14px",
                        xl: "20px",
                      },
                      fontWeight: "500",
                      marginTop: "30px",
                      textAlign: "end",
                      float: "right",
                      position: "relative",
                    }}
                    onClick={handleAddMore}
                  >
                    Add more
                    <Box
                      sx={{
                        position: "absolute",
                        top: "44px",
                        fontSize: {
                          lg: "10px",
                          xl: "12px",
                        },
                        color: "black",
                      }}
                    >
                      *Add upto 10 Kirtans
                    </Box>
                  </Button>
                ) : null} */}
              </div>
            )}
          </div>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    createResponse,
    updateResponse,

    singerSuccessResponse,
    albumSuccessResponse,
    dialogMessage,
  } = state.kirtanReducer;

  const { SingerData } = state.singerReducer;
  const { AlbumData } = state.albumReducer;

  return {
    createResponse,
    updateResponse,
    SingerData: SingerData?.content,
    AlbumData: AlbumData?.content,
    singerSuccessResponse,
    albumSuccessResponse,
    dialogMessage,
  };
};

export default connect(mapStateToProps, {
  fetchLanguage,
  createKirtanWithSinger,
  updateKirtanWithSinger,
  createKirtanWithAlbum,
  updateKirtanWithAlbum,
  getKirtanWithSinger,
  getKirtanWithAlbum,
  showUnshowDialogMessage,
  getAllAlbum,
  getAllSinger,
})(withMixpanelTracking(UploadGospel, "Add Gospel"));

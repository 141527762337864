import {
  GET_LEADERBOARD,
  GET_LEADERBOARD_FAILURE,
  GET_LEADERBOARD_SUCCESS,
  GET_TODAY_TASK,
  GET_TODAY_TASK_SUCCESS,
  GET_UPLOAD_STATUS,
  GET_UPLOAD_STATUS_FAILURE,
  GET_UPLOAD_STATUS_SUCCESS,
  GET_USER_STATS,
  GET_USER_STATS_FAILURE,
  GET_USER_STATS_SUCCESS,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  data: [],
  tasks: null,
  leaderboards: [],
  userStats: null,
  uploadStatus: false,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPLOAD_STATUS:
      return {
        ...state,
        loading: true,
      };
    case GET_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        uploadStatus: action.payload,
        error: "",
        loading: false,
      };
    case GET_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        uploadStatus: false,
        loading: false,
      };
    case GET_TODAY_TASK:
      return {
        ...state,
        loading: true,
      };
    case GET_TODAY_TASK_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
        error: "",
        loading: false,
      };
    case GET_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_LEADERBOARD:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboards: action.payload,
        error: "",
        loading: false,
      };
    case GET_LEADERBOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_USER_STATS:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_STATS_SUCCESS:
      return {
        ...state,
        userStats: action.payload,
        error: "",
        loading: false,
      };
    case GET_USER_STATS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const GET_UPLOAD_STATUS = "GET_UPLOAD_STATUS";
export const GET_UPLOAD_STATUS_SUCCESS = "GET_UPLOAD_STATUS_SUCCESS";
export const GET_UPLOAD_STATUS_FAILURE = "GET_UPLOAD_STATUS_FAILURE";

export const GET_TODAY_TASK = "GET_TODAY_TASK";
export const GET_TODAY_TASK_SUCCESS = "GET_TODAY_TASK_SUCCESS";
export const GET_TODAY_TASK_FAILURE = "GET_TODAY_TASK_FAILURE";

export const GET_LEADERBOARD = "GET_LEADERBOARD";
export const GET_LEADERBOARD_SUCCESS = "GET_LEADERBOARD_SUCCESS";
export const GET_LEADERBOARD_FAILURE = "GET_LEADERBOARD_FAILURE";

export const GET_USER_STATS = "GET_USER_STATS";
export const GET_USER_STATS_SUCCESS = "GET_USER_STATS_SUCCESS";
export const GET_USER_STATS_FAILURE = "GET_USER_STATS_FAILURE";

export const getUploadStatus = () => {
  return {
    type: GET_UPLOAD_STATUS,
  };
};
export const getUploadStatusSuccess = (data) => {
  return {
    type: GET_UPLOAD_STATUS_SUCCESS,
    payload: data,
  };
};
export const getUploadStatusFailure = (err) => {
  return {
    type: GET_UPLOAD_STATUS_FAILURE,
    payload: err,
  };
};

export const getTodayTask = () => {
  return {
    type: GET_TODAY_TASK,
  };
};
export const getTodayTaskSuccess = (data) => {
  return {
    type: GET_TODAY_TASK_SUCCESS,
    payload: data,
  };
};
export const getTodayTaskFailure = (err) => {
  return {
    type: GET_TODAY_TASK_FAILURE,
    payload: err,
  };
};

export const getLeaderboards = () => {
  return {
    type: GET_LEADERBOARD,
  };
};
export const getLeaderboardsSuccess = (data) => {
  return {
    type: GET_LEADERBOARD_SUCCESS,
    payload: data,
  };
};
export const getLeaderboardsFailure = (err) => {
  return {
    type: GET_LEADERBOARD_FAILURE,
    payload: err,
  };
};

export const getUserStats = () => {
  return {
    type: GET_USER_STATS,
  };
};
export const getUserStatsSuccess = (data) => {
  return {
    type: GET_USER_STATS_SUCCESS,
    payload: data,
  };
};
export const getUserStatsFailure = (err) => {
  return {
    type: GET_USER_STATS_FAILURE,
    payload: err,
  };
};

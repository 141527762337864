export const CREATE_QUIZ = "CREATE_QUIZ";
export const CREATE_QUIZ_SUCCESS = "CREATE_QUIZ_SUCCESS";
export const CREATE_QUIZ_FAILURE = "CREATE_QUIZ_FAILURE";

export const GET_QUIZ = "GET_QUIZ";
export const GET_QUIZ_SUCCESS = "GET_QUIZ_SUCCESS";
export const GET_QUIZ_FAILURE = "GET_QUIZ_FAILURE";

export const UPDATE_QUIZ = "UPDATE_QUIZ";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAILURE = "UPDATE_QUIZ_FAILURE";

export const DOWNLOAD_QUIZ = "DOWNLOAD_QUIZ";
export const DOWNLOAD_QUIZ_SUCCESS = "DOWNLOAD_QUIZ_SUCCESS";
export const DOWNLOAD_QUIZ_FAILURE = "DOWNLOAD_QUIZ_FAILURE";

export const TOGGLE_QUIZ = "TOGGLE_QUIZ";

export const downloadQuizByID = (id) => {
  return {
    type: DOWNLOAD_QUIZ,
    id,
  };
};

export const downloadQuizByIDSuccess = () => {
  return {
    type: DOWNLOAD_QUIZ_SUCCESS,
  };
};
export const downloadQuizByIDFail = (err) => {
  return {
    type: DOWNLOAD_QUIZ_FAILURE,
    payload: err,
  };
};

export const UpdateQuizByID = (payload, id) => {
  return {
    type: UPDATE_QUIZ,
    payload,
    id,
  };
};

export const toggleQuiz = () => {
  return {
    type: TOGGLE_QUIZ,
  };
};
export const updateQuizByIDSuccess = () => {
  return {
    type: UPDATE_QUIZ_SUCCESS,
  };
};
export const updateQuizByIDFail = (err) => {
  return {
    type: UPDATE_QUIZ_FAILURE,
    payload: err,
  };
};

export const createQuiz = (data) => {
  return {
    type: CREATE_QUIZ,
    payload: data,
  };
};
export const createQuizPostSuccess = (data) => {
  return {
    type: CREATE_QUIZ_SUCCESS,
    payload: data,
  };
};
export const createQuizPostFailure = (error) => {
  return {
    type: CREATE_QUIZ_FAILURE,
    payload: error,
  };
};

export const getQuiz = (urlParameters) => {
  return {
    type: GET_QUIZ,
    params: urlParameters
  };
};
export const getQuizSuccess = (data) => {
  return {
    type: GET_QUIZ_SUCCESS,
    payload: data,
  };
};
export const getQuizFailure = (err) => {
  return {
    type: GET_QUIZ_FAILURE,
    payload: err,
  };
};

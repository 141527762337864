import {
  CREATE_PROFILE,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  DIALOG_MESSAGE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
} from "./action";

const initialState = {
  dialogMessage: false,
  loading: false,
  error: "",
  data: [],
  profileData: [],
  // deleteSuccess: false,
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROFILE:
      return {
        ...state,
        data: [],
        error: "",
        dialogMessage: false,
        loading: true,
      };

    case PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: "",
        dialogMessage: true,
        loading: false,
      };

    case PROFILE_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        data: null,
        error: "",
        dialogMessage: false,
        loading: true,
      };

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: "",
        dialogMessage: true,
        loading: false,
      };

    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        data: null,
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };

    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };

    default:
      return state;
  }
};

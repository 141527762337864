import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_HELP_CENTER_STATUS,
  GET_DELETE_REQUEST_STATUS,
  GET_VOLUNTEER_STATUS,
  getHelpCenterPaneSuccess,
  getHelpCenterPaneFail,
  getDeleteRequestPaneSuccess,
  getDeleteRequestPaneFail,
  getVolunteerStatustPaneSuccess,
  getVolunteerStatustPaneFail,
} from "./action";

import { getRequest } from "../../apiServices/ApiActions";

import {
  getVolunteerStatusUrls,
  getdeleteRequestStatusUrls,
  getHelpCenterStatusUrls,
} from "../../apiServices/ApiUrl";

const getdeleteR = async (page) => {
  return getRequest(getdeleteRequestStatusUrls);
};
const getHelpcenterR = async (page) => {
  return getRequest(getHelpCenterStatusUrls);
};
const getVoluntterR = async (page) => {
  return getRequest(getVolunteerStatusUrls);
};

function* getHelpCenter(action) {
  try {
    const Result = yield call(getHelpcenterR, action.params);

    if (Result.status === 200) {
      let response = Result.data;
      yield put(getHelpCenterPaneSuccess(response));
    } else {
      yield put(getHelpCenterPaneFail(Result));
    }
  } catch (error) {
    yield put(getHelpCenterPaneFail(error));
  }
}
function* getVolunteerStatus(action) {
  try {
    const Result = yield call(getVoluntterR, action.params);

    if (Result.status === 200) {
      let response = Result.data;
      yield put(getVolunteerStatustPaneSuccess(response));
    } else {
      yield put(getVolunteerStatustPaneFail(Result));
    }
  } catch (error) {
    yield put(getVolunteerStatustPaneFail(error));
  }
}
function* getDeleteRequest(action) {
  try {
    const Result = yield call(getdeleteR, action.params);

    if (Result.status === 200) {
      let response = Result.data;
      yield put(getDeleteRequestPaneSuccess(response));
    } else {
      yield put(getDeleteRequestPaneFail(Result));
    }
  } catch (error) {
    yield put(getDeleteRequestPaneFail(error));
  }
}

function* PaneSaga() {
  yield takeEvery(GET_HELP_CENTER_STATUS, getHelpCenter);
  yield takeEvery(GET_DELETE_REQUEST_STATUS, getDeleteRequest);
  yield takeEvery(GET_VOLUNTEER_STATUS, getVolunteerStatus);
}
export default PaneSaga;

import { all, fork } from "redux-saga/effects";
import communitySaga from "./community/community-saga";
import eventsSaga from "./Events/events-saga";
import granthSaga from "./granth/granth-saga";
import hukumnamaSaga from "./hukumnama/hukumnama-saga";
import loginSaga from "./login/login-saga";
import nitnemSaga from "./nitnem/nitnem-saga";
import languageSaga from "./language/language-saga";
import volunteerSaga from "./volunteer/volunteer-saga";
import helpsSaga from "./help-centre/helps-saga";
import userSaga from "./usermanagement/usermanagement-saga";
import kirtanSaga from "./kirtan/kirtan-saga";
import gurudwaraSaga from "./gurudwara/gurudwara-saga";
import profileSaga from "./userprofile/userprofile-saga";
import quizSaga from "./quiz/quiz-saga";
import dashboardSaga from "./dashboard/dashboard-saga";
import deleteRequestSaga from "./delete-request/delete-request-saga";
import SingerSaga from "./singer/singer-saga";
import albumSaga from "./album/album-saga";
import PaneSaga from "./navigationaPane/pane-saga";

export default function* rootSaga() {
  yield all([
    fork(communitySaga),
    fork(eventsSaga),
    fork(granthSaga),
    fork(hukumnamaSaga),
    fork(loginSaga),
    fork(nitnemSaga),
    fork(languageSaga),
    fork(volunteerSaga),
    fork(helpsSaga),
    fork(userSaga),
    fork(kirtanSaga),
    fork(gurudwaraSaga),
    fork(profileSaga),
    fork(quizSaga),
    fork(dashboardSaga),
    fork(deleteRequestSaga),
    fork(SingerSaga),
    fork(albumSaga),
    fork(PaneSaga),
  ]);
}

import axios from "axios";

const userAccessToken = localStorage.getItem("token");

const languageCode = "en_US";

const config = {
  headers: { Authorization: `Bearer ${userAccessToken}` },
  params: { languageCode },
};

export const getEvents = async () =>
  await axios.get(`${process.env.REACT_APP_BACKEND_URL}/event`, config);

export const saveEvents = async (addEventData) =>
  await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/event/save`,
    addEventData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    },
  );

export const updateEvents = async (addEventData, id) =>
  await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/event/update/` + `${id}`,
    addEventData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    },
  );
export const postEvents = async (formData) =>
  await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/doc/file/uploads`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );

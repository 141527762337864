import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_USER,
  userSuccess,
  userFailure,
  getSubAdminUsersSuccess,
  getSubAdminUsersFaliure,
  GET_SUBADMIN_USERS,
  UPDATE_USER,
  updateUserSuccess,
  updateUserFailure,
  DELETE_USER,
  deleteUserFails,
  deleteUserSuccess,
  UPDATE_USER_STATUS,
  updateUserStatus,
  updateUserStatusSuccess,
  updateUserStatusFailure,
  GET_USER_PROFILE,
  getUserProfileSuccess,
  getUserProfileFaliure,
  GET_USER_COUNT,
  getUserCountSuccess,
  getUserCountFaliure,
} from "./action";

import {
  postRequest,
  putRequest,
  deleteRequest,
} from "../../apiServices/ApiActions";

import {
  addUserUrl,
  updateUserUrl,
  deleteUserUrl,
} from "../../apiServices/ApiUrl";
import {
  getSubAdminsFromApi,
  getUserProfileFromApi,
  getUserStatsFromApi,
  updateUserStatusFromApi,
} from "../../apiServices/app-management";
import { showSnackbar } from "../snackbar/action";

const addUser = async (payload) => {
  return await postRequest(addUserUrl, payload);
};

const getSubAdminsApi = async () => {
  return getSubAdminsFromApi();
};

const getUserStatsApi = async () => {
  return getUserStatsFromApi();
};

const getUserProfileApi = async () => {
  return getUserProfileFromApi();
};

const updateUserbyId = (payload) => {
  return putRequest(updateUserUrl, payload);
};

const deleteUserData = async (payload) => {
  return deleteRequest(deleteUserUrl + `${payload}`);
};

const updateUserStatusApi = async (id, status) => {
  return updateUserStatusFromApi(id, status);
};

function* createUser(action) {
  try {
    const result = yield call(addUser, action.payload);
    if (result.status === 200) {
      yield put(userSuccess(result));
      yield put(
        showSnackbar({
          message: "User create successfully",
          severity: "success",
        }),
      );
    } else {
      let res = result?.response?.data;
      yield put(userFailure(result));

      if (result?.response?.data?.status === 500) {
        yield put(
          showSnackbar({
            message: "Failed to create user: " + result?.response?.data?.error,
            severity: "error",
          }),
        );
      } else {
        yield put(
          showSnackbar({
            message:
              "Failed to create user: " + result?.response?.data?.message,
            severity: "error",
          }),
        );
      }
    }
  } catch (error) {
    yield put(userFailure(error));
  }
}

function* getSubAdminsResponse() {
  try {
    const result = yield call(getSubAdminsApi);
    if (result.status === 200) {
      yield put(getSubAdminUsersSuccess(result.data));
    } else {
      yield put(getSubAdminUsersFaliure(result));
    }
  } catch (error) {
    yield put(getSubAdminUsersFaliure(error));
  }
}

function* getUserProfileResponse() {
  try {
    const result = yield call(getUserProfileApi);
    if (result.status === 200) {
      yield put(getUserProfileSuccess(result.data));
    } else {
      yield put(getUserProfileFaliure(result));
    }
  } catch (error) {
    yield put(getUserProfileFaliure(error));
  }
}

function* getUserStatsResponse() {
  try {
    const result = yield call(getUserStatsApi);

    if (result.status === 200) {
      yield put(getUserCountSuccess(result.data));
    } else {
      yield put(getUserCountFaliure(result));
    }
  } catch (error) {
    yield put(getUserCountFaliure(error));
  }
}

function* updateUser(action) {
  try {
    const result = yield call(updateUserbyId, action.payload);

    if (result.status === 200) {
      yield put(updateUserSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(updateUserFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(updateUserFailure(error));
  }
}

function* deleteUser(action) {
  try {
    const result = yield call(deleteUserData, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteUserSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteUserFails(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(deleteUserFails(error));
  }
}

function* updateUserStatusResponse(action) {
  try {
    const result = yield call(updateUserStatusApi, action.id, action.status);
    if (result.status === 200) {
      yield put(updateUserStatusSuccess(result));
    } else {
      yield put(updateUserStatusFailure(result));
    }
  } catch (error) {
    yield put(updateUserStatusFailure(error));
  }
}

function* userSaga() {
  yield takeEvery(CREATE_USER, createUser);
  yield takeEvery(GET_SUBADMIN_USERS, getSubAdminsResponse);
  yield takeEvery(GET_USER_COUNT, getUserStatsResponse);
  yield takeEvery(GET_USER_PROFILE, getUserProfileResponse);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatusResponse);
}

export default userSaga;

import {
  CREATE_ALBUM,
  CREATE_ALBUM_FAILURE,
  CREATE_ALBUM_SUCCESS,
  DELETE_ALBUM,
  DELETE_ALBUM_FAILURE,
  DELETE_ALBUM_SUCCESS,
  GET_ALBUM,
  GET_ALBUM_FAILURE,
  GET_ALBUM_SUCCESS,
  UPDATE_ALBUM,
  UPDATE_ALBUM_FAILURE,
  UPDATE_ALBUM_SUCCESS,
  DIALOG_MESSAGE,
  DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
  loading: false,
  error: "",
  AlbumData: [],
  createRespone: false,
  updateResponse: false,
  deleteResponse: false,
  dialogMessage: false,
};

export const albumReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ALBUM:
      return {
        ...state,
        loading: true,
        dialogMessage: false,
      };
    case CREATE_ALBUM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        createResponse: true,
        dialogMessage: true,
      };
    case CREATE_ALBUM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        dialogMessage: false,
      };
    case GET_ALBUM:
      return {
        ...state,
        AlbumData: [],
        loading: true,
      };
    case GET_ALBUM_SUCCESS:
      return {
        ...state,
        AlbumData: action.payload,
        error: "",
        loading: false,
      };
    case GET_ALBUM_FAILURE:
      return {
        ...state,
        error: action.payload,
        AlbumData: [],
        loading: false,
      };
    case UPDATE_ALBUM:
      return {
        ...state,
        loading: true,
        updateResponse: false,
        dialogMessage: false,
      };
    case UPDATE_ALBUM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        updateResponse: true,
        dialogMessage: true,
      };
    case UPDATE_ALBUM_FAILURE:
      return {
        ...state,
        error: action.payload,
        updateResponse: false,
        loading: false,
        dialogMessage: false,
      };
    case DELETE_ALBUM:
      return {
        ...state,
        loading: true,
        deleteResponse: false
      };
    case DELETE_ALBUM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        deleteResponse: true,
      };
    case DELETE_ALBUM_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        deleteResponse: false
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };

    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,
        deleteResponse: false,
      };

    default:
      return state;
  }
};

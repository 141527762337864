import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { formatDateSeperatedWithHyphen } from "../../helpers/dateHelper";
export const handleExport = (data) => {
  // Convert the JSON data to a format suitable for Excel

  const transformedData = data.map((item) => ({
    ...item,
    helpNames: item.helpNames.join(", "),
    createdAt: formatDateSeperatedWithHyphen(item.createdAt),
    updatedAt: formatDateSeperatedWithHyphen(item.updatedAt),
    // Join array elements into a single string
  }));
  const worksheet = XLSX.utils.json_to_sheet(transformedData, {
    header: [
      "id",
      "userId",
      "userFullName",
      "email",
      "countryCode",
      "phoneNumber",
      "helpNames",
      "status",
      "comment",
      "otherData",
      "additionalInfo",
      "createdAt",
      "updatedAt",
    ],
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a binary string
  const workbookOut = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Save the file using file-saver
  const blob = new Blob([workbookOut], { type: "application/octet-stream" });
  saveAs(blob, "data.xlsx");
};

export const FETCH_LANGUAGE = "FETCH_LANGUAGE";
export const FETCH_LANGUAGE_SUCCESS = "FETCH_LANGUAGE_SUCCESS";
export const FETCH_LANGAUGE_FAILURE = "FETCH_LANGUAGE_FAILURE";

export const fetchLanguage = () => {
    return {
        type: FETCH_LANGUAGE
    }
}

export const fetchLanguageSuccess = (data) => {
    return {
        type: FETCH_LANGUAGE_SUCCESS,
        payload: data
    }
}

export const fetchLanguageFailure = (error) => {
    return {
        type: FETCH_LANGAUGE_FAILURE,
        payload: error
    }
}

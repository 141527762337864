import axios from "axios";

// fetching logged in user access token
const userAccessToken = localStorage.getItem("token");

const languageCode = "en_US";

const config = {
  headers: { Authorization: `Bearer ${userAccessToken}` },
  params: { languageCode },
};

export const savePosts = async (communityPostData) =>
  await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/wealth/save`,
    communityPostData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    }
  );

export const postWealths = async (formData) =>
  await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/doc/file/uploads`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const getPosts = async () =>
  await axios.get(`${process.env.REACT_APP_BACKEND_URL}/wealth/admin/all`, config);

export const updatePosts = async (formData) =>
  await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/wealth/update`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    }
  );
import { call, put, takeEvery } from "redux-saga/effects";

import {  
    CREATE_HUKUMNAMA, 
    createHukumnamaSuccess,
    createHukumnamaFailure,
    FETCH_HUKUMNAMA,
    fetchHukumnamaSuccess,
    fetchHukumnamaFailure,
    FETCH_ALL_HUKUMNAMA,
    fetchAllHukumnamaSuccess,
    fetchAllHukumnamaFailure,
    UPDATE_HUKUMNAMA,
    updateHukumnamaSuccess,
    updateHukumnamaFailure,
    DELETE_HUKUMNAMA,
    deleteHukumnamaSuccess,
    deleteHukumnamaFailure
} from "./action";

import { getRequest, postRequest, putRequest, deleteRequest } from "../../apiServices/ApiActions";
import { 
  getAllHukumnamaURL, 
  getAllHukumnamaWithPaginationUrl, 
  addHukumnamaUrl, 
  updateHukumnamaUrl, 
  deleteHukumnamaUrl
} from "../../apiServices/ApiUrl";
import { formatDateSeperatedWithHyphen, todayDate } from "../../helpers/dateHelper";

const addHukumnama = async (payload) => {
  return postRequest(addHukumnamaUrl, payload);
};

const getHukumnama = async(languageCode) => {
  const hyphenFormattedDate = formatDateSeperatedWithHyphen(todayDate());
  return getRequest(`${getAllHukumnamaURL}${hyphenFormattedDate}&languageCode=${languageCode}`);
}

const getAllHukumnamas = async(page) => {
  return await getRequest(`${getAllHukumnamaWithPaginationUrl}?page=${--page}&size=${10}`);
}

const editHukumnama = async(payload, id) => {
  return await putRequest(`${updateHukumnamaUrl}${id}`, payload);
}

const removeHukumnama =  async(id) => {
  return await deleteRequest(`${deleteHukumnamaUrl}/${id}`);
}

function* createHukumnama(action) {
  try {
    const result = yield call(addHukumnama, action.payload);

    if (result.status === 200) {
      yield put(createHukumnamaSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(createHukumnamaFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(createHukumnamaFailure(error));
  }
}

function* getHukumnamas(action) {
    try {
       const result = yield call(getHukumnama, action.params); 

       if(result.status === 200) {
        yield put(fetchHukumnamaSuccess(result));
       } else {
        yield put(fetchHukumnamaFailure(result));
       }
    } catch (error) {
        yield put(fetchHukumnamaFailure(error));
    }
}

function* fetchAllHukumnamaWithPagination(action) {
  try {
    const result = yield call(getAllHukumnamas, action.params);

    if(result.status === 200) {
      yield put(fetchAllHukumnamaSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(fetchAllHukumnamaFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(fetchAllHukumnamaFailure(error));
  }
}

function* updateHukumnama(action) {
  try {
    const result = yield call(editHukumnama, action.payload, action.params);

    if (result.status === 200) {
      yield put(createHukumnamaSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(createHukumnamaFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(updateHukumnamaFailure(error));
  }
}

function* deleteHukumnama(action) {
  try {
    const result = yield call(removeHukumnama, action.params);

    if(result.status === 204) {
      yield put(deleteHukumnamaSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(deleteHukumnamaFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(deleteHukumnamaFailure(error));
  }
}

function* hukumnamaSaga() {
  yield takeEvery(CREATE_HUKUMNAMA, createHukumnama);
  yield takeEvery(FETCH_HUKUMNAMA, getHukumnamas);
  yield takeEvery(FETCH_ALL_HUKUMNAMA, fetchAllHukumnamaWithPagination);
  yield takeEvery(UPDATE_HUKUMNAMA, updateHukumnama);
  yield takeEvery(DELETE_HUKUMNAMA, deleteHukumnama);
}

export default hukumnamaSaga;

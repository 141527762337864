import {
  CREATE_HUKUMNAMA,
  CREATE_HUKUMNAMA_SUCCESS,
  CREATE_HUKUMNAMA_FAILURE,
  FETCH_HUKUMNAMA,
  FETCH_HUKUMNAMA_SUCCESS,
  FETCH_HUKUMNAMA_FAILURE,
  FETCH_ALL_HUKUMNAMA,
  FETCH_ALL_HUKUMNAMA_SUCCESS,
  FETCH_ALL_HUKUMNAMA_FAILURE,
  UPDATE_HUKUMNAMA,
  UPDATE_HUKUMNAMA_SUCCESS,
  UPDATE_HUKUMNAMA_FAILURE,
  DELETE_HUKUMNAMA,
  DELETE_HUKUMNAMA_SUCCESS,
  DELETE_HUKUMNAMA_FAILURE,
  DIALOG_MESSAGE,
  DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
  loading: false,
  error: "",
  todayHukumnamaData: [],
  hukumnamaData: [],
  getHukumnamaSuccess: false,
  deleteSuccess: false,
  dialogMessage: false
};

export const hukumnamaReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_HUKUMNAMA:
      return {
        ...state,
        error: "",
        dialogMessage: false,
        loading: true,
      };

    case CREATE_HUKUMNAMA_SUCCESS:
      return {
        ...state,
        error: "",
        dialogMessage: true,
        loading: false,
      };

    case CREATE_HUKUMNAMA_FAILURE:
      return {
        ...state,
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };

    case FETCH_HUKUMNAMA:
      return {
        ...state,
        todayHukumnamaData: [],
        error: "",
        getHukumnamaSuccess: false,
      };

    case FETCH_HUKUMNAMA_SUCCESS:
      return {
        ...state,
        todayHukumnamaData: action.payload,
        error: "",
        getHukumnamaSuccess: true,
      };

    case FETCH_HUKUMNAMA_FAILURE:
      return {
        ...state,
        todayHukumnamaData: [],
        error: action.payload,
        getHukumnamaSuccess: false,
      };

    case FETCH_ALL_HUKUMNAMA:
      return {
        ...state,
        hukumnamaData: [],
        error: "",
        loading: true
      }

    case FETCH_ALL_HUKUMNAMA_SUCCESS:
      return {
        ...state,
        hukumnamaData: action.payload,
        error: "",
        loading: false
      }

    case FETCH_ALL_HUKUMNAMA_FAILURE:
      return {
        ...state,
        hukumnamaData: [],
        error: action.payload,
        loading: false
      }

    case UPDATE_HUKUMNAMA: 
      return {
        ...state,
        error: "",
        dialogMessage: false,
        loading: true
      }

    case UPDATE_HUKUMNAMA_SUCCESS:
      return {
        ...state,
        error: "",
        dialogMessage: true,
        loading: false
      }

    case UPDATE_HUKUMNAMA_FAILURE:
      return {
        ...state,
        error: action.payload,
        dialogMessage: false,
        loading: false
      }

    case DELETE_HUKUMNAMA:
      return {
        ...state,
        error: "",
        deleteSuccess: false,
        loading: true
      }

    case DELETE_HUKUMNAMA_SUCCESS:
      return {
        ...state,
        error: "",
        deleteSuccess: true,
        loading: false
      }

    case DELETE_HUKUMNAMA_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
        loading: false
      }

    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false
      }

    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,
        deleteSuccess: false,
      };

    default:
      return state;
  }
};

import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { connect } from "react-redux";
import {
  getVolunteerList,
  getVolunteerByStatus,
  updateVolunteerByStatus,
} from "../../redux/volunteer/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ApprovedVol = (props) => {
  const [tableList, setTableList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [size] = useState(5);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("id");
  const decodedData = decodeURIComponent(encodedData);

  const EventsId = JSON.parse(decodedData);

  const handleChange = (_, value) => {
    setPage(value);
  };

  const handleStatus = (volId, status) => {
    props.updateVolunteerByStatus(volId, status);
    props.trackButtonClick("Update Volunteer Status Button", 1);
    if (tableList.length % size === 1 && count >= 0) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", 0);
    formData.append("size", 50);
    formData.append("status", "APPROVED");
    const queryParams = new URLSearchParams(formData).toString();

    props.getVolunteerList(`${EventsId}?${queryParams}`);
  }, [props.getVolunteerList, props.updateByStatusSuccess]);

  useEffect(() => {
    setTableList(props.searchedList);
  }, [props.searchedList]);

  useEffect(() => {
    if (
      (props.getListSuccess || props.updateByStatusSuccess) &&
      props.volunteerList.length > 0
    ) {
      if (props.volunteerList) {
        setTableList([
          ...props.volunteerList?.slice((page - 1) * size, page * size),
        ]);
        setCount(Math.ceil(props.volunteerList?.length / size));
        props.searchList(props.volunteerList);
      }
    } else {
      props.searchList([]);
      setTableList([]);
    }
  }, [props.getListSuccess, props.updateByStatusSuccess, page]);

  return (
    <>
      <Grid container xs={12} flexWrap="wrap">
        <TableContainer component={Paper} elevation={0}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="view_Volunteer_table"
          >
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow className="view_volunteer_table_row">
                <TableCell className="view_volunteer_cell">SL No.</TableCell>
                <TableCell className="view_volunteer_cell">Name</TableCell>
                <TableCell className="view_volunteer_cell">Email Id</TableCell>
                <TableCell className="view_volunteer_cell">
                  Phone Number
                </TableCell>
                <TableCell className="view_volunteer_cell">Sewa Type</TableCell>
                <TableCell className="view_volunteer_cell">Status</TableCell>
                <TableCell align="right" className="view_volunteer_cell">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableList?.length > 0 &&
                tableList.map((row, index) => (
                  <TableRow
                    key={row.title}
                    className="view_volunteer_table_row"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="view_volunteer_cell"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.name}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.email}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.phone}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.sevaResponse}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      <p style={{ color: "#4169E1", padding: 0, margin: 0, fontWeight:'550' }}>
                        {row.status}
                      </p>
                    </TableCell>
                    <TableCell align="right" className="view_volunteer_cell">
                      <button className='action-Button'
                        style={{color: "#C7332A", border: "1px solid #C7332A"}}
                        onClick={() => handleStatus(row.id, "REJECTED")}
                      >
                        Reject
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {tableList.length == 0 && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
        {count && tableList.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size="large"
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    volunteerListByStatus,
    volunteerList,
    getListSuccess,
    getVolunteerSuccess,
    updateByStatusSuccess,
  } = state.volunteerReducer;
  return {
    volunteerListByStatus,
    volunteerList,
    getListSuccess,
    getVolunteerSuccess,
    updateByStatusSuccess,
  };
};

export default connect(mapStateToProps, {
  getVolunteerList,
  getVolunteerByStatus,
  updateVolunteerByStatus,
})(withMixpanelTracking(ApprovedVol, "Approved Volunteer"));

import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Paginate from "../commonComponent/Pagination";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import {
  formatDateInAlphanumericFormat,
  formatDateSeperatedWithHyphen,
} from "../../helpers/dateHelper";
import {
  deleteEventsByID,
  getEvents,
  toggleModal,
} from "../../redux/Events/action";
import "../../styles/event.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";

const LanguageList = [
  { langName: "HINDI", LangCode: "hi_IN" },
  { langName: "ENGLISH", LangCode: "en_US" },
  { langName: "PUNJABI", LangCode: "pa_IN" },
];

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth:'thin',
    [theme.breakpoints.down(1535)]: {
      maxHeight: 245,
    },
    [theme.breakpoints.between(1536, 1919)]: {
      maxHeight: 320,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 510,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1535)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1536, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewEvent = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // Sorting state
  const [sortedData, setSortedData] = useState([]); // Sorted data
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteEventsByID(value.id);
    props.trackButtonClick("Delete Event Button", 1, value?.id);
  };

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getEvents(newPage);
  };

  const setEventDataWithPagination = (loading) => {
    if (!loading && props?.eventsData) {
      const { content, page } = props?.eventsData;

      props.setEventList(content);
      props.setRecordsList(content);
      setSortedData(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  const sortByTitle = () => {
    if (sortedData?.length > 0) {
      const isAsc = sortOrder === "asc";
      const sorted = [...sortedData].sort((a, b) =>
        isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      );
      setSortOrder(isAsc ? "desc" : "asc");
      setSortedData(sorted);
    }
  };

  const width = 300;
  const style = {
    maxWidth: width,
    borderStyle: "border-box",
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);

    navigate(`/content/event/edit?data=${encodedData}`);
  };
  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);

  const closeSuccessDeleteModal = () => {
    props.toggleModal();
  };

  useEffect(() => {
    if (props.deleteSuccess) {
      setOpenSucceessDelete(true);
    }
  }, [props.deleteSuccess]);

  useEffect(() => {
    setSortedData(props?.eventList);

    if (props?.eventList?.length !== props?.recordsList?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setEventDataWithPagination(false);
    }
  }, [props?.eventList]);

  useEffect(() => {
    props.getEvents(paginationObject?.page);
    setRowData([]);
  }, [props.deleteSuccess]);

  useEffect(() => {
    setEventDataWithPagination(props?.loading);
  }, [props.loading]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            elevation={0}
            className={classes?.table}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={style}
                    onClick={sortByTitle}
                    style={{ cursor: "pointer" }}
                  >
                    Title
                    <TableSortLabel
                      active
                      direction={sortOrder}
                      onClick={sortByTitle}
                    />
                  </TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Event Date</TableCell>
                  <TableCell>Location Name</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell align="right" sx={{ pr: 2.5 }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData?.length > 0 &&
                  sortedData.map((eventrow) => (
                    <TableRow
                      key={eventrow.id}
                      sx={{
                        border: "2px solid #F7F8F9",
                      }}
                    >
                      <TableCell component="th" scope="row" sx={style}>
                        {eventrow.name}
                      </TableCell>
                      <TableCell>
                        {
                          LanguageList.find(
                            (item) => item?.LangCode === eventrow?.languageCode,
                          )?.langName
                        }
                      </TableCell>
                      <TableCell>
                        {formatDateSeperatedWithHyphen(eventrow?.dateTime)}
                      </TableCell>
                      <TableCell>{eventrow.locationName}</TableCell>
                      <TableCell>Admin</TableCell>
                      <TableCell>
                        {formatDateInAlphanumericFormat(eventrow?.updatedAt)}
                      </TableCell>
                      <TableCell align="right" sx={{ p: 0 }}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            props.setContentDataToBeUpdated(eventrow);
                            handleRowEdit(eventrow);
                            props.trackButtonClick("Edit Events Button", 1);
                          }}
                        >
                          <img
                            className={classes?.icon_size}
                            src="/edit-icon.svg"
                            alt="edit-icon"
                          />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteData(eventrow)}
                        >
                          <img
                            className={classes?.icon_size}
                            src="/delete-icon.svg"
                            alt="delete-icon"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {sortedData === undefined ||
            (sortedData?.length === 0 && (
              <Box
                sx={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "30px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <img
                    src="/mailbox.png"
                    alt="No data"
                    style={{ width: "30px", height: "30px" }}
                  />
                </Box>
                <Box sx={{}}>No data</Box>
              </Box>
          ))}

          {sortedData?.length > 0 ? (
            <Grid container sx={{ marginTop: "45px" }}>
              <Grid xs={5}></Grid>
              <Grid xs={4}>
                <Paginate
                  paginationObject={paginationObject}
                  handlePageChange={handlePageChange}
                />
              </Grid>
              <Grid xs={3}></Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        openSuccessDelete={openSuccessDelete}
        setDeleteToggle={setDeleteToggle}
        recordType="Event"
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { successRsponse, eventsData, deleteSuccess, loading } =
    state.eventReducer;
  return {
    successRsponse,
    eventsData,
    deleteSuccess,
    loading,
  };
};

export default connect(mapStateToProps, {
  getEvents,
  deleteEventsByID,
  toggleModal,
})(withMixpanelTracking(ViewEvent, "Event"));

import { FileUploadOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../helpers/InputValidation";
import { getAllGURUDWARA } from "../../redux/gurudwara/action";
import {
  createUser,
  showUnshowDialogMessage,
  updateUser,
} from "../../redux/usermanagement/action";
import "../../styles/user-management.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const AddUser = (props) => {
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    password: "",
    dateOfBirth: new Date(),
    countryCode: "+91",
    phoneNumber: "",
    userType: "SUBADMIN",
    locationIds: [],
  });

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);

  const editRecords = JSON.parse(decodedData);
  const navigate = useNavigate();
  const { action } = useParams();

  const [errors, setErrors] = useState({
    fullName: false,
    email: false,
    phoneNumber: false,
    password: false,
    dateOfBirth: false,
    locationIds: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setUserData({
      ...userData,
      dateOfBirth: dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), // Format the date as required
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validation state
    const newErrors = {
      fullName: !userData.fullName.trim(),
      email: !validateEmail(userData.email),
      phoneNumber: !validatePhoneNumber(userData.phoneNumber),
      password: action === "edit" ? false : !userData.password.trim(),
      dateOfBirth: !userData.dateOfBirth,
      locationIds: !userData.locationIds,
    };

    setErrors(newErrors);

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some(Boolean);

    if (!hasErrors) {
      let newItem = {
        ...userData,
        locationIds: Array.isArray(userData.locationIds)
          ? userData.locationIds
          : [userData.locationIds],
      };

      if (action === "edit") {
        const updateUserPayload = {
          id: editRecords.id,
          fullName: newItem.fullName,
          email: newItem.email,
          countryCode: newItem.countryCode,
          phoneNumber: newItem.phoneNumber,
          locationIds: newItem.locationIds,
        };
        props.updateUser(updateUserPayload);
        props.trackButtonClick("Update User Button", 1);
      } else {
        props.createUser(newItem);
        props.trackButtonClick("Create User Button", 1);
      }
    }
  };

  const shouldDisableDate = (date) => {
    return dayjs(date).isAfter(dayjs(), "day");
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/user-management/view`, { replace: true });
    // if (typeof props.closeEditModal == "function") props.closeEditModal(false);
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setUserData({
        ...userData,
        fullName: "",
        email: "",
        password: "",
        dateOfBirth: new Date(),
        locationIds: "",
        phoneNumber: "",
      });
      navigate(`/user-management/view`, { replace: true });
    }
  };

  useEffect(() => {
    props.getAllGURUDWARA();
  }, []);

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setUserData({
        ...userData,
        fullName: records.fullName,
        email: records.email,
        phoneNumber: records.phoneNumber,
        password: records.password,
        dateOfBirth: records.dateOfBirth,
        locationIds: records?.locationIds?.[0],
      });
    }
  }, [action]);
  console.log("valueLoc", userData?.locationIds);

  const matches = useMediaQuery("(max-width:1440px)");

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header="User Details Added Successfully!"
          content="An Email has been sent to the user with credentials"
        />
      )}

      <Grid
        xs={12}
        className={"user_container"}
        component="form"
        onSubmit={handleFormSubmit}
        sx={{ marginTop: { xl: "10px" } }}
      >
        <div style={{ width: "100%", padding: "20px", height: "80%" }}>
          <Stack
            spacing={{ lg: 1, xl: 4 }}
            direction="column"
            gap={{ lg: 2, xl: 4 }}
            sx={{ mt: { lg: 0, xl: "40px" } }}
          >
            <Stack spacing={2} direction="row" gap={{ lg: 2, xl: 2 }}>
              <FormControl fullWidth variant="outlined" error={errors.fullName}>
                <TextField
                  id="fullName"
                  name="fullName"
                  size={matches ? "small" : ""}
                  label="Name"
                  placeholder="Enter Name"
                  InputLabelProps={{ shrink: true }}
                  className={"user_input_box"}
                  value={userData.fullName}
                  onChange={handleChange}
                  required
                />
                {errors.fullName && (
                  <FormHelperText sx={{ color: "red" }}>
                    Name is required
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl variant="outlined" fullWidth error={errors.email}>
                <TextField
                  id="email"
                  name="email"
                  label="Email ID"
                  placeholder="Enter Email ID"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className={"user_input_box"}
                  value={userData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.email
                      ? "Invalid Email format"
                      : "Email is required"}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl
                variant="outlined"
                fullWidth
                error={errors.phoneNumber}
              >
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Enter Ph. Number"
                  type="tel"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className={"user_input_box"}
                  value={userData.phoneNumber}
                  onChange={handleChange}
                  required
                />
                {errors.phoneNumber && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.phoneNumber
                      ? "Invalid Phone number format"
                      : "Phone number is required"}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                error={errors.dateOfBirth}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={"user_input_box"}
                    disabled={action === "edit" ? true : false}
                    fullWidth
                    label="Date of Birth *"
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        error: userData.dateOfBirth !== " " ? false : true,
                        size: matches ? "small" : "",
                      },
                    }}
                    required
                    value={dayjs(userData.dateOfBirth)}
                    onChange={handleDateChange}
                    shouldDisableDate={shouldDisableDate}
                  />
                </LocalizationProvider>
                {errors.dateOfBirth && (
                  <FormHelperText sx={{ color: "red" }}>
                    Date of Birth is required
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  id="userType"
                  name="userType"
                  label="Role"
                  className={"user_input_box"}
                  variant="outlined"
                  disabled
                  size={matches ? "small" : ""}
                  value={userData.userType}
                >
                  <MenuItem value="SUBADMIN">SUBADMIN</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth error={errors.password}>
                <TextField
                  fullWidth
                  id="password"
                  disabled={action === "edit" ? true : false}
                  name="password"
                  size={matches ? "small" : ""}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className={"user_input_box"}
                  value={userData.password}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
                  <FormHelperText sx={{ color: "red" }}>
                    Password is required
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl
                variant="outlined"
                fullWidth
                error={errors.locationIds}
                size={matches ? "small" : ""}
              >
                <InputLabel id="chooseGurudwara">Choose Gurudwara</InputLabel>
                <Select
                  required
                  className={"user_input_box"}
                  InputLabelProps={{ shrink: true }}
                  labelId="chooseGurudwara"
                  id="locationIds"
                  value={userData?.locationIds}
                  label="Choose Gurudwara"
                  onChange={(event) =>
                    setUserData({
                      ...userData,
                      locationIds: event.target.value,
                    })
                  }
                >
                  {props?.guruDwaraData?.length > 0 &&
                    props?.guruDwaraData?.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
                {/* {errors.locationIds && (
                  <FormHelperText sx={{ color: "red" }}>
                    Gurudwara selection is required
                  </FormHelperText>
                )} */}
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel>Reporting To</InputLabel>
                <Select
                  id="reportingTo"
                  name="reportingTo"
                  label="Reporting To"
                  className={"user_input_box"}
                  variant="outlined"
                  value="adminName"
                  size={matches ? "small" : ""}
                  disabled
                >
                  <MenuItem value="adminName">Harpal Sandhu</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl
                variant="outlined"
                fullWidth
                error={errors.locationIds}
                size={matches ? "small" : ""}
              >
                <InputLabel id="diocese">Choose Diocese</InputLabel>
                <Select
                  // required
                  disabled
                  className={"user_input_box"}
                  InputLabelProps={{ shrink: true }}
                  labelId="diocese"
                  id="diocese"
                  value={userData?.locationIds}
                  label="Choose Diocese"
                  onChange={(event) =>
                    setUserData({
                      ...userData,
                      locationIds: event.target.value,
                    })
                  }
                >
                  {props?.guruDwaraData?.length > 0 &&
                    props?.guruDwaraData?.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth size={matches ? "small" : ""}>
                <InputLabel id="church">Choose Church</InputLabel>
                <Select
                  // required
                  disabled
                  id="church"
                  name="church"
                  labelId="church"
                  label="Choose Church"
                  InputLabelProps={{ shrink: true }}
                  className={"user_input_box"}
                  variant="outlined"
                  value=""
                  size={matches ? "small" : ""}
                >
                  {props?.guruDwaraData?.length > 0 &&
                    props?.guruDwaraData?.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>

            <Grid
              xs={12}
              sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "15px !important",
                textAlign: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                // startIcon={<FileUploadOutlined />}
                sx={{
                  backgroundColor: "var(--secondary-color)",
                  textTransform: "none",
                  fontSize: { lg: "18px", xl: "20px" },
                  paddingX: { xl: "30px" },
                  fontWeight: "400",
                }}
                size={matches ? "small" : "medium"}
              >
                {action === "edit" ? "Update" : "Create"}
              </Button>
            </Grid>
          </Stack>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage, updateUser, createUser } = state.userReducer;
  const { data, getAllGURUDWARA } = state.gurudwaraReducer;

  return {
    successResponse: dialogMessage,
    getAllGURUDWARA,
    guruDwaraData: data?.data,
    updateUser,
    createUser,
  };
};

export default connect(mapStateToProps, {
  getAllGURUDWARA,
  createUser,
  updateUser,
  showUnshowDialogMessage,
})(withMixpanelTracking(AddUser, " Add User"));

import {
    FETCH_LANGUAGE,
    FETCH_LANGUAGE_SUCCESS,
    FETCH_LANGAUGE_FAILURE
} from "./action";

const initialState = {
    error: "",
    languageData: [],
    success: false,
}

export const languageReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_LANGUAGE:
            return {
                ...state,
                error: "",
                languageData: [],
                success: false,
            }

        case FETCH_LANGUAGE_SUCCESS:
            return {
                ...state,
                error: "",
                languageData: action.payload,
                success: true
            }

        case FETCH_LANGAUGE_FAILURE:
            return {
                ...state,
                error: action.payload,
                languageData: [],
                success: false
            }

        default:
            return state;

    }
}
import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_COMMUNITY,
  GET_COMMUNITY,
  createCommunityPostSuccess,
  createCommunityPostFailure,
  DELETE_COMMUNITY,
  deleteCommunityFails,
  deleteCommunitySuccess,
  getCommPostFailure,
  getCommPostSuccess,
  updateCommunitySuccess,
  updateCommunityFailure,
  UPDATE_COMMUNITY,
} from "./action";

import {
  postRequest,
  getRequestWithparams,
  deleteRequest,
  getRequest,
  putRequest,
} from "../../apiServices/ApiActions";

import {
  communityPostURL,
  getCommunityPostUrl,
  deleteCommunityPostUrl,
  updateCommunityPostUrl,
} from "../../apiServices/ApiUrl";

import { showSnackbar } from "../snackbar/action";

const createPost = async (payload) => {
  return postRequest(communityPostURL, payload);
};

const deleteCommunityData = async (payload) => {
  return deleteRequest(deleteCommunityPostUrl + `${payload}`);
};

const updateCommunitybyId = (payload, id) => {
  return putRequest(updateCommunityPostUrl + `${id}`, payload);
};

const getCommunityPost = (page) => {
  return getRequestWithparams(
    `${getCommunityPostUrl}?page=${--page}&size=${10}`,
  );
};

function* createCommunityPost(action) {
  try {
    const Result = yield call(createPost, action.payload);
    const res = Result.response;

    if (res.status === 200) {
      yield put(createCommunityPostSuccess(res));
    } else {
      yield put(createCommunityPostFailure(res));
      yield put(
        showSnackbar({
          message: res.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(createCommunityPostFailure(error));
    yield put(
      showSnackbar({
        message: error,
        severity: "error",
      }),
    );
  }
}

function* getCommunity(action) {
  try {
    const Result = yield call(getCommunityPost, action.params);

    if (Result.status === 200) {
      yield put(getCommPostSuccess(Result.data));
    } else {
      yield put(getCommPostFailure(Result));
      yield put(
        showSnackbar({
          message: Result.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(getCommPostFailure(error));
    yield put(
      showSnackbar({
        message: error,
        severity: "error",
      }),
    );
  }
}

function* updateCommunity(action) {
  try {
    const result = yield call(updateCommunitybyId, action.payload, action.id);

    if (result.status === 200) {
      yield put(updateCommunitySuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(updateCommunityFailure(result));
      yield put(
        showSnackbar({
          message: result.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(updateCommunityFailure(error));
    yield put(
      showSnackbar({
        message: error,
        severity: "error",
      }),
    );
  }
}

function* deleteCommunity(action) {
  try {
    const result = yield call(deleteCommunityData, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteCommunitySuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteCommunityFails(result));

      yield put(
        showSnackbar({
          message: result.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(deleteCommunityFails(error));
    yield put(
      showSnackbar({
        message: error,
        severity: "error",
      }),
    );
  }
}

function* communitySaga() {
  yield takeEvery(CREATE_COMMUNITY, createCommunityPost);
  yield takeEvery(GET_COMMUNITY, getCommunity);

  yield takeEvery(DELETE_COMMUNITY, deleteCommunity);
  yield takeEvery(UPDATE_COMMUNITY, updateCommunity);
}
export default communitySaga;

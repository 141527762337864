import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";

// Initialize Mixpanel
mixpanel.init("bced684ff47e13c2dcad7db7700fbab6", { debug: true });

const withMixpanelTracking = (WrappedComponent, screenName) => {
  return (props) => {
    const location = useLocation();

    const startTime = React.useRef(new Date());

    useEffect(() => {
      // Set current user details
      setCurrUser();

      // Track screen view
      trackScreenView(screenName);

      // Cleanup on unmount: Track time spent on the screen
      return () => {
        const duration = Math.floor((new Date() - startTime.current) / 1000);
        trackTimeSpent(screenName, duration);
      };
    }, [location]);
    

    const setCurrUser = () => {
      const currUser = JSON.parse(localStorage.getItem("currUser"));

      if (currUser) {
        mixpanel.identify(currUser.email);
        mixpanel.people.set({
          $name: currUser.fullName,
          $email: currUser.email,
          $phoneNumber: currUser.phoneNumber,
          //"language": currUser.currLanguage,
        });
      }
    };

    const trackScreenView = (screenName) => {
      mixpanel.track(`${screenName} View`, {
        screenName,
      });
    };

    const trackTimeSpent = (screenName, duration) => {
      mixpanel.track(`${screenName} Time Spent`, {
        screenName,
        duration,
      });
    };

    const trackButtonClick = (
      buttonName,
      buttonTapCount,
      Eventname,
      additionalProperties = {},
    ) => {
      mixpanel.track(buttonName, {
        //screenName,
        buttonName,
        buttonTapCount,
        Eventname,
        ...additionalProperties,
      });
    };

    return <WrappedComponent {...props} trackButtonClick={trackButtonClick} />;
  };
};

export default withMixpanelTracking;

import MenuLayout from "../commonLayout/MenuLayout";

const Analytics = () => {
    return (
        <h1>Analytics</h1>
    )
}

const wrapper = () => {
    return (
      <MenuLayout childComponent = { () => { return ( <Analytics /> ) } }/>
    )
}

export default wrapper;

import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { FileUploader } from "react-drag-drop-files";

const DialogBoxFileUploadLayout = ({
  dialogOpen,
  setDialogOpen,
  uploadInProgress,
  progress,
  handleFileChange,
  toBeUploadedFileFormat,
  uploadType,
}) => {
  // State to store the selected file
  const [file, setFile] = useState(null);

  // Function to close the dialog
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogTitle>
        {/* Close Icon to close the dialog */}
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{ position: "absolute", right: "2%", top: "4%" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          justifyContent={"center"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minWidth: { xs: "200px", sm: "400px" },
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {/* File uploader component with drag-and-drop functionality */}
          <FileUploader
            handleChange={(event) => {
              handleFileChange(event);
            }}
            name="file"
            types={toBeUploadedFileFormat?.acceptedFileFormatForDragDrop}
            children={
              <img
                src="/fileUploadIcon.svg"
                alt="fileUpload-icon"
                style={{
                  cursor: "pointer",
                  background: "var(--primary-color)",
                  borderRadius: 40,
                }}
              />
            }
            multiple={uploadType === "filesData"} // Allow multiple files only for filesData
          />

          {/* Text and button components for file upload instructions */}
          <Typography variant="h5" justifyContent={"center"} mt={"4%"}>
            Drag File to Upload
          </Typography>
          <Typography variant="body2" justifyContent={"center"} mt={"2%"}>
            {`Upload ${toBeUploadedFileFormat?.fileType} File only`}
          </Typography>
          <Typography variant="body1" justifyContent={"center"} mt={"4%"}>
            or
          </Typography>

          {/* Button to open file picker dialog */}
          <Button
            variant="contained"
            component="label"
            value={file?.name}
            sx={{
              backgroundColor: "var(--secondary-color)",
              mt: "4%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src="/fileUpload-Icon.svg"
              alt="fileUpload-icon"
              style={{ height: 20, width: 20, marginRight: 10 }}
            />
            Choose File
            <input
              hidden
              type="file"
              accept={
                toBeUploadedFileFormat?.acceptedFileFormatForNormalFileUpload
              }
              multiple={uploadType === "filesData"}
              onChange={(event) => {
                handleFileChange(event);
              }}
            />
          </Button>

          {/* Upload Progress Dialog */}
          {uploadInProgress && (
            <Box mt={4} width="100%">
              <LinearProgress variant="determinate" value={progress} />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
              >{`${Math.round(progress)}%`}</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogBoxFileUploadLayout;

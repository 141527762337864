import { call, put, takeEvery } from "redux-saga/effects";
import {
  uploadKirtanWithSongUrl,
  uploadKirtanWithAlbumUrl,
  updateKirtanWithSongUrl,
  updateKirtanWithAlbumUrl,
  getAllKirtanWithAlbum,
  getAllKirtanWithSinger,
  deleteSongWitlSingerUrls,
  deleteSongWitlAlbumUrls,
} from "../../apiServices/ApiUrl";
import {
  getRequest,
  putRequest,
  deleteRequest,
  postRequest,
} from "../../apiServices/ApiActions";
import {
  CREATE_KIRTAN_WITH_SINGER,
  UPDATE_KIRTAN_WITH_SINGER,
  CREATE_KIRTAN_WITH_ALBUM,
  UPDATE_KIRTAN_WITH_ALBUM,
  GET_KIRTAN_WITH_ALBUM,
  GET_KIRTAN_WITH_SINGER,
  DELETE_SONG_SINGER,
  DELETE_SONG_ALBUM,
  createKirtanWithSingerSuccess,
  createKirtanWithSingerFail,
  updateKirtanWithSingerFail,
  createKirtanWithAlbumSuccess,
  createKirtanWithAlbumFail,
  updateKirtanWithAlbumSuccess,
  updateKirtanWithAlbumFail,
  updateKirtanWithSingerSuccess,
  getKirtanWithSingerFail,
  getKirtanWithSingerSuccess,
  getKirtanWithAlbumFail,
  getKirtanWithAlbumSuccess,
  deleteAlbumSongSuccess,
  deleteAlbumSongFailure,
  deleteSingerSongSuccess,
  deleteSingerSongFailure,
} from "./action";

import { showSnackbar } from "../snackbar/action";

const createCallWithSinger = async (payload) => {
  return postRequest(uploadKirtanWithSongUrl, payload);
};

const createCallWithAlbum = async (payload) => {
  return postRequest(uploadKirtanWithAlbumUrl, payload);
};

const updateCallWithSinger = async (payload, id) => {
  return putRequest(updateKirtanWithSongUrl + `${id}`, payload);
};

const updateCallWithAlbum = async (payload, id) => {
  return putRequest(updateKirtanWithAlbumUrl + `${id}`, payload);
};

const getCallWithAlbum = async () => {
  return getRequest(getAllKirtanWithAlbum);
};

const getCallWithSinger = async () => {
  return getRequest(getAllKirtanWithSinger);
};

const deleteCallWithSinger = async (id) => {
  return deleteRequest(deleteSongWitlSingerUrls + `${id}`);
};
const deleteCallWithAlbum = async (id) => {
  return deleteRequest(deleteSongWitlAlbumUrls + `${id}`);
};

function* createKirtansWithSinger(action) {
  try {
    const result = yield call(createCallWithSinger, action.payload);
    if (result.status === 200) {
      yield put(createKirtanWithSingerSuccess(result));
      yield put(
        showSnackbar({
          message: "Song Created successfully!",
          severity: "success",
        }),
      );
    } else {
      yield put(createKirtanWithSingerFail(result));
      yield put(
        showSnackbar({
          message: "Song Is Not created.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(createKirtanWithSingerFail(error));
  }
}

function* updateKirtansWithSinger(action) {
  try {
    const result = yield call(updateCallWithSinger, action.payload, action.id);
    if (result.status === 200) {
      yield put(updateKirtanWithSingerSuccess(result));
      yield put(
        showSnackbar({
          message: "Song Created successfully!",
          severity: "success",
        }),
      );
    } else {
      yield put(updateKirtanWithSingerFail(result));
      yield put(
        showSnackbar({
          message: "Song Is Not created.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(updateKirtanWithSingerFail(error));
  }
}

function* createKirtansWithAlbum(action) {
  try {
    const result = yield call(createCallWithAlbum, action.payload);
    if (result.status === 200) {
      yield put(createKirtanWithAlbumSuccess(result));
    } else {
      yield put(createKirtanWithAlbumFail(result));
    }
  } catch (error) {
    yield put(createKirtanWithAlbumFail(error));
  }
}

function* updateKirtansWithAlbum(action) {
  try {
    const result = yield call(updateCallWithAlbum, action.payload, action.id);
    if (result.status === 200) {
      yield put(updateKirtanWithAlbumSuccess(result));
    } else {
      yield put(updateKirtanWithAlbumFail(result));
    }
  } catch (error) {
    yield put(updateKirtanWithAlbumFail(error));
  }
}

function* getKirtanWithSinger(action) {
  try {
    const result = yield call(getCallWithSinger);

    if (result.status === 200) {
      let response = result.data;
      yield put(getKirtanWithSingerSuccess(response.singers));
    } else {
      yield put(getKirtanWithSingerFail(result));
    }
  } catch (error) {
    yield put(getKirtanWithSingerFail(error));
  }
}

function* deleteAlbumsSong(action) {
  try {
    const result = yield call(deleteCallWithAlbum, action.id);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteAlbumSongSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteAlbumSongFailure(result));
      yield put(
        showSnackbar({
          message: "Song Deleted successfully!",
          severity: "success",
        }),
      );

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(deleteAlbumSongFailure(error));
  }
}

function* deleteSingerSong(action) {
  try {
    const result = yield call(deleteCallWithSinger, action.id);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteSingerSongSuccess(result));
      yield put(
        showSnackbar({
          message: "Song Deleted successfully!",
          severity: "success",
        }),
      );
    } else {
      let res = result?.response?.data;

      yield put(deleteSingerSongFailure(result));
      yield put(
        showSnackbar({
          // message: res.message,
          message: result.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(deleteSingerSongFailure(error));
  }
}

function* getKirtanWithAlbum(action) {
  try {
    const result = yield call(getCallWithAlbum);

    if (result.status === 200) {
      let response = result.data;
      yield put(getKirtanWithAlbumSuccess(response.albums));
    } else {
      yield put(getKirtanWithAlbumFail(result));
    }
  } catch (error) {
    yield put(getKirtanWithAlbumFail(error));
  }
}
function* kirtanSaga() {
  yield takeEvery(CREATE_KIRTAN_WITH_SINGER, createKirtansWithSinger);
  yield takeEvery(UPDATE_KIRTAN_WITH_SINGER, updateKirtansWithSinger);
  yield takeEvery(CREATE_KIRTAN_WITH_ALBUM, createKirtansWithAlbum);
  yield takeEvery(UPDATE_KIRTAN_WITH_ALBUM, updateKirtansWithAlbum);
  yield takeEvery(GET_KIRTAN_WITH_SINGER, getKirtanWithSinger);
  yield takeEvery(GET_KIRTAN_WITH_ALBUM, getKirtanWithAlbum);
  yield takeEvery(DELETE_SONG_ALBUM, deleteAlbumsSong);
  yield takeEvery(DELETE_SONG_SINGER, deleteSingerSong);
}
export default kirtanSaga;

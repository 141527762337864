import { Navigate, Outlet } from 'react-router-dom';

// middleware function that will check whether the user has access to a particular route or not
const PrivateRoutes = () => {
    let userToken = localStorage.getItem("token");

    let auth = { 'token': userToken ? true : false };

    return (
        auth.token ? <Outlet/> : <Navigate to='/login' />
    )
}

export default PrivateRoutes;

import {
    ADMIN_LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    ADMIN_LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE
} from "./action";

const initialState = {
    success: false,
    loading: false,
    error: "",
    data: [],
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_LOGIN:
            return {
                ...state,
                data: [],
                error: "",
                loading: true,
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: "",
                success: true,
                loading: false,
            };

        case LOGIN_FAILURE:
            return {
                ...state,
                data: [],
                error: action.payload,
                success: false,
                loading: false,
            };
        case ADMIN_LOGOUT:
            return {
                ...state,
                data: [],
                error: "",
                success: false,
            };

      

        default:
            return state;
    }
};

import { call, put, takeEvery } from "redux-saga/effects";
import {
  getLeaderBoardsFromApi,
  getTodayTaskFromApi,
  getUploadStatusFromApi,
  getUserStatsFromApi,
} from "../../apiServices/dashboard";
import {
  GET_LEADERBOARD,
  GET_TODAY_TASK,
  GET_UPLOAD_STATUS,
  GET_USER_STATS,
  getLeaderboardsFailure,
  getLeaderboardsSuccess,
  getTodayTaskFailure,
  getTodayTaskSuccess,
  getUploadStatusFailure,
  getUploadStatusSuccess,
  getUserStatsFailure,
  getUserStatsSuccess,
} from "./action";

const getUploadStatusApi = async () => {
  return getUploadStatusFromApi();
};

const getTodayTaskApi = async () => {
  return getTodayTaskFromApi();
};

const getLeaderboardsApi = async () => {
  return getLeaderBoardsFromApi();
};

const getUserStatsApi = async () => {
  return getUserStatsFromApi();
};

function* getUploadStatusResponse() {
  try {
    const result = yield call(getUploadStatusApi);
    if (result.status === 200) {
      yield put(getUploadStatusSuccess(result));
    } else {
      yield put(getUploadStatusFailure(result));
    }
  } catch (error) {
    yield put(getUploadStatusFailure(error));
  }
}

function* geTodayTaskResponse() {
  try {
    const result = yield call(getTodayTaskApi);
    if (result.status === 200) {
      yield put(getTodayTaskSuccess(result));
    } else {
      yield put(getTodayTaskFailure(result));
    }
  } catch (error) {
    yield put(getTodayTaskFailure(error));
  }
}

function* getLeaderboardResponse() {
  try {
    const result = yield call(getLeaderboardsApi);
    if (result.status === 200) {
      yield put(getLeaderboardsSuccess(result.data.content));
    } else {
      yield put(getLeaderboardsFailure(result));
    }
  } catch (error) {
    yield put(getLeaderboardsFailure(error));
  }
}

function* getUserStatsResponse() {
  try {
    const result = yield call(getUserStatsApi);
    if (result.status === 200) {
      yield put(getUserStatsSuccess(result.data));
    } else {
      yield put(getUserStatsFailure(result));
    }
  } catch (error) {
    yield put(getUserStatsFailure(error));
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_UPLOAD_STATUS, getUploadStatusResponse);
  yield takeEvery(GET_TODAY_TASK, geTodayTaskResponse);
  yield takeEvery(GET_LEADERBOARD, getLeaderboardResponse);
  yield takeEvery(GET_USER_STATS, getUserStatsResponse);
}

export default dashboardSaga;

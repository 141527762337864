import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import useCustomStyles from "../../customHooks/useCustomStyles";
import { truncateAtDot } from "../../helpers/truncateFileName";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { postEvents, saveEvents, updateEvents } from "../../apiServices/event";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import { formatDateInUTC, tomorrowDate } from "../../helpers/dateHelper";
import { getAllGURUDWARA } from "../../redux/gurudwara/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createEvents, getEvents } from "../../redux/Events/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { FileUploadOutlined } from "@mui/icons-material";

const DatePickerTextField = styled(TextField)({
  backgroundColor: "white",
  "& .MuiInputBase-root": {
    backgroundColor: "white",
  },
  "& .MuiInputBase-input": {
    padding: "10px 14px",
  },
});
const styles = (theme) => ({
  container: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1536)]: {
      padding: "10px 10px",
      marginTop: "25px",
      height: "65vh",
      overflowY: "auto",
      scrollbarWidth:'thin'
    },
    [theme.breakpoints.between(1536, 1919)]: {
      padding: "10px 10px",
      marginTop: "25px",
      height: "460px",
      overflowY: "auto",
      scrollbarWidth:'thin'
    },
    [theme.breakpoints.up(1920)]: {
      padding: "0 20px 25px",
      marginTop: "20px",
      height: "700px",
    },
  },

  ContainerMargin: {
    [theme.breakpoints.down(1536)]: {
      marginTop: "15%",
    },
    [theme.breakpoints.between(1536, 1919)]: {
      marginTop: "15%",
    },
    [theme.breakpoints.up(1920)]: {
      marginTop: "10%",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEvent = (props) => {
  const formatDateinUTC = formatDateInUTC(tomorrowDate());
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const editRecords = JSON.parse(decodedData);
  const { action, tabs } = useParams();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  const [eventData, setEventData] = useState({
    name: "",
    thumbnailData: "",
    filesData: [],
    dateTime: formatDateinUTC,
    localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    description: "",
    locationId: "",
    languageCode: " ",
    uploadType: "EVENT",
    locationName: "",
  });

  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });
  const matches = useMediaQuery("(max-width:1440px)");
  // State to manage file names for thumbnail and image
  const [thumbnailFileName, setThumbnailFileName] = useState("");
  const [imageFileNames, setimageFileNames] = useState([]);
  const [thumbnailError, setThumbnailError] = useState(false);
  const [imageError, setImageError] = useState(false);

  // State to control visibility of schedule box and dialogs
  const [dialogOpen, setDialogOpen] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dialogBoxToggle, setDialogBoxToggle] = useState(false);

  // State to manage upload progress and status
  const [progress, setProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);

  // State to determine the type of file to be uploaded
  const [uploadType, setUploadType] = useState("");
  const datepickerRef = useRef(); // Create a ref for DatePicker

  const openDatePicker = () => {
    datepickerRef.current.setOpen(true); // Manually open DatePicker
  };

  const handleOpen = (
    type,
    fileType,
    dragDropFileFormat,
    normalUploadFileFormat,
  ) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setUploadType(type);
    setDialogOpen(true);
  };

  const handleDateChange = (newValue) => {
    const utcDate = newValue ? formatDateInUTC(newValue) : null;

    setEventData({
      ...eventData,

      dateTime: utcDate,
    });
  };

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    if (uploadType === "thumbnailData") {
      setThumbnailFileName("");
    } else if (uploadType === "filesData") {
      setimageFileNames([]);
    }
  };

  const handleFileChange = (e) => {
    let files = e.target ? e.target.files : e;

    if (uploadType === "thumbnailData") {
      files = e.target ? e.target.files[0] : e;

      // Ensure only one file is allowed for thumbnailData
      if (files.length > 1) {
        alert("Only one file is allowed for thumbnail.");
        return;
      }

      if (
        files &&
        (files.type === "image/jpeg" || files.type === "image/png")
      ) {
        setEventData({ ...eventData, thumbnailData: files });
        setThumbnailFileName(files.name);
        setDialogOpen(false);
        setProgress(0);
        setUploadFileName(files.name);
        handleProgressDialogOpen();
        uploadFile(files);
        setThumbnailError(false);
      } else {
        alert("Please upload a valid jpeg/png file for thumbnail.");
      }
    } else if (uploadType === "filesData") {
      // Ensure only up to 3 files are allowed for filesData
      const newFiles = Array.from(files);

      // Check if the total number of files exceeds the limit
      if (newFiles.length > 3) {
        alert("You can only upload up to 3 files.");
        return;
      }

      const validFiles = newFiles.filter(
        (file) => file.type === "image/jpeg" || file.type === "image/png",
      );

      if (validFiles.length > 0) {
        setEventData({
          ...eventData,
          filesData: [...eventData.filesData, ...validFiles],
        });
        setimageFileNames((prevFiles) => [
          ...prevFiles,
          ...validFiles.map((files) => truncateAtDot(files.name)),
        ]);
        setDialogOpen(false);
        setProgress(0);
        setUploadFileName(validFiles.map((file) => file.name).join(", "));
        handleProgressDialogOpen();
        uploadFile(validFiles);
        setImageError(false);
      } else {
        alert("Please upload valid jpeg/png files.");
      }
    }
  };

  const handleChangeLanguage = (val) => {
    setEventData({
      ...eventData,
      languageCode: val.target.value,
    });
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const uploadFile = (file) => {
    setProgress(0);
    const UploadProgress = () => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);
        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);
      setUploadInterval(interval);
    };
    UploadProgress();
  };

  const handleChange = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value,
    });
  };

  const createEventData = async (uploadedFiles) => {
    const addEventData = {};

    addEventData["description"] = eventData.description;
    addEventData["locationId"] = eventData.locationId;
    addEventData["localTimeZone"] = eventData.localTimeZone;
    addEventData["languageCode"] = eventData.languageCode;
    addEventData["name"] = eventData.name;
    addEventData["dateTime"] = eventData.dateTime;

    const filteredImages = uploadedFiles.filter(
      (file) => file.fileName !== thumbnailFileName,
    );
    addEventData["filesData"] = filteredImages;

    const filteredThumbnailImage = uploadedFiles.filter(
      (file) => file.fileName === thumbnailFileName,
    );
    addEventData["thumbnailData"] = filteredThumbnailImage[0];

    try {
      let final;
      if (action === "edit") {
        final = await updateEvents(addEventData, editRecords?.id);
      } else {
        final = await saveEvents(addEventData);
      }

      if (final.status === 200 || final.status === 201) {
        setDialogBoxToggle(true);
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        alert(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setEventData({
        ...eventData,
        name: records.name,
        description: records.description,
        localTimeZone: records.localTimeZone,
        dateTime: records.dateTime,
        languageCode: records.languageCode,
        locationName: records.locationName,
        locationId: records.locationId,
      });
      if (records?.filesData && records?.filesData.length > 0) {
        let newArr = [];

        records?.filesData.map((item) =>
          newArr.push(truncateAtDot(item.fileName)),
        );
        setimageFileNames(newArr);
      }
      setThumbnailFileName(records.thumbnailUrl?.fileName);
    }
  }, [action]);

  useEffect(() => {
    if (props.successRsponse) {
      setEventData({
        ...eventData,
        name: "",
        localTimeZone: "",
        dateTime: formatDateinUTC,
        description: "",
        languageCode: "",
      });
      setThumbnailFileName("");
      setimageFileNames([]);
    }
  }, [props.successRsponse]);

  useEffect(() => {
    props.getAllGURUDWARA();
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (thumbnailFileName === "") {
      setThumbnailError(true);
      return;
    }

    if (imageFileNames.length === 0) {
      setImageError(true);
      return;
    }

    setDialogBoxToggle(false);
    if (action === "edit" && eventData.filesData.length === 0) {
      const addEventData = {};

      addEventData["description"] = eventData.description;
      addEventData["locationId"] = eventData.locationId;
      addEventData["localTimeZone"] = eventData.localTimeZone;
      addEventData["languageCode"] = eventData.languageCode;
      addEventData["name"] = eventData.name;
      addEventData["dateTime"] = eventData.dateTime;

      addEventData["filesData"] = editRecords?.filesData;

      addEventData["thumbnailData"] = editRecords?.thumbnailUrl;

      try {
        let final = await updateEvents(addEventData, editRecords?.id);
        props.trackButtonClick("Update Event Button", 1);
        if (final.status === 200 || final.status === 201) {
          setDialogBoxToggle(true);
        }
      } catch (error) {
        if (error?.response?.status === 409) {
          alert(error?.response?.data?.message);
        }
      }
    } else {
      const formData = new FormData();

      formData.append("languageCode", eventData.languageCode);
      formData.append("uploadType", eventData.uploadType);

      eventData.filesData.forEach((file) => {
        formData.append("files", file, file.name);
      });

      try {
        const result = await postEvents(formData);

        if (result.status === 200 || result.status === 201) {
          createEventData(result?.data);
          setEventData({
            ...eventData,
            name: "",
            localTimeZone: "",
            dateTime: formatDateinUTC,
            description: "",
          });
          setThumbnailFileName("");
          setimageFileNames([]);
          props.trackButtonClick("Create Event Button", 1);
        }
      } catch (error) {
        if (error?.response?.status === 409) {
          alert(error?.response?.data?.message);
        }
      }
    }
  };

  const handleCloseModel = () => {
    navigate(`/content/${tabs}/view`);
  };

  return (
    <>
      {dialogBoxToggle && (
        <DialogBoxLayout
          dialogBoxToggle={dialogBoxToggle}
          setDialogBoxToggle={setDialogBoxToggle}
          header="Uploaded Successfully!"
          content="You have Uploaded Your File Successfully"
          handleCloseModel={handleCloseModel}
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadType={uploadType}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          uploadFileName={uploadFileName}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleFormSubmit}
      >
        <Stack
          spacing={2}
          direction="column"
          gap={3}
          className={classes?.ContainerMargin}
        >
          <Stack
            spacing={2}
            direction="row"
            gap={3}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
            >
              <TextField
                fullWidth
                label="Title"
                placeholder="Enter Title"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size={ matches?'small':'' }
                sx={{ backgroundColor: "white" }}
                value={eventData.name}
                onChange={handleChange}
                required
                id="title"
                name="name"
              />
            </FormControl>

            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
            >
              <TextField
                fullWidth
                id="thumbnail"
                name="thumbnailData"
                InputLabelProps={{ shrink: true }}
                label="Thumbnail"
                placeholder="Add File"
                variant="outlined"
                size={ matches?'small':'' }
                required={editRecords ? false : true}
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button
                      aria-label="upload"
                      component="label"
                      className="addFileButton"
                      onClick={() =>
                        handleOpen(
                          "thumbnailData",
                          "JPEG",
                          ["JPEG", "PNG"],
                          [".jpeg, .png"],
                        )
                      }
                    >
                      Add File
                    </Button>
                  ),
                }}
                value={truncateAtDot(thumbnailFileName)}
              />
              {thumbnailError && (
                <div style={{ color: "red" }}>Thumbnail required</div>
              )}
            </FormControl>
          </Stack>

          <Stack
            spacing={2}
            direction="row"
            gap={3}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
              size={ matches?'small':'' }
            >
              <DatePickerTextField
                label="Date & Time"
                value={
                  eventData.dateTime
                    ? format(eventData?.dateTime, "MM/dd/yyyy h:mm aa")
                    : ""
                }
                InputProps={{
                  readOnly: true,                
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={openDatePicker}>
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <DatePicker
                minDate={tomorrowDate()}
                selected={eventData.dateTime}
                ref={datepickerRef}
                onChange={handleDateChange}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                portalId="root-portal"
                customInput={
                  <div
                    style={{
                      position: "absolute",
                      opacity: 0,
                      pointerEvents: "none",
                    }}
                  >
                    <input />
                  </div>
                }
              />
            </FormControl>

            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, marginTop:'-1.5rem !important'}}
            >
              <TextField
                fullWidth
                id="image"
                name="filesData"
                label="Image"
                InputLabelProps={{ shrink: true }}
                placeholder="Add File"
                variant="outlined"
                required={editRecords ? false : true}
                sx={{ backgroundColor: "white" }}
                size={ matches?'small':'' }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button
                      aria-label="upload"
                      component="label"
                      className="addFileButton"
                      onClick={() =>
                        handleOpen(
                          "filesData",
                          "JPEG",
                          ["JPEG", "PNG"],
                          [".jpeg, .png"],
                        )
                      }
                    >
                      Add File
                    </Button>
                  ),
                }}
                value={imageFileNames.join(", ")}
              />
              {imageError && <div style={{ color: "red" }}>Image required</div>}
            </FormControl>
          </Stack>

          <Stack
            spacing={1}
            direction="row"
            gap={3}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
            >
              <TextField
                fullWidth
                label="Description"
                size={ matches?'small':'' }
                multiline
                InputLabelProps={{ shrink: true }}
                placeholder="Enter Description"
                rows={5}
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                value={eventData.description}
                onChange={handleChange}
                required
                id="description"
                name="description"
              />
            </FormControl>

            <Stack spacing={2} direction="column" gap={3} >
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={ matches?'small':'' }
              >
                <InputLabel id="demo-simple-select-label">
                  Choose Language
                </InputLabel>
                <Select
                  required
                  sx={{ backgroundColor: "white" }}
                  InputLabelProps={{ shrink: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={eventData?.languageCode}
                  label="Select Language"
                  placeholder="Choose Language"
                  onChange={(e) => handleChangeLanguage(e)}
                >
                  <MenuItem value="en_US">English</MenuItem>
                  <MenuItem value="hi_IN">Hindi</MenuItem>
                  <MenuItem value="pa_IN">Punjabi</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={ matches?'small':'' }
              >
                <InputLabel id="demo-simple-select-label" >
                  Choose Gurudwara
                </InputLabel>
                <Select
                  required
                  sx={{ mt: 1, backgroundColor: "white" }}
                  InputLabelProps={{ shrink: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={eventData.locationId}
                  label="Add Gurudwara"
                  placeholder="Enter Gurudwara Name"
                  onChange={(event) =>
                    setEventData({
                      ...eventData,
                      locationId: event.target.value,
                      locationName: event.target.value,
                    })
                  }
                >
                  {props?.guruDwaraData?.length > 0 &&
                    props?.guruDwaraData?.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>

        <Grid xs={12} sx={{ mt: 4, mb: 2 }}>
          <Button
            type="submit"
            variant="contained"
            startIcon={<FileUploadOutlined />}
            sx={{
              backgroundColor: "var(--secondary-color)",
              textTransform: "none",
              fontSize: "20px",
              fontWeight: "500",
              marginLeft: { sm: 11, md: 52, lg: 86, xl: 113 },
            }}
            size="small"
          >
            {action === "edit" ? "Update" : "Upload"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { successRsponse, eventsData } = state.eventReducer;
  const { data, getAllGURUDWARA } = state.gurudwaraReducer;
  return {
    successRsponse,
    eventsData,
    getAllGURUDWARA,
    guruDwaraData: data?.data,
  };
};
export default connect(mapStateToProps, {
  getAllGURUDWARA,
  createEvents,
  getEvents,
})(withMixpanelTracking(AddEvent, "Add Event"));

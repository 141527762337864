import React, { useState, useEffect } from "react";

import {
  TextField,
  Button,
  Grid,
  Stack,
  useTheme,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  useMediaQuery,
} from "@mui/material";

import useCustomStyles from "../../customHooks/useCustomStyles";
import PreviewCommunityPost from "./PreviewCommunityPost";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import { todayDateinUTC } from "../../helpers/dateHelper";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  postWealths,
  savePosts,
  updatePosts,
} from "../../apiServices/communityPost";
import { connect } from "react-redux";
import { createCommPost, getCommPost } from "../../redux/community/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FileUploadOutlined } from "@mui/icons-material";

const styles = (theme) => ({
  container: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1536)]: {
      padding: "10px 10px",
      height: "430px",
      overflowY: "auto",
      scrollbarWidth:'thin'
    },
    [theme.breakpoints.between(1536, 1919)]: {
      padding: "10px 10px",
      marginTop: "20px",
      height: "500px",
      overflowY: "auto",
      scrollbarWidth:'thin'
    },
    [theme.breakpoints.up(1920)]: {
      padding: "0 20px 25px",
      marginTop: "20px",
      height: "700px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddCommunityPost = (props) => {
  const utcFormattedDate = todayDateinUTC();
  const navigate = useNavigate();
  const { action, tabs } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  const [postData, setPostData] = useState({
    name: "",
    description: "",
    files: [],
    date: utcFormattedDate,
    languageCode: "",
    uploadType: "COMMON_WEALTH",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",

    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  // State variables for controlling dialogs and upload progress
  const [ImageFileName, setImageFileName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dialogBoxToggle, setDialogBoxToggle] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [fileError, setFileError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (ImageFileName === "") {
      setFileError(true);
      return;
    }

    setDialogBoxToggle(true);

    if (postData.files?.length > -1) {
      const newPreviewUrls = postData.files.map((file) =>
        URL.createObjectURL(file),
      );
      let images = new Set(newPreviewUrls);
      images = Array.from(images);
      setPreviewUrls(images);
      setPreviewOpen(true);
    }
  };

  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  // Close the preview component
  const handleClosePreview = () => {
    setPreviewOpen(false);
    setDialogBoxToggle(false);
  };

  // Close the progress dialog
  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    setImageFileName("");
  };

  // Handle input changes for title and description fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "description" && value?.length > 900) {
      alert("Maximum 900 characters");
      return;
    }
    setPostData({
      ...postData,
      [name]: value,
    });
  };

  const handleChangeLanguage = (val) => {
    setPostData({
      ...postData,
      languageCode: val.target.value,
    });
  };
  // Handle file selection and upload
  const handleFileChange = (e) => {
    let files = e.target ? e.target.files : e;
    files = Array.from(files);

    // Filter valid image files
    const validFiles = files.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png",
    );

    // Check for maximum file upload limit
    if (validFiles?.length + postData.files?.length > 10) {
      alert("You can upload a maximum of 10 files.");
      return;
    }

    if (validFiles?.length > 0) {
      setUploadFileName(validFiles.map((file) => file.name).join(", "));
      setPostData((prevPostData) => ({
        ...prevPostData,
        files: [...prevPostData.files, ...validFiles],
      }));
      setImageFileName(validFiles.map((file) => file.name).join(", "));
      setDialogOpen(false);
      handleProgressDialogOpen();
      validFiles.forEach((file) => uploadFile(file));
    } else {
      alert("Please upload valid JPEG/PNG files");
    }
  };

  // Open the progress dialog
  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  // Simulate file upload and progress
  const uploadFile = (file) => {
    setProgress(0);
    const UploadProgress = () => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);
        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          handleSuccessDialogOpen();
        }
      }, 300);
      setUploadInterval(interval);
    };
    UploadProgress();
    setFileError(false);
  };

  // Open the success dialog
  const handleSuccessDialogOpen = () => {
    setProgressDialogOpen(false);
  };

  // Handle posting the community post
  const handlePost = (event) => {
    event.preventDefault();
    setPreviewOpen(false);
    setDialogBoxToggle(false);
    handleFormSubmit(event);
  };

  const createCommunityPost = async (uploadedFiles) => {
    const communityPostData = {};

    communityPostData["name"] = postData.name;
    communityPostData["date"] = postData.date;
    communityPostData["description"] = postData.description;
    communityPostData["languageCode"] = postData.languageCode;
    let ImageFiles = [];
    if (uploadedFiles) {
      uploadedFiles.map((item) => {
        let newitem = {};
        newitem["fileName"] = item.fileName;
        newitem["path"] = item.filePath;
        newitem["fileType"] = item.fileType;
        newitem["fileSize"] = item.size;
        ImageFiles.push(newitem);
      });
    }

    communityPostData["files"] = ImageFiles;

    try {
      let final;
      if (action === "edit") {
        communityPostData["id"] = editRecords;
        final = await updatePosts(communityPostData, editRecords.id);
        props.trackButtonClick("Update Community Post Button", 1);
      } else {
        final = await savePosts(communityPostData);
        props.trackButtonClick("Create Community Post Button", 1);
      }
      if (final.status === 200 || final.status === 201) {
        setDialogBoxToggle(true);
      }
    } catch (error) {
      if (error?.response?.status === 302) {
        alert(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setPostData({
        ...postData,
        name: records.name,
        description: records.description,
        date: records.date,
        languageCode: records.languageCode,
      });
      if (records?.fileDetails && records?.fileDetails.length > 0) {
        let data = records?.fileDetails[0];

        setImageFileName(data.fileName);
      }
      // setThumbnailFileName(records.thumbnailUrl?.fileName);
    }
  }, []);

  useEffect(() => {
    if (props.communtyPostdata) {
      setPostData({
        ...postData,
        name: "",
        description: "",
        date: utcFormattedDate,
      });
      setImageFileName([]);
    }
  }, [props.communtyPostdata]);

  // Function to POST all community posts
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setDialogBoxToggle(false);

    if (action === "edit" && postData.files?.length === 0) {
      const communityPostData = {};

      communityPostData["name"] = postData.name;
      communityPostData["description"] = postData.description;
      communityPostData["date"] = postData.date;
      communityPostData["languageCode"] = postData.languageCode;
      communityPostData["files"] = editRecords.fileDetails;
      communityPostData["id"] = editRecords.id;

      try {
        let result = await updatePosts(communityPostData, editRecords.id);
        setDialogBoxToggle(false);
        if (result.status === 200 || result.status === 201) {
          setDialogBoxToggle(true);
        }
      } catch (error) {
        if (error?.response?.status === 302) {
          alert(error?.response?.data?.message);
        }
      }
    } else {
      const formData = new FormData();

      formData.append("languageCode", postData.languageCode);
      formData.append("uploadType", postData.uploadType);

      // Append each file to the form data
      postData.files.forEach((file) => {
        formData.append("files", file, file.name);
      });

      try {
        const result = await postWealths(formData);

        if (result.status === 200 || result.status === 201) {
          createCommunityPost(result?.data);
          setPostData({
            ...postData,
            name: "",
            description: "",
            date: utcFormattedDate,
          });
          setImageFileName([]);
        }
      } catch (error) {
        if (error?.response?.status === 302) {
          alert(error?.response?.data?.message);
        }
      }
    }
  };

  const handleCloseModel = () => {
    navigate(`/content/${tabs}/view`);
  };

  return (
    <>
      {dialogBoxToggle && (
        <DialogBoxLayout
          dialogBoxToggle={dialogBoxToggle}
          setDialogBoxToggle={setDialogBoxToggle}
          header="Uploaded Successfully!"
          content="You have Uploaded Your Post Successfully"
          handleCloseModel={handleCloseModel}
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadType="filesData"
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadFileName={uploadFileName}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleSubmit}
      >
        <div>
          <Stack
            spacing={2}
            direction="column"
            gap={3}
            marginTop={"5%"}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <Stack spacing={2} direction="row" gap={3}>
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  placeholder="Enter title"
                  size={ matches?'small':'' }
                  InputLabelProps={{ shrink: true }}
                  sx={{ backgroundColor: "white" }}
                  value={postData.name}
                  onChange={handleChange}
                  required
                  id="name"
                  name="name"
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={ matches?'small':'' }
              >
                <InputLabel id="demo-simple-select-label">
                  Choose Language
                </InputLabel>
                <Select
                  required
                  sx={{ backgroundColor: "white" }}
                  InputLabelProps={{ shrink: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={postData?.languageCode}
                  label="Select Language"
                  placeholder="Choose Language"
                  onChange={(e) => handleChangeLanguage(e)}
                >
                  <MenuItem value="en_US">English</MenuItem>
                  <MenuItem value="hi_IN">Hindi</MenuItem>
                  <MenuItem value="pa_IN">Punjabi</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack spacing={2} direction="row" gap={3}>
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  fullWidth
                  id="file"
                  name="file"
                  label="File"
                  variant="outlined"
                  size={ matches?'small':'' }
                  required
                  placeholder="Add File"
                  InputLabelProps={{ shrink: true }}
                  sx={{ backgroundColor: "white", mb: "5%" }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Button
                        aria-label="upload"
                        component="label"
                        className="addFileButton"
                        onClick={() =>
                          handleOpen("JPEG", ["JPEG", "PNG"], [".jpeg, .png"])
                        }
                      >
                        Add File
                      </Button>
                    ),
                  }}
                  value={ImageFileName}
                />
                {fileError && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "-20px",
                      marginBottom: "20px",
                    }}
                  >
                    File required
                  </div>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs} size={ matches?'small':'' }>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      required
                      id="scheduleDate"
                      label="Date *"
                      variant="outlined"
                      size={ matches?'small':'' }
                      InputLabelProps={{ shrink: true }}
                      value={dayjs(postData.date)}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        color: "#000000",
                      }}
                      format="DD/MM/YYYY"
                      onChange={(newValue) => {
                        setPostData({
                          ...postData,
                          date: newValue ? newValue.toISOString() : null,
                        });
                      }}
                      shouldDisableDate={(date) => {
                        return date.isBefore(dayjs().startOf("day"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
              >
                <TextField
                  fullWidth
                  label="Description"
                  multiline
                  rows={6}
                  variant="outlined"
                  size={ matches?'small':'' }
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter Description"
                  sx={{ backgroundColor: "white" }}
                  value={postData.description}
                  onChange={handleChange}
                  required
                  id="description"
                  name="description"
                />
              </FormControl>
            </Stack>
          </Stack>

          <Grid item xs={12} display="flex" justifyContent={"flex-end"} mt="5%">
            <Button
              type="submit"
              variant="contained"
              startIcon={<FileUploadOutlined />}
              sx={{
                backgroundColor: "var(--secondary-color)",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "500",
              }}
              size="small"
            >
              {action === "edit" ? "Update" : "Upload"}
            </Button>
          </Grid>
        </div>
      </Grid>

      {previewOpen && (
        <PreviewCommunityPost
          open={previewOpen}
          name={postData.name}
          date={postData.date}
          images={
            postData.files?.length > 0 ? postData.files : editRecords.filesUrl
          }
          description={postData.description}
          previewUrls={
            previewUrls?.length > 0 ? previewUrls : editRecords.filesUrl
          }
          onClose={handleClosePreview}
          handlePost={handlePost}
          EditRecordsData={editRecords ? editRecords : []}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { communtyPostdata, getCommPostSuccess } = state.eventReducer;
  return {
    communtyPostdata,
    getCommPostSuccess,
  };
};

export default connect(mapStateToProps, { createCommPost, getCommPost })(
  withMixpanelTracking(AddCommunityPost, " Add Community Post"),
);

import { apiClient } from "./AxiosClient";

export const getAllGurudwara = async () => await apiClient.get("/location/all");

export const createGurudwara = async (data) =>
  await apiClient.post("/location/save", data);

export const updateGurudwara = async (data, id) =>
  await apiClient.put(`/location/update`, data);


export const updateGurudwaraStatus = async (id, status) =>
  await apiClient.put(`/location/switch/${id}/${status}`);

export const deleteGurudwara = async (id) =>
  await apiClient.delete(`/location/${id}`);

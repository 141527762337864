import "./App.css";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./auth/AuthMiddleware";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import UserManagament from "./components/UserManagement";
import AppManagement from "./components/AppManagement";
import ContentManagement from "./components/ContentManagement";
import Analytics from "./components/Analytics";
import CrashAnalytics from "./components/CrashAnalytics";
import Volunteer from "./components/Volunteer";
import Feedback from "./components/Feedback";
import DeleteRequest from "./components/delete-request/DeleteRequest";
import Churches from "./components/Church";
import AddDiocese from "./components/Church/AddDiocese";
import ViewAppVersionHistory from "./components/ViewAppVersionHistory";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-management/:action" element={<UserManagament />} />
          <Route path="/app-management" element={<AppManagement />} />
          <Route
            path="/content/:tabs/:action"
            exact
            strict
            element={<ContentManagement />}
          />
          <Route
            path="/content/gospel/:tabs/:action"
            exact
            strict
            element={<ContentManagement />}
          />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/crash-analytics" element={<CrashAnalytics />} />
          <Route path="/volunteer/:action" element={<Volunteer />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/delete-requests" element={<DeleteRequest />} />
          <Route path="/church/:action" element={<Churches />} />
          <Route path="/diocese/:action" element={<AddDiocese />} />
          <Route
            path="/ViewAppVersionHistory"
            element={<ViewAppVersionHistory />}
          />
        </Route>

        <Route path="/login" exact element={<Login />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;

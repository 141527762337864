import {
  CREATE_QUIZ,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAILURE,
  GET_QUIZ,
  GET_QUIZ_SUCCESS,
  GET_QUIZ_FAILURE,
  UPDATE_QUIZ,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAILURE,
  DOWNLOAD_QUIZ,
  DOWNLOAD_QUIZ_SUCCESS,
  DOWNLOAD_QUIZ_FAILURE,
  TOGGLE_QUIZ,
} from "./action";
const initialState = {
  loading: false,
  error: "",
  QUIZData: [],
  createSuccess: false,
  UPDATESuccess: false,
  getSuccess: false,
  DOWNLOADSuccess: false,
};

export const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_QUIZ:
      return {
        ...state,
        createSuccess: false,
        error: "",
        loading: true,
      };
    case TOGGLE_QUIZ:
      return {
        ...state,
        createSuccess: false,
        UPDATESuccess: false,
      };

    case CREATE_QUIZ_SUCCESS:
      return {
        ...state,

        error: "",
        loading: false,
        createSuccess: true,
      };
    case CREATE_QUIZ_FAILURE:
      return {
        ...state,
        createSuccess: false,
        error: action.payload,
        loading: false,
      };
    case UPDATE_QUIZ:
      return {
        ...state,

        error: "",
        UPDATESuccess: false,
      };

    case UPDATE_QUIZ_SUCCESS:
      return {
        ...state,

        error: "",

        UPDATESuccess: true,
      };
    case UPDATE_QUIZ_FAILURE:
      return {
        ...state,

        error: action.payload,
        UPDATESuccess: false,
      };

    case GET_QUIZ:
      return {
        ...state,
        QUIZData: [],
        loading: true,
        getSuccess: false,
      };
    case GET_QUIZ_SUCCESS:
      return {
        ...state,
        QUIZData: action.payload,
        error: "",
        loading: false,
        getSuccess: true,
      };
    case GET_QUIZ_FAILURE:
      return {
        ...state,
        QUIZData: [],
        error: action.payload,
        loading: false,
        getSuccess: false,
      };
    case DOWNLOAD_QUIZ:
      return {
        ...state,

        error: "",
        DOWNLOADSuccess: false,
      };

    case DOWNLOAD_QUIZ_SUCCESS:
      return {
        ...state,

        error: "",

        DOWNLOADSuccess: true,
      };
    case DOWNLOAD_QUIZ_FAILURE:
      return {
        ...state,

        error: action.payload,
        DOWNLOADSuccess: false,
      };

    default:
      return state;
  }
};

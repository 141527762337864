export const CREATE_HUKUMNAMA = "CREATE_HUKUMNAMA";
export const CREATE_HUKUMNAMA_SUCCESS = "CREATE_HUKUMNAMA_SUCCESS";
export const CREATE_HUKUMNAMA_FAILURE = "CREATE_HUKUMNAMA_FAILURE";

export const FETCH_HUKUMNAMA = "FETCH_HUKUMNAMA";
export const FETCH_HUKUMNAMA_SUCCESS = "FETCH_HUKUMNAMA_SUCCESS";
export const FETCH_HUKUMNAMA_FAILURE = "FETCH_HUKUMNAMA_FAILURE";

export const FETCH_ALL_HUKUMNAMA = "FETCH_ALL_HUKUMNAMA";
export const FETCH_ALL_HUKUMNAMA_SUCCESS = "FETCH_ALL_HUKUMNAMA_SUCCESS";
export const FETCH_ALL_HUKUMNAMA_FAILURE = "FETCH_ALL_HUKUMNAMA_FAILIURE";

export const UPDATE_HUKUMNAMA = "UPDATE_HUKUMNAMA";
export const UPDATE_HUKUMNAMA_SUCCESS = "UPDATE_HUKUMNAMA_SUCCESS";
export const UPDATE_HUKUMNAMA_FAILURE = "UPDATE_HUKUMNAMA_FAILURE";

export const DELETE_HUKUMNAMA = "DELETE_HUKUMNAMA";
export const DELETE_HUKUMNAMA_SUCCESS = "DELETE_HUKUMNAMA_SUCCESS";
export const DELETE_HUKUMNAMA_FAILURE = "DELETE_HUKUMNAMA_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

// defining actions for create hukumnama
export const createHukumnama = (data) => {
  return {
    type: CREATE_HUKUMNAMA,
    payload: data,
  };
};

export const createHukumnamaSuccess = (data) => {
  return {
    type: CREATE_HUKUMNAMA_SUCCESS,
    payload: data,
  };
};

export const createHukumnamaFailure = (error) => {
  return {
    type: CREATE_HUKUMNAMA_FAILURE,
    payload: error,
  };
};

// defining actions for fetch particular hukumnama
export const fetchHukumnama = (urlParameters) => {
  return {
      type: FETCH_HUKUMNAMA,
      params: urlParameters
  }
}

export const fetchHukumnamaSuccess = (data) => {
  return {
    type: FETCH_HUKUMNAMA_SUCCESS,
    payload: data,
  };
};

export const fetchHukumnamaFailure = (error) => {
  return {
    type: FETCH_HUKUMNAMA_FAILURE,
    payload: error,
  };
};

// defining actions for fetch all hukumnama
export const fetchAllHukumnamaWithPagination = (urlParameters) => {
  return {
    type: FETCH_ALL_HUKUMNAMA,
    params: urlParameters
  };
};

export const fetchAllHukumnamaSuccess = (data) => {
  return {
    type: FETCH_ALL_HUKUMNAMA_SUCCESS,
    payload: data,
  };
};

export const fetchAllHukumnamaFailure = (error) => {
  return {
    type: FETCH_ALL_HUKUMNAMA_FAILURE,
    payload: error,
  };
};

// defining actions for update hukumnama
export const updateHukumnama = (data, urlParameters) => {
  return {
    type: UPDATE_HUKUMNAMA,
    payload: data,
    params: urlParameters
  }
}

export const updateHukumnamaSuccess = (data) => {
  return {
    type: UPDATE_HUKUMNAMA_SUCCESS,
    payload: data,
  };
};

export const updateHukumnamaFailure = (error) => {
  return {
    type: UPDATE_HUKUMNAMA_FAILURE,
    payload: error,
  };
};

// defining actions for delete hukumnama
export const deleteHukumnama = (id) => {
  return {
    type: DELETE_HUKUMNAMA,
    params: id
  };
};

export const deleteHukumnamaSuccess = (data) => {
  return {
    type: DELETE_HUKUMNAMA_SUCCESS,
    payload: data,
  };
};

export const deleteHukumnamaFailure = (error) => {
  return {
    type: DELETE_HUKUMNAMA_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};
export const CREATE_PROFILE = "CREATE_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const createProfile = (data) => {
  return {
    type: CREATE_PROFILE,
    payload: data,
  };
};

export const profileSuccess = (data) => {
  return {
    type: PROFILE_SUCCESS,
    payload: data,
  };
};

export const profileFailure = (error) => {
  return {
    type: PROFILE_FAILURE,
    payload: error,
  };
};

export const updateUserProfile = (data) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: data,
  };
};

export const updateUserProfileSuccess = (data) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: data,
  };
};

export const updateUserProfileFailure = (error) => {
  return {
    type: UPDATE_USER_PROFILE_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import { retry } from "redux-saga/effects";

dayjs.extend(utc); // Extend dayjs with the utc plugin

// Function that will return today's date
export const todayDate = () => {
    return dayjs();
}

// Function that will return any date
export const anyDate = (dateValue) => {
    return dayjs(dateValue);
}

// Function that will format the provided date separated with slash
export const formatDateSeperatedWithSlash = (dateValue) => {
    return dayjs(dateValue).format('DD/MM/YYYY');
}

// Function that will format the provided date separated with hyphen
export const formatDateSeperatedWithHyphen = (dateValue) => {
    return dayjs(dateValue).format('YYYY-MM-DD');
}

// Function that will format the provided date in alphanumeric format
export const formatDateInAlphanumericFormat = (dateValue) => {
    return dayjs(dateValue).format('DD MMMM YYYY');
}

// Function that returns date in UTC format: "2024-07-27T16:02:33Z"
export const todayDateinUTC = () => {
    return dayjs().utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
}

// Function that returns a formatted date in UTC
export const formatDateInUTC = (date) => {
    return dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
};

// Function that will format date of birth
export const formatDateOfBirth = (date) => {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

// Function that wil return tomorrow's date
export const tomorrowDate = () => {
    let dateOfTomorrow = new Date();
    dateOfTomorrow.setDate(dateOfTomorrow.getDate() + 1);
    return dateOfTomorrow;
}

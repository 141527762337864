import { call, put, takeEvery } from "redux-saga/effects";

import { 
  CREATE_NITNEM, 
  nitnemSuccess, 
  nitnemFailure,
  FETCH_ALL_NITNEM,
  fetchAllNitnemSuccess,
  fetchAllNitnemFailure,
  UPDATE_NITNEM,
  updateNitnemSuccess,
  updateNitnemFailure,
  DELETE_NITNEM,
  deleteNitnemSuccess,
  deleteNitnemFailure
} from "./action";

import { postRequest, deleteRequest, putRequest, getRequest } from "../../apiServices/ApiActions";

import { 
  addNitnemUrl, 
  updateNitnemUrl, 
  fetchAllNitnemWithPaginationUrl, 
  deleteNitnemUrl 
} from "../../apiServices/ApiUrl";

const addNitnem = async(payload) => {
  return await postRequest(addNitnemUrl, payload);
};

const getAllNitnem = async(page) => {
  return await getRequest(`${fetchAllNitnemWithPaginationUrl}?page=${--page}&size=${10}`);
}

const editNitnem = async(payload, params) => {
  return await putRequest(`${updateNitnemUrl}/${params}`, payload);
}

const removeNitnem = async(id) => {
  return await deleteRequest(`${deleteNitnemUrl}/${id}`);
}

function* createNitnem(action) {
  try {
    const result = yield call(addNitnem, action.payload);

    if (result.status === 200) {
      yield put(nitnemSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(nitnemFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(nitnemFailure(error));
  }
}

function* fetchAllNitnemWithPagination(action) {
  try {
    const result = yield call(getAllNitnem, action.params);

    if(result.status === 200) {
      yield put(fetchAllNitnemSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(fetchAllNitnemFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(fetchAllNitnemFailure(error));
  }
}

function* updateNitnem(action) {
  try {
    const result = yield call(editNitnem, action.payload, action.params);

    if(result.status === 200) {
      yield put(updateNitnemSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(updateNitnemFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(updateNitnemFailure(error));
  }
}

function* deleteNitnem(action) {
  try {
    const result = yield call(removeNitnem, action.params);

    if(result.status === 204) {
      yield put(deleteNitnemSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(deleteNitnemFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(deleteNitnemFailure(error));
  }
}

function* nitnemSaga() {
  yield takeEvery(CREATE_NITNEM, createNitnem);
  yield takeEvery(UPDATE_NITNEM, updateNitnem);
  yield takeEvery(FETCH_ALL_NITNEM, fetchAllNitnemWithPagination);
  yield takeEvery(DELETE_NITNEM, deleteNitnem);
}

export default nitnemSaga;

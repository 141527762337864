import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MenuLayout from "../commonLayout/MenuLayout";
import AddChurch from "./Church/AddChurch";
import Overview from "./Church/Overview";
import "../styles/church.css";

const Churches = () => {
  const matches = useMediaQuery("(max-width:1440px)");
  const navigate = useNavigate();
  const { action } = useParams();

  const handleAddChurch = () => {
    navigate(`/church/Add`, { replace: true });
  };
  const updateChurch = (value) => {
    const queryString = new URLSearchParams(value).toString();
    navigate(`/church/edit?${queryString}`, { replace: true });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, maxHeight: "100%", mx: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          {action === "edit" || action === "Add" ? (
            <AddChurch />
          ) : (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 5, sm: 2, md: 1 }}
              justifyContent="flex-start"
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Grid xs={5} md={5} lg={7}>
                  <Box
                    className={"page_title"}
                    sx={{
                      textWrap: "nowrap",
                      paddingY: {
                        lg: "10px",
                        xl: "20px",
                        marginTop: "1rem",
                      },
                    }}
                  >
                    Church
                  </Box>

                  <p className="church_page_sub_title">
                    Get a top level view of your Contents
                  </p>
                </Grid>
                <Button
                  variant="contained"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  size={"small"}
                  onClick={handleAddChurch}
                  className={`church_button`}
                  sx={{ fontSize: matches ? "12px" : "inherit" }}
                >
                  Add Church
                </Button>
              </Grid>

              <Overview handleEdit={updateChurch} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <Churches />;
      }}
    />
  );
};

export default wrapper;

import { call, put, takeEvery } from "redux-saga/effects";

import { 
    FETCH_LANGUAGE,
    fetchLanguageSuccess,
    fetchLanguageFailure
} from "./action";

import { getRequest } from "../../apiServices/ApiActions";
import { fetchLanguageUrl } from "../../apiServices/ApiUrl";

const getLanguage = async() => {
    return await getRequest(fetchLanguageUrl);
}

function* getAllLanguage() {
    try {
        const result = yield call(getLanguage);

        if(result.status === 200) {
            yield put(fetchLanguageSuccess(result));
        } else {
            let res = result?.response?.data
            yield put(fetchLanguageFailure(result));

            if(res){
                yield put(alert(res.message))
            }
        }
    } catch (error) {
        yield put(fetchLanguageFailure(error));
    }
}

function* languageSaga() {
    yield takeEvery(FETCH_LANGUAGE, getAllLanguage);
}
  
export default languageSaga;

import { call, put, takeEvery } from "redux-saga/effects";

import { ADMIN_LOGIN, loginSuccess, loginFailure, logoutSuccess, ADMIN_LOGOUT } from "./action";

import { postRequest } from "../../apiServices/ApiActions";
import { loginAuthUrl } from "../../apiServices/ApiUrl";

const addAdmin = async(payload) => {
  return postRequest(loginAuthUrl, payload);
};

function* createAdmin(action) {
  try {
    const result = yield call(addAdmin, action.payload);

    if (result.status === 200) {
      yield put(loginSuccess(result));
    } else {
      let res = result?.response;
      yield put(loginFailure(result));

      if(res?.status === 403) {
        yield put(alert("Entered email/password is incorrect!"));
      } else {
        yield put(alert(res?.data?.error));
      }
    }
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* logoutAdmin(action) {
  try {
    yield put(logoutSuccess);
  } catch (error) {
  
  }
}

function* loginSaga() {
  yield takeEvery(ADMIN_LOGIN, createAdmin);
  yield takeEvery(ADMIN_LOGOUT, logoutAdmin);
}

export default loginSaga;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search__input--container {
  position: relative;
  color: "#666666";
}

.search__input {
  padding: 10px;
  padding-left: 50px;
  width: 20rem;
  max-height: 48px;
  border-radius: 50px;
  border: 1px solid #c9c9c9;
  font-size: 18px;
}

.search__input--icon {
  position: absolute;
  top: 8px;
  left: 15px;
  color: #666666;
  outline: none;
}

.search__input--container input:focus {
  outline: none;
}

@media (min-width: 1180px) and (max-width: 1535px) {
  .search__input {
    width: 240px;
    font-size: 12px;
    height: 30px;
  }
  .search__input--icon {
    top: 5px;
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .search__input {
    width: 250px;
    font-size: 14px;
  }
  .search__input--icon {
    top: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/SearchInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,cAAc;EACd,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,YAAY;IACZ,eAAe;IACf,YAAY;EACd;EACA;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,YAAY;IACZ,eAAe;EACjB;EACA;IACE,QAAQ;EACV;AACF","sourcesContent":[".search__input--container {\n  position: relative;\n  color: \"#666666\";\n}\n\n.search__input {\n  padding: 10px;\n  padding-left: 50px;\n  width: 20rem;\n  max-height: 48px;\n  border-radius: 50px;\n  border: 1px solid #c9c9c9;\n  font-size: 18px;\n}\n\n.search__input--icon {\n  position: absolute;\n  top: 8px;\n  left: 15px;\n  color: #666666;\n  outline: none;\n}\n\n.search__input--container input:focus {\n  outline: none;\n}\n\n@media (min-width: 1180px) and (max-width: 1535px) {\n  .search__input {\n    width: 240px;\n    font-size: 12px;\n    height: 30px;\n  }\n  .search__input--icon {\n    top: 5px;\n  }\n}\n\n@media (min-width: 1536px) and (max-width: 1919px) {\n  .search__input {\n    width: 250px;\n    font-size: 14px;\n  }\n  .search__input--icon {\n    top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const adminLogin = (data) => {
  return {
    type: ADMIN_LOGIN,
    payload: data,
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

export const adminLogout = (data) => {
  return {
    type: ADMIN_LOGOUT,
    payload: data,
  };
};

export const logoutSuccess = (data) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: data,
  };
};

export const logoutFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};


import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import useCustomStyles from "../../customHooks/useCustomStyles";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";

import {
  createNitnem,
  updateNitnem,
  showUnshowDialogMessage,
} from "../../redux/nitnem/action";
import { fetchLanguage } from "../../redux/language/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

const styles = (theme) => ({
  container: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px",
      marginTop: "20px",
      height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditPrayer = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [fileError, setFileError] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);

  const prayerTypeObject = [
    { value: "MORNING_TIME", label: "Morning" },
    { value: "EVENING_TIME", label: "Evening" },
    { value: "NIGHT_TIME", label: "Night" },
  ];

  const [nitnemObject, setNitnemObject] = useState({
    names: "",
    prayerType: "",
    languageCode: "",
    files: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");

  // Opens the file upload dialog for the specified type
  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  // Closes the progress dialog, stops the upload progress, and resets progress
  const handleProgressDialogClose = () => {
    setNitnemObject({ ...nitnemObject, files: "" });
    setFileName("");
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval); // Stop the upload interval
    setProgress(0); // Reset the progress
  };

  // Handles file input change, sets the file name, and starts the upload process
  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && file.type === "application/epub+zip") {
      setUploadFileName(file.name);
      setNitnemObject({ ...nitnemObject, files: file });
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
      setFileError(false);
    } else {
      alert("Please upload a valid epub file.");
    }
  };

  // Opens the progress dialog and sets upload status
  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  // Simulates file upload progress
  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);
        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval); // Save the interval ID to stop it later
    };
    UploadProgress();
  };

  const handleNitnemUpload = async (event) => {
    event.preventDefault();

    if (fileName === "") {
      setFileError(true);
      return;
    }

    const formData = new FormData();

    formData.append("files", nitnemObject?.files);
    formData.append("languageCode", nitnemObject?.languageCode);
    formData.append("prayerType", nitnemObject?.prayerType);

    if (action === "edit") {
      // performing edit nitnem operation
      let fileObject = {};

      // constructing the file response data in a format that is acceptable for update nitnem API call
      fileObject.lastModified = editRecords?.createdAt;
      fileObject.lastModifiedDate = editRecords?.updatedAt;
      fileObject.name = editRecords?.fileName;
      fileObject.size = editRecords?.fileSize;
      fileObject.type = editRecords?.fileType;
      fileObject.webkitRelativePath = editRecords?.filePath;

      formData.set("files", fileObject);
      formData.append("name", nitnemObject?.names);

      props.updateNitnem(formData, editRecords?.id);
      props.trackButtonClick("Update Prayer Button", 1);
    } else {
      // performing add nitnem operation
      formData.append("names", nitnemObject?.names);

      props.createNitnem(formData);
      props.trackButtonClick("Create Prayer Button", 1);
    }
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setNitnemObject({
        ...nitnemObject,
        names: "",
        languageCode: "",
        prayerType: "",
      });
      setFileName("");
    }
  };

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const getAllLanguagesList = () => {
    props.fetchLanguage();
  };

  useEffect(() => {
    getAllLanguagesList();

    if (action === "edit") {
      setNitnemObject({
        ...nitnemObject,
        names: editRecords?.name,
        languageCode: editRecords?.languageCode,
        prayerType: editRecords?.prayerType,
      });
      setFileName(editRecords?.fileName);
    }
  }, []);

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  return (
    <>
      {props?.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header="Uploaded Successfully!"
          content="You Have Uploaded Your File Successfully"
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          uploadFileName={uploadFileName}
          uploadInProgress={uploadInProgress}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleNitnemUpload}
      >
        <div>
          <Stack
            spacing={8}
            direction="column"
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <Stack spacing={4} direction="row">
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 1 }}
              >
                <TextField
                  required
                  className={classes?.input_box}
                  id="outlined-basic"
                  placeholder="Enter Title"
                  label="Title"
                  variant="outlined"
                  size={ matches?'small':'' }
                  InputLabelProps={{ shrink: true }}
                  value={nitnemObject?.names}
                  onChange={(event) =>
                    setNitnemObject({
                      ...nitnemObject,
                      names: event.target.value,
                    })
                  }
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mb: 5 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-basic"
                  placeholder="EPUB File"
                  label="File"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  size={ matches?'small':'' }
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <>
                        <Button
                          aria-label="upload"
                          component="label"
                          className="addFileButton"
                          onClick={() => {
                            handleOpen("EPUB", ["EPUB"], [".epub"]);
                          }}
                        >
                          Add File
                        </Button>
                      </>
                    ),
                  }}
                  value={fileName}
                />
                {fileError && <div style={{ color: "red" }}>File required</div>}
              </FormControl>
            </Stack>

            <Stack spacing={4} direction="row">
              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 5 }}
                size={ matches?'small':'' }
              >
                <InputLabel id="demo-simple-select-label" sx={{ mt: 1 }}>
                  Time
                </InputLabel>

                <Select
                  required
                  className={classes?.input_box}
                  sx={{ mt: 1 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Choose Time"
                  label="Time"
                  
                  InputLabelProps={{ shrink: true }}
                  value={nitnemObject?.prayerType}
                  onChange={(event) =>
                    setNitnemObject({
                      ...nitnemObject,
                      prayerType: event.target.value,
                    })
                  }
                >
                  {prayerTypeObject.map((prayer) => {
                    return (
                      <MenuItem value={prayer.value}>{prayer.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={ matches?'small':'' }
              >
                <InputLabel id="demo-simple-select-label" sx={{ mt: 1 }}>
                  Language
                </InputLabel>
                <Select
                  required
                  className={classes?.input_box}
                  sx={{ mt: 1 }}
                  InputLabelProps={{ shrink: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={nitnemObject?.languageCode}
                  label="Select Language"
                  placeholder="Choose Language"
                  onChange={(event) =>
                    setNitnemObject({
                      ...nitnemObject,
                      languageCode: event.target.value,
                    })
                  }
                >
                  {props?.languageData?.data?.length > 0 &&
                    props?.languageData?.data.map((language) => (
                      <MenuItem value={language?.code}>
                        {language?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Grid xs={12} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              type="submit"
              startIcon={<FileUploadOutlined />}
              size="small"
              sx={{
                backgroundColor: "var(--secondary-color)",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "500",
                marginLeft: { sm: 11, md: 52, lg: 85, xl: 110 },
              }}
            >
              {action === "edit" ? "Update" : "Upload"}
            </Button>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { dialogMessage } = state.nitnemReducer;
  const { success, languageData } = state.languageReducer;

  return {
    successResponse: dialogMessage,
    languageSuccessResponse: success,
    languageData,
    contentDataToBeUpdated: ownProps.contentDataToBeUpdated,
  };
};

export default connect(mapStateToProps, {
  createNitnem,
  updateNitnem,
  showUnshowDialogMessage,
  fetchLanguage,
})(withMixpanelTracking(AddEditPrayer, "Add Prayer"));

import {
  CREATE_GRANTH,
  GRANTH_SUCCESS,
  GRANTH_FAILURE,
  DIALOG_MESSAGE,
  DELETE_GRANTH_FAILURE,
  DELETE_GRANTH_SUCCESS,
  DELETE_GRANTH,
  GET_GRANTH_DETAILS_BYID,
  GET_GRANTH_SUCCESS_DETAILS_BYID,
  GET_GRANTH_FAILURE_DETAILS_BYID,
  GET_GRANTH_DETAILS,
  GET_GRANTH_SUCCESS_DETAILS,
  GET_GRANTH_FAILURE_DETAILS,
  UPDATE_GRANTH,
  UPDATE_GRANTH_SUCCESS,
  UPDATE_GRANTH_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
  loading: false,
  error: "",
  granthData: [],
  dialogMessage: false,
  deleteGranthsucces: false,
  granthDataById: [],
  updateGranthSuccess: false,
  getGrantSucces: false,
};

export const granthReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GRANTH:
      return {
        ...state,

        error: "",
        dialogMessage: false,
        loading: true,
      };

    case GRANTH_SUCCESS:
      return {
        ...state,

        error: "",
        dialogMessage: true,
        loading: false,
      };

    case GRANTH_FAILURE:
      return {
        ...state,

        error: action.payload,
        dialogMessage: false,
        loading: false,
      };
    case UPDATE_GRANTH:
      return {
        ...state,

        error: "",
        dialogMessage: false,
        loading: true,
        updateGranthSuccess: false,
      };

    case UPDATE_GRANTH_SUCCESS:
      return {
        ...state,

        error: "",
        dialogMessage: true,
        updateGranthSuccess: true,
        loading: false,
      };

    case UPDATE_GRANTH_FAILURE:
      return {
        ...state,

        error: action.payload,
        dialogMessage: false,
        loading: false,
        updateGranthSuccess: false,
      };
    case DELETE_GRANTH:
      return {
        ...state,
        deleteGranthsucces: false,
        error: "",
      };
    case DELETE_GRANTH_SUCCESS:
      return {
        ...state,
        error: "",
        deleteGranthsucces: true,
      };
    case DELETE_GRANTH_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteGranthsucces: false,
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };
    case GET_GRANTH_DETAILS:
      return {
        ...state,
        granthData: [],
        loading: true,
        error: "",
      };
    case GET_GRANTH_SUCCESS_DETAILS:
      return {
        ...state,
        granthData: action.payload,
        loading: false,
        getGrantSucces: true,
        error: "",
      };
    case GET_GRANTH_FAILURE_DETAILS:
      return {
        ...state,
        granthData: [],
        loading: false,
        getGrantSucces: false,
        error: action.payload,
      };
    case GET_GRANTH_DETAILS_BYID:
      return {
        ...state,
        granthDataById: [],
        loading: true,
        error: "",
      };
    case GET_GRANTH_SUCCESS_DETAILS_BYID:
      return {
        ...state,
        granthDataById: action.payload,
        loading: false,
        error: "",
      };
    case GET_GRANTH_FAILURE_DETAILS_BYID:
      return {
        ...state,
        granthDataById: [],
        loading: false,
        error: action.payload,
      };

    case DELETE_TOGGLE_MODAL_STATUS:
      return {
          ...state,
          deleteGranthsucces: false,
      };

    default:
      return state;
  }
};
